import { ChangeEvent, memo, KeyboardEvent } from "react";
import { ElementChangeEvent, Sizes } from "../../../common/types/ui.types";
import styles from "../input/inputs.module.scss";

//* MultiSelect Prop Types
interface InputTextProps {
  className?: string;
  inputClass?: string;
  labelClass?: string;
  name: string;
  label?: string;
  value?: string | number;
  disabled?: boolean;
  placeholder?: string;
  maxLength?: number;
  autoFocus?: boolean;
  autoComplete?: "on" | "off";
  onChange?: (val: ElementChangeEvent) => void;
  onKeyUp?: (e: KeyboardEvent<HTMLInputElement>) => void;
  type?: string;
  size?: Sizes;
  Icon?: any;
  defaultValue?: any;
}

const InputText = memo(({ onChange, ...props }: InputTextProps) => {
  // * Handle on change on input component
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    onChange && onChange({ name, data: value });
  };

  return (
    <div
      className={`${props.className && props.className} ${styles.inputWrapper}`}
    >
      <label className={`${styles.floatLabel} ${props.labelClass}`}>
        {props.label}
      </label>
      <input
        type={props.type ? props.type : "text"}
        className={`${styles.input} ${props.inputClass ?? ""} ${
          props.disabled && styles.disabled
        } 
          ${styles[props.size ?? "default"]}
          ${props.Icon && styles.withIcon}
        `}
        onChange={handleChange}
        value={props.value}
        disabled={props.disabled}
        autoFocus={props.autoFocus}
        maxLength={props.maxLength}
        name={props.name}
        onKeyUp={props.onKeyUp && props.onKeyUp}
        autoComplete={props.autoComplete ?? "off"}
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
      />
      {props.Icon && <span className={styles.icon}> {props.Icon}</span>}
    </div>
  );
});

//* Display name for memoization.
InputText.displayName = "InputText";
export default InputText;
