import { useState } from "react";
import { useNavigate } from "react-router";
import { useRecoilState, useRecoilValue } from "recoil";
import { API, API_MOD } from "../../../../common/config/api.config";
import { NameIdTemplate } from "../../../../common/helpers/general.helper";
import { showToast } from "../../../../common/helpers/toast.helper";
import { checkError } from "../../../../common/helpers/validation.helper";
import { OperatorDTO } from "../../../../common/models/operator.model";
import { UserDTO } from "../../../../common/models/user.model";
import { ElementChangeEvent } from "../../../../common/types/ui.types";
import FlatButton from "../../../../components/elements/buttons/flat.button";
import Dropdown from "../../../../components/elements/dropdown/dropdown.element";
import Dialog from "../../../../components/ui/dialog/dialog.ui";
import { AtomAuthUser } from "../../../../store/auth.store";
import { AtomProvinces } from "../../../../store/dashboard.store";
import { AtomOperators } from "../../../../store/operator.store";
import { AtomUsers } from "../../../../store/user.store";
import styles from "../../../userManagement/userManagement.module.scss";

interface AddUserDialogProps {
  show: boolean;
  setShow: (b: boolean) => void;
  onSave: () => void;
  loader: boolean;
  setLoader: (v: boolean) => void;
}

const AddWalletDialog = ({
  show,
  setShow,
  loader,
  setLoader,
  ...props
}: AddUserDialogProps) => {
  const authUser = useRecoilValue(AtomAuthUser);
  const operators = useRecoilValue(AtomOperators);

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [walletDetails, setWalletDetails] = useState<any>({} as UserDTO);
  // const [options, setOptions] = useState<{
  //   operators: string[];
  //   aggregators: string[];
  // }>({ operators: [], aggregators: [] });

  const currencies = useRecoilValue(AtomProvinces);
  const navigate = useNavigate();
  const [wallet, setWallet] = useRecoilState(AtomUsers);

  const handleChange = (e: ElementChangeEvent) => {
    setErrorMessage("");
    const opId = e.data;
    let _new = { ...walletDetails, [e.name]: opId };
    if (e.name === "operatorId") {
      _new = {
        ..._new,
        operatorId: opId.operatorId,
        aggregatorId: opId.aggregatorId,
      };
    }
    setWalletDetails(_new);
  };
  const onSave = () => {
    setLoader(true);
    let _walletObj = { ...walletDetails };
    // _walletObj = { ..._walletObj, operatorId: _walletObj.operator.operatorId };
    API.post(`${API_MOD.OPERATOR}/wallet`, _walletObj, {
      /*  */ headers: { Authorization: authUser?.token ?? "" },
    })
      .then((res) => {
        if (res) {
          setWallet([walletDetails, ...wallet]);
          setWalletDetails({} as UserDTO);
          props.onSave();
          setShow(false);
          setLoader(false);
        }
        showToast("success", "Wallet Add Successfully..!");
      })
      .catch((error) => {
        setLoader(false);
        checkError(error, true, navigate);
      });
  };

  const operatorOptTemplate = ({ operatorId }: OperatorDTO) => {
    const _name = operators[operatorId]
      ? operators[operatorId].detail
      : operatorId;
    const _defaultFlag = operators[operatorId]?.isDefault
      ? operators[operatorId]?.isDefault
      : undefined;
    return <>{NameIdTemplate(_name, operatorId, _defaultFlag)}</>;
  };

  const operatorValueTemplate = (value: string) => {
    const _val = operators[value] ? operators[value].detail : value;
    return _val;
  };

  return (
    <div>
      <Dialog
        closeOnEsc
        hideCloseBtn={false}
        show={show}
        setShow={setShow}
        headerTemplate={() => <h1>Add Wallet</h1>}
        glassEffect={true}
      >
        <div className={styles.dialogBody}>
          <div className={styles.GridWrapper}>
            <div className={styles.section1}>
              <>
                {/* {authUser?.role !== ROLES.OPERATOR && (
                  <Dropdown
                    options={Object.keys(aggregators)} // dynamic
                    selectedValue={walletDetails?.aggregatorId ?? ""}
                    optionTemplate={aggregatorOptTemplate}
                    name="aggregatorId"
                    label="Select Aggregator"
                    placeholder="Select Aggregator"
                    onChange={handleDropDownChange}
                    valueTemplate={aggregatorValueTemplate}
                    optionKey="aggregatorId"
                  />
                )} */}
                <Dropdown
                  options={Object.values(operators)} // dynamic
                  selectedValue={walletDetails?.operatorId ?? ""}
                  name={"operatorId"}
                  optionKey="operatorId"
                  label={`Select Operator`}
                  placeholder={`Select Operator`}
                  onChange={handleChange}
                  optionTemplate={operatorOptTemplate}
                  valueTemplate={operatorValueTemplate}
                  valueObjKey="detail"
                />
                <Dropdown
                  options={currencies ?? []}
                  selectedValue={walletDetails?.currency ?? ""}
                  name="currency"
                  label="Select Currency"
                  placeholder="Select Currency"
                  onChange={(e) => handleChange(e)}
                />
                {/* <Dropdown
                  options={Object.keys(aggregators)} // dynamic
                  selectedValue={userDetails?.aggregatorId ?? ""}
                  optionTemplate={aggregatorOptTemplate}
                  name="aggregatorId"
                  label="Select Aggregator"
                  placeholder={`Select Aggregator`}
                  onChange={onOperatorChange}
                  valueTemplate={aggregatorValueTemplate}
                  optionKey="aggregatorId"
                />
                <Dropdown
                  options={options.operators} // dynamic
                  selectedValue={userDetails?.operatorId ?? ""}
                  name={"operatorId"}
                  label={`Select Operator`}
                  placeholder={`Select Operator`}
                  onChange={handleChange}
                  disabled={userDetails?.role === "ADMIN"}
                  optionTemplate={operatorOptTemplate}
                  valueTemplate={operatorValueTemplate}
                />
                <Dropdown
                  options={currencies ?? []}
                  selectedValue={userDetails.currency}
                  name="currency"
                  label="Select Currency"
                  placeholder="Select Currency"
                  onChange={(e) => handleChange(e, e.data.currency)}
                /> */}
              </>
            </div>
            <div className="errorMsg">{errorMessage}</div>
            <div className={styles.buttonDiv}>
              <FlatButton
                onClick={onSave}
                className={styles.dialogButton}
                label="SAVE"
              />
              <FlatButton
                className={styles.dialogButton}
                onClick={() => setShow(false)}
                label="CANCEL"
              />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default AddWalletDialog;
