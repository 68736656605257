import { atom, selector } from "recoil";
import { LS_KEYS } from "../common/constants/general.constant";
import { UserDTO } from "../common/models/user.model";
import { MENU } from "../common/routes/default.routes";
import {
  AtomOperatorsBalanceReport,
  AtomOperators,
  AtomDefaultOperators,
} from "./operator.store";
import {
  AtomAggregatorBalanceReport,
  AtomAggregatorEntries,
  AtomAggregatorIds,
  AtomAggregatorsObj,
} from "./aggregator.store";
import {
  AtomAdminBalanceReport,
  AtomFDFilter,
  AtomProvinces,
  AtomSettlementReport,
} from "./dashboard.store";
import {
  AtomAllFDData,
  AtomFDOptions,
  AtomFilteredFDData,
} from "./financialDashboard.store";
import {
  AtomSettlementLog,
  AtomSettlementLogDate,
  AtomTransaction,
  AtomTransactionDate,
  AtomWager,
  AtomWagerDate,
} from "./report.store";
import { AtomUsers } from "./user.store";

const SelectorAuthUser = selector({
  key: "keySelectorAuthUser",
  get: () => {
    const _client = localStorage.getItem(LS_KEYS.USER);
    if (!_client) return null;
    const user = JSON.parse(_client);
    return user;
  },
});

export const AtomAuthUser = atom<UserDTO | null | undefined>({
  key: "atomAuthUser",
  default: SelectorAuthUser,
});

export const AtomAuthMenu = atom<any[]>({
  key: "atomAuthMenu",
  default: [...MENU],
});

const SelectorSidebarOpen = selector({
  key: "selectorSidebar",
  get: () => {
   

    if (window.innerWidth >= 1440) return true;
    return false;
  },
});

export const AtomShowSidebar = atom<boolean>({
  key: "atomSideBarMenu",
  default: SelectorSidebarOpen,
});

export const SelectorResetAllAtoms = selector({
  key: "resetAllAtoms",
  get: () => {},
  set: ({ reset }) => {


    reset(AtomAuthUser);
    reset(AtomAuthMenu);
    // reset(AtomShowSidebar);
    reset(AtomOperators);
    reset(AtomOperatorsBalanceReport);
    reset(AtomAggregatorBalanceReport);
    reset(AtomAggregatorsObj);
    reset(AtomAggregatorsObj);
    reset(AtomOperatorsBalanceReport);
    reset(AtomAggregatorEntries);
    reset(AtomAggregatorsObj);
    reset(AtomAggregatorIds);
    reset(AtomAggregatorBalanceReport);
    reset(AtomAuthUser);
    reset(AtomAuthMenu);
    reset(AtomShowSidebar);
    reset(AtomProvinces);
    reset(AtomSettlementReport);
    reset(AtomFDFilter);
    reset(AtomAdminBalanceReport);
    reset(AtomAllFDData);
    reset(AtomFilteredFDData);
    reset(AtomFDOptions);
    reset(AtomOperators);
    reset(AtomDefaultOperators);
    reset(AtomOperatorsBalanceReport);
    reset(AtomTransaction);
    reset(AtomTransactionDate);
    reset(AtomWager);
    reset(AtomWagerDate);
    reset(AtomSettlementLog);
    reset(AtomSettlementLogDate);
    reset(AtomUsers);
  },
});
