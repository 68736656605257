import { useState } from "react";
import { useNavigate } from "react-router";
import { ToastContainer } from "react-toastify";
import { useRecoilValue } from "recoil";
import { API } from "../../../../common/config/api.config";
import { ROLES } from "../../../../common/constants/general.constant";
import { formatDateString } from "../../../../common/helpers/date.helper";
import { showToast } from "../../../../common/helpers/toast.helper";
import { checkError } from "../../../../common/helpers/validation.helper";
import { SettleDTO } from "../../../../common/models/settle.model";
import { ElementChangeEvent } from "../../../../common/types/ui.types";
import FlatButton from "../../../../components/elements/buttons/flat.button";
import InputText from "../../../../components/elements/input/inputText.element";
import Dialog from "../../../../components/ui/dialog/dialog.ui";
import { AtomAuthUser } from "../../../../store/auth.store";
import styles from "./settleDialog.module.scss";

interface SettleProps {
  show?: boolean;
  setShow?: (b: any) => void;
  selectedRow: any;
  fetchAgentBalanceReport: () => void;
  showCreditDebit?: any;
}

const SettleDialog = ({
  show,
  setShow,
  selectedRow,
  fetchAgentBalanceReport,
  showCreditDebit,
}: SettleProps) => {
  const [isOpen, setIsOpen] = useState<string | null>(null);
  const [value, setValue] = useState({} as SettleDTO);
  const navigate = useNavigate();
  const setteleTemp = () => {
    if (showCreditDebit) return <h1>Report/Settle</h1>;
    return <h1>Report</h1>;
  };

  const authUser = useRecoilValue(AtomAuthUser);

  const handleAction = (label: string) => {
    setIsOpen(label);
  };

  const onClose = () => {
    setIsOpen(null);
    setShow && setShow(false);
  };

  const handleChange = (val: ElementChangeEvent) => {
    setValue({ ...value, [val.name]: val.data });
  };

  const handleSubmit = () => {
    API.post(
      "report/settle",
      {
        ...value,
        settleBy:
          authUser?.role === ROLES.OPERATOR
            ? authUser?.operatorId
            : authUser?.aggregatorId,
        aggregatorId: selectedRow.aggregatorId,
        operatorId: selectedRow.operatorId,
        province: selectedRow.province,
        currency: selectedRow.currency,
        // settlementId: guid(),
        settlementType: isOpen,
      },
      { headers: { Authorization: authUser?.token ?? "" } }
    )
      .then((res) => {
        setShow && setShow(false);
        showToast("success", "Settlement successfully..!");
        setValue({} as SettleDTO);
        setIsOpen(null);
        fetchAgentBalanceReport();
      })
      .catch((error) => {
        checkError(error, true, navigate);
      });
  };

  const Details = (label: string, data: string | number) => (
    <div className={styles.detailWrapper}>
      <span className={styles.label}>{label}</span>
      <div className={styles.data}>{data}</div>
    </div>
  );

  return (
    <div>
      <ToastContainer />
      <Dialog
        closeOnEsc
        hideCloseBtn={false}
        show={show}
        setShow={setShow}
        headerTemplate={setteleTemp}
        glassEffect={true}
        onClose={onClose}
        className={styles.bodyWrp}
      >
        <div className={styles.headerWrp}>
          <div className={styles.lastWrp}>
            <span>Last</span>
          </div>
          <div>
            <span>Current</span>
          </div>
        </div>
        <div className={styles.bodyWpr}>
          <div className={styles.dataWpr}>
            {Details("Operator ID", selectedRow?.operatorId ?? "-")}
            {Details("Aggregator ID", selectedRow?.aggregatorId ?? "-")}
            {Details("Closing Balance", selectedRow?.closingBalance ?? "-")}
            {Details("Currency", selectedRow?.currency ?? "-")}
            {Details(
              "Last Report Start Date",
              formatDateString(selectedRow?.lastReportStartDate)
            ) ?? "-"}
            {Details(
              "Last Report End Date",
              formatDateString(selectedRow?.lastReportEndDate)
            ) ?? "-"}
            {Details("Rebate Rate", selectedRow?.rebateRate ?? "-")}
          </div>
          {selectedRow.lastReportStartDate !== 0 && (
            <div className={styles.dataWpr}>
              {Details(
                "Report Opening Balance",
                selectedRow?.reportOpeningBalance
              ) ?? "-"}
              {Details(
                "Report Start Date",
                formatDateString(selectedRow?.reportStartDate)
              ) ?? "-"}
              {Details(
                "Report End Date",
                formatDateString(selectedRow?.reportEndDate)
              ) ?? "-"}
              {Details("Total Turnover", selectedRow?.totalInvestment ?? "-")}
              {Details("Total Rebate", selectedRow?.totalRebates ?? "-")}
              {Details("Total Refund", selectedRow?.totalRefund ?? "-")}
              {Details("Total Winnings", selectedRow?.totalWinnings ?? "-")}
            </div>
          )}
        </div>

        {showCreditDebit ? (
          <div className={styles.buttonDiv}>
            <FlatButton
              className={
                isOpen === "CREDIT" ? styles.dialogButton1 : styles.dialogButton
              }
              label="CREDIT"
              onClick={() => handleAction("CREDIT")}
            />
            <FlatButton
              className={
                isOpen === "DEBIT" ? styles.dialogButton1 : styles.dialogButton
              }
              label="DEBIT"
              onClick={() => handleAction("DEBIT")}
            />
          </div>
        ) : (
          <div className={styles.savBtnWpr}>
            <FlatButton
              className={styles.footerBtn}
              label="OK"
              onClick={onClose}
            />
          </div>
        )}
        {isOpen !== null && (
          <div className={styles.inputWrp}>
            <InputText
              name="settlementNote"
              value={value?.settlementNote ?? ""}
              className={styles.input}
              type="text"
              label="Settlement Note"
              labelClass={styles.inputLabel}
              placeholder="Enter Settlement Note"
              onChange={(e) => handleChange(e)}
            />
            <InputText
              name="settlementAmount"
              value={value?.settlementAmount ?? ""}
              className={styles.input}
              type="number"
              label="Settlement Amount"
              labelClass={styles.inputLabel}
              placeholder="Enter Settlement Amount"
              onChange={(e) => handleChange(e)}
              onKeyUp={(e: any) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
            />
            <div className={styles.savBtnWpr}>
              <FlatButton
                className={styles.footerBtn}
                label="SETTLE"
                onClick={handleSubmit}
              />
              <FlatButton
                className={styles.footerBtn}
                label="CANCEL"
                onClick={onClose}
              />
            </div>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default SettleDialog;
