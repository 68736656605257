import { memo, ReactNode, useEffect } from "react";
import styles from "./layouts.module.scss";
import themes from "../styles/theme/themes.module.scss";
import layouts from "../styles/theme/layout.module.scss";
import { MENU } from "../common/routes/default.routes";
import SideNavigation from "../components/shared/sideNavigation/sideNavigation.component";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  AtomAuthMenu,
  AtomAuthUser,
  AtomShowSidebar,
} from "../store/auth.store";
import AuthProvider from "../provider/auth.provider";

//* GlobalLayout Prop Types
interface GlobalLayoutProps {
  children: ReactNode;
}

//* GlobalLayout
const GlobalLayout = memo(({ children }: GlobalLayoutProps) => {
  const authUser = useRecoilValue(AtomAuthUser);
  const [menu, setMenu] = useRecoilState(AtomAuthMenu);
  const show = useRecoilValue(AtomShowSidebar);

  useEffect(() => {
    if (authUser?.role) {
      let _menu: any[] = [];
      _menu = MENU.filter((x) => x.roles.includes(authUser.role));
      setMenu(_menu);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  return (
    <AuthProvider>
      <div
        className={`${styles.siteWrapper} ${themes.blueWhite} ${layouts.layout01}`}
      >
        <div className={styles.layoutWrapper}>
          <main
            className={`${styles.mainWrapper} ${
              show ? styles.shown : styles.hidden
            } `}
          >
            <SideNavigation menu={menu} />
            <div className={styles.childrenWrapper}>{children}</div>
          </main>
        </div>
      </div>
    </AuthProvider>
  );
});

//* Display name for memoization.
GlobalLayout.displayName = "GlobalLayout";
export default GlobalLayout;
