import { memo, ReactNode, useEffect } from "react";
import styles from "../wagerRowDialog/wagerRowDialog.module.scss";

//* Dialog Prop Types
interface DialogProps {
  headerTemplate?: any;
  children?: ReactNode;
  hideCloseBtn?: boolean;
  show?: boolean;
  closeOnBackdrop?: boolean;
  closeOnEsc?: boolean;
  setShow?: (b: boolean) => void;
  glassEffect?: boolean;
}

//* Dialog
const RowDialog = memo(
  ({ setShow, show, closeOnEsc, ...props }: DialogProps) => {
    // * Effect to handle keyUp event listener.
    useEffect(() => {
      // * Handle Key Up Event change to hide dialog on event click
      const handleKeyUp = (e: KeyboardEvent) =>
        e.key === "Escape" && setShow && setShow(false);

      if (closeOnEsc && show) window.addEventListener("keyup", handleKeyUp);
      else if (!show) window.removeEventListener("keyup", handleKeyUp);
      // * Destroy the listener. CallBack should be same to be destroyed
      return () => window.removeEventListener("keyup", handleKeyUp);
    }, [closeOnEsc, setShow, show]);

    /* handle Close Panel */

    const handleClose = () => {
      setShow && setShow(false);
    };

    if (show)
      return (
        <div className={styles.dialogWrapper}>
          <div
            className={`${styles.backdrop} ${
              props.glassEffect ? styles.blurBg : ""
            }`}
            onClick={() => props.closeOnBackdrop && setShow && setShow(false)}
          ></div>
          <div className={styles.dialogCard}>
            {props.headerTemplate && (
              <div className={styles.dialogHeader}>
                {props.headerTemplate()}
              </div>
            )}
            <div className={styles.dialogBody}>{props.children}</div>
            {!props.hideCloseBtn && (
              <button onClick={handleClose} className={styles.closeBtn}>
                &times;
              </button>
            )}
          </div>
        </div>
      );
    return null;
  }
);

//* Display name for memoization.
RowDialog.displayName = "RowDialog";
export default RowDialog;
