import { useLocation, useNavigate } from "react-router";
import { REPORT_TAB } from "../../../common/routes/default.routes";
import FlatButton from "../../../components/elements/buttons/flat.button";
import DatePicker from "react-datepicker";
import { AtomAuthUser, AtomShowSidebar } from "../../../store/auth.store";
import { useRecoilState, useRecoilValue } from "recoil";
import { FiMenu } from "react-icons/fi";
import {
  CsvHeaders,
  SettlementLogColumns,
} from "../../../common/constants/columns.constant";
import {
  AtomSettlementLog,
  AtomSettlementLogDate,
} from "../../../store/report.store";
import { API, API_MOD } from "../../../common/config/api.config";
import {
  formatDate,
  formatDateString,
  formatTimestamp,
} from "../../../common/helpers/date.helper";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { FORMAT } from "../../../common/constants/datetime.constant";
import { CSVLink } from "react-csv";
import {
  ColumnBodyOptions,
  ElementChangeEvent,
} from "../../../common/types/ui.types";
import PrimeTable from "../../../components/ui/table/primeTable.ui";
import { SettlementLogDTO } from "../../../common/models/report.model";
import {
  NameIdTemplate,
  objToStringList,
  toLocaleNumber,
} from "../../../common/helpers/general.helper";
import styles from "./_reports.module.scss";
import { AtomAggregatorsObj } from "../../../store/aggregator.store";
import { AtomOperators } from "../../../store/operator.store";
import { checkError } from "../../../common/helpers/validation.helper";
import { useCallback, useEffect, useState } from "react";
import { FaFilter, FaFlag } from "react-icons/fa";
import SearchBar from "../../../components/ui/searchBar/searchBar";
import { FilterDTO, __FilterDTO } from "../../../common/models/filter.modal";
import { FILTER, RolesType } from "../../../common/constants/general.constant";
import Filter from "../../../components/elements/Filter/filter.element";
import { type } from "os";
import { FilterHelper } from "../../../common/helpers/filter.helper";

const InitFilter = {
  [FILTER.settlementType]: { options: [], selected: [] },
  [FILTER.settledBy]: { options: [], selected: [] },
  [FILTER.operator]: { options: [], selected: [] },
};

const SettlementHistoryPage = () => {
  const path = useLocation().pathname;

  const [isOpen, setIsOpen] = useRecoilState(AtomShowSidebar);
  const [selectedDate, setSelectedDate] = useRecoilState(AtomSettlementLogDate);
  const [settlementLog, setSettlementLog] = useRecoilState(AtomSettlementLog);
  const aggregators = useRecoilValue(AtomAggregatorsObj);
  const authUser = useRecoilValue(AtomAuthUser);
  const operators = useRecoilValue(AtomOperators);
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [filterData, setFilterData] = useState<__FilterDTO>(InitFilter);
  const [filterShow, setFilterShow] = useState(false);
  const [filterSettlement, setFilteredSettlement] = useState<
    SettlementLogDTO[]
  >([]);
  const [csvData, setCsvData] = useState<any[]>([]);

  const navigate = useNavigate();

  const onSubmit = () => {
    initializeOptions(settlementLog);
    let selectStartDate = selectedDate.startDate;
    let selectEndDate = selectedDate.endDate;
    let dateFirst = new Date(format(selectStartDate, FORMAT.mmddyyyy_slash));
    let dateSecond = new Date(format(selectEndDate, FORMAT.mmddyyyy_slash));
    let timeDiff = Math.abs(dateSecond.getTime() - dateFirst.getTime());
    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

    if (diffDays > 30) {
      console.log(diffDays, "--Datefirest");
      toast.error("Start Date Should Not Be More Than 30 Days", {
        bodyClassName: "text",
        className: "text",
        position: "bottom-right",
      });
      return;
    }

    if (dateFirst > dateSecond) {
      toast.error("End date should not be greater than start date", {
        bodyClassName: "text",
        className: "text",
        position: "bottom-right",
      });
      return;
    }
    setLoader(true);
    let startDate = formatDate(selectStartDate);
    let endDate = formatDate(selectEndDate);
    let transactionPayload: any = {
      startDate: startDate.toString(),
      endDate: endDate.toString(),
    };
    API.post(`${API_MOD.REPORT}/settlements`, transactionPayload, {
      headers: {
        Authorization: `${authUser?.token}`,
      },
    })
      .then((res) => {
        let response = (res?.data as SettlementLogDTO[]) ?? [];
        const _sorted = response.sort((a, b) =>
          a.settledTimeStamp < b.settledTimeStamp
            ? 1
            : a.settledTimeStamp > b.settledTimeStamp
            ? -1
            : 0
        );
        setSettlementLog(_sorted);
        setFilteredSettlement(_sorted);
        setLoader(false);
      })
      .catch((error) => {
        checkError(error, true, navigate);
        setLoader(false);
      });
  };

  const ColumnBody = (
    rowData: SettlementLogDTO | any,
    { field }: ColumnBodyOptions
  ) => {
    const _data = rowData[field] ?? "-";
    switch (field) {
      case "settlePeriod":
        return (
          <>
            {formatDateString(rowData.lastReportStartDate)} -{" "}
            {formatDateString(rowData.lastReportEndDate)}
          </>
        );
      case "localDate":
        return <>{formatDateString(_data)}</>;
      case "aggregatorId":
        const name = aggregators[rowData?.aggregatorId]
          ? aggregators[rowData?.aggregatorId].detail
          : rowData?.aggregatorId ?? "";

        return NameIdTemplate(name, rowData.aggregatorId);
      case "operatorId":
        const data = rowData.isDefault === true ? aggregators : operators;
        const _name =
          data[rowData.operatorId] && data[rowData.operatorId].detail
            ? data[rowData.operatorId].detail
            : rowData.operatorId;
        return (
          <>
            {NameIdTemplate(
              _name,
              rowData.operatorId,
              rowData?.operatorId === rowData.aggregatorId
            )}
          </>
        );
      case "reportBalance":
        return <div className={styles.alignRight}>{toLocaleNumber(_data)}</div>;
      case "openingBalance":
        return <div className={styles.alignRight}>{toLocaleNumber(_data)}</div>;
      case "closingBalance":
        return <div className={styles.alignRight}>{toLocaleNumber(_data)}</div>;
      case "settlementAmount":
        return <div className={styles.alignRight}>{toLocaleNumber(_data)}</div>;
      case "settlementAmt":
        return <div className={styles.alignRight}>{toLocaleNumber(_data)}</div>;
      case "settledTimeStamp":
        return (
          <div className={styles.alignRight}>{formatTimestamp(_data)}</div>
        );
      case "action":
        return (
          <div className={styles.actionWrapper}>
            <>
              <FlatButton className={styles.actionBtn}>BET</FlatButton>
            </>
          </div>
        );
      default:
        return <>{_data}</>;
    }
  };

  const handleDateChange = (date: any, name: any) => {
    let _date = new Date(date);

    if (name === "startDate" && _date > selectedDate.endDate) {
      setSelectedDate({
        endDate: _date,
        startDate: _date,
      });
    } else {
      setSelectedDate({ ...selectedDate, [name]: _date });
    }
  };

  const handleChange = (e: ElementChangeEvent) => {
    initializeOptions(settlementLog);
    let val = e.data.toLowerCase();
    setSearch(e.data);

    const _filterData = [...settlementLog].filter((c) => {
      let operator = operators[c.operatorId]?.detail;

      const isValid =
        operator?.toLowerCase().startsWith(val) ||
        c.aggregatorId?.toLowerCase().startsWith(val) ||
        c.operatorId?.toLowerCase().startsWith(val) ||
        c.settlementType?.toLowerCase().startsWith(val) ||
        c.settledBy?.toLowerCase().startsWith(val) ||
        c.settlementId?.toLowerCase().startsWith(val) ||
        c.settlementNote?.toLowerCase().startsWith(val) ||
        operator?.toLowerCase().includes(val) ||
        c.operatorId?.toLowerCase().includes(val) ||
        c.settlementType?.toLowerCase().includes(val) ||
        c.settledBy?.toLowerCase().includes(val) ||
        c.settlementId?.toLowerCase().includes(val) ||
        c.settlementNote?.toLowerCase().includes(val) ||
        formatTimestamp(c.settledTimeStamp).includes(val) ||
        formatTimestamp(c.settledTimeStamp).startsWith(val) ||
        formatDateString(c.lastReportStartDate).includes(val) ||
        formatDateString(c.lastReportStartDate).startsWith(val) ||
        formatDateString(c.lastReportEndDate).includes(val) ||
        formatDateString(c.lastReportEndDate).startsWith(val);

      return isValid;
    });

    setFilteredSettlement(_filterData);
  };

  const handleMenuOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleRoute = (route: string) => {
    navigate(route);
  };

  const initializeOptions = useCallback(
    (settlementLog: SettlementLogDTO[]) => {
      const _settlementType = objToStringList(settlementLog, "settlementType");
      const _settledBy = objToStringList(settlementLog, "settledBy");
      const _operator = objToStringList(settlementLog, "operatorId");

      setFilterData({
        ...filterData,
        settlementType: {
          ...filterData.settlementType,
          options: _settlementType,
        },
        settledBy: { ...filterData.settledBy, options: _settledBy },
        operator: { ...filterData.settledBy, options: _operator },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [settlementLog]
  );

  useEffect(() => {
    initializeOptions(settlementLog);
    handleChange({ data: search, name: "" });
    setFilteredSettlement(settlementLog);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settlementLog]);

  const handleFilter = () => {
    setFilterShow(!filterShow);
  };

  const handleExportCsv = () => {
    let _csv: any = [];
    let _data = [...settlementLog];

    for (const settle of _data) {
      let _obj: any = { ...settle };
      _obj["settledTimeStamp"] = formatTimestamp(settle.settledTimeStamp);
      _obj["aggregatorId"] = settle.aggregatorId;
      _obj["operatorId"] = settle.operatorId;
      _obj["aggregatorDetail"] =
        aggregators[settle.aggregatorId]?.detail?.toString();
      _obj["operatorDetails"] =
        operators[settle.operatorId]?.detail?.toString();
      _obj["lastReportStartDate"] = formatDateString(
        settle.lastReportStartDate
      );
      _obj["lastReportEndDate"] = formatDateString(settle.lastReportEndDate);
      _obj["settlementType"] = settle.settlementType;
      _obj["openingBalance"] = settle.openingBalance;
      _obj["reportBalance"] = settle.reportBalance;
      _obj["settlementAmount"] = settle.settlementAmount;
      _obj["closingBalance"] = settle.closingBalance;
      _obj["settlementNote"] = settle.settlementNote;
      _obj["settledBy"] = settle.settledBy;
      _obj["settlementId"] = settle.settlementId;
      _obj["currency"] = settle.currency;
      _csv.push(_obj);
    }
    setCsvData(_csv);
  };

  const handleFilterChange = (e: ElementChangeEvent) => {
    let _filter = { ...filterData };
    _filter = { ..._filter, [e.name]: e.data };
    setFilterData(_filter);
  };

  const onClearClick = () => {
    setSearch("");
    initializeOptions(settlementLog);
    setFilteredSettlement(settlementLog);
  };

  const onFilterClick = () => {
    setSearch("");
    const _settlementType = filterData[FILTER.settlementType];
    const _settlementBy = filterData[FILTER.settledBy];
    const _operator = filterData[FILTER.operator];

    let _filteredSettlement: SettlementLogDTO[] = [];

    for (const trans of settlementLog) {
      if (
        _settlementType &&
        _settlementType?.selected?.length &&
        !_settlementType.selected.includes(trans.settlementType)
      )
        continue;
      if (
        _settlementBy &&
        _settlementBy?.selected?.length &&
        !_settlementBy?.selected?.includes(trans.settledBy)
      )
        continue;
      if (
        _operator &&
        _operator?.selected?.length &&
        !_operator?.selected?.includes(trans.operatorId)
      )
        continue;
      _filteredSettlement.push(trans);
    }
    setFilteredSettlement(_filteredSettlement);
  };

  useEffect(() => {
    if (settlementLog.length === 0) onSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterSearch = (e: ElementChangeEvent, type?: string) => {
    switch (type) {
      case FILTER.operator:
        const filterResult = FilterHelper.Operator(
          e.data,
          settlementLog,
          operators
        );
        const _operator = objToStringList(filterResult, "operatorId");

        setFilterData({
          ...filterData,
          operator: { ...filterData.operator, options: _operator },
        });
        break;
      case FILTER.settlementType:
        const _filterResult = FilterHelper.SettlementType(
          e.data,
          settlementLog
        );
        const _settlementType = objToStringList(
          _filterResult,
          "settlementType"
        );

        setFilterData({
          ...filterData,
          settlementType: {
            ...filterData.settlementType,
            options: _settlementType,
          },
        });
        break;
      case FILTER.settledBy:
        const settleByRes = FilterHelper.SettleBy(e.data, settlementLog);
        const _settleByRes = objToStringList(settleByRes, "settledBy");

        setFilterData({
          ...filterData,
          settledBy: { ...filterData.settledBy, options: _settleByRes },
        });
        break;
    }
  };

  return (
    <div>
      <header className={styles.headerWrapper1}>
        <div className={styles.menuIcon}>
          <FiMenu
            onClick={handleMenuOpen}
            size={40}
            className="burgerMenuIcon"
          />
        </div>
        {REPORT_TAB.map((tab, key) => {
          const isActive = path.startsWith(tab.href);
          if (tab.roles?.includes(authUser?.role ?? ""))
            return (
              // <span

              <FlatButton
                key={key}
                label={tab.label}
                className={`${styles.iconSet} ${isActive && styles.activeLink}`}
                onClick={() => handleRoute(tab.href)}
              />
            );
          return null;
        })}
      </header>
      <div className={styles.filterReportHeader}>
        <header className={styles.settlementWrapper}>
          <div className={styles.datePickerWrapper}>
            <div className={styles.dateRow}>
              <div className={styles.row}>
                <p className={styles.label}>Start Date</p>
                <DatePicker
                  selected={selectedDate.startDate}
                  className={styles.dateWrp}
                  onChange={(date) => handleDateChange(date, "startDate")}
                  dateFormat="dd-MM-yyyy"
                  maxDate={new Date()}
                />
              </div>
              <div className={styles.row}>
                <p className={styles.label}>End Date</p>
                <DatePicker
                  selected={selectedDate.endDate}
                  className={styles.dateWrp}
                  onChange={(date) => handleDateChange(date, "endDate")}
                  dateFormat="dd-MM-yyyy"
                  maxDate={new Date()}
                  // minDate={selectedDate.startDate}
                />
              </div>
            </div>
          </div>
          <div className={styles.actionSet}>
            <div className={styles.btnSet}>
              <FlatButton
                label="Submit"
                onClick={onSubmit}
                className={styles.settleSubmit}
              />
            </div>
            <div>
              {settlementLog.length !== 0 && (
                <FlatButton
                  className={`${styles.exportButton} ${styles.settleSubmit}`}
                  onClick={handleExportCsv}
                >
                  <CSVLink
                    data={csvData}
                    filename={`SettlementReport_${formatDate(
                      selectedDate.startDate
                    )}_${formatDate(selectedDate.endDate)} `}
                    headers={CsvHeaders}
                  >
                    Export
                  </CSVLink>
                </FlatButton>
              )}
            </div>
          </div>
        </header>
        <div className={styles.filterWrp}>
          <FlatButton className={styles.filterIcon} onClick={handleFilter}>
            <FaFilter size={14} />
          </FlatButton>
          <SearchBar
            onChange={handleChange}
            value={search}
            className={styles.search}
          />
        </div>
      </div>

      {/* <ReportHeader
        selectedDate={selectedDate.startDate}
        onSubmit={onSubmit}
        onDateChange={(date) => handleDateChange(date, "startDate")}
        InputChange={handleChange}
        // onEndDateChange={(date) => handleDateChange(date, "startDate")}
      /> */}
      <Filter
        show={filterShow}
        filterData={filterData}
        onFilterCHange={handleFilterChange}
        onFilterClick={onFilterClick}
        onClearClick={onClearClick}
        onFilter={filterSearch}
      />
      <PrimeTable
        data={filterSettlement}
        columns={SettlementLogColumns}
        ColumnBody={ColumnBody}
        isLoading={loader}
      />

      {/* <Table
        columns={SettlementLogColumns}
        bordered
        data={settlementLog}
        Column={Column}
        sortable
        sortFields={[
          "agentId",
          "operatorId",
          "province",
          "settleBy",
          "settlementAmount",
          "closingBalance",
          "lastReportEndDate",
          "lastReportStartDate",
          "openingBalance",
          "reportBalance",
          "settledTimeStamp",
          "localDate",
          "settledTransactionId",
          "settlementId",
          "settlementNote",
          "settlementType",
        ]}
      /> */}
    </div>
  );
};

export default SettlementHistoryPage;
