import { LS_KEYS } from "../constants/general.constant";
import { UserDTO } from "../models/user.model";
import { getLSObject } from "./storage.helper";

export const getHeader = () => {
  const user: UserDTO = getLSObject(LS_KEYS.USER);
  return {
    Authorization: user?.token ?? "",
  };
};
