import axios from "axios";

const baseURL = "https://staggingproxyapi.ashva.app/";

export const API = axios.create({ baseURL });

// Backend modules to be reused
export const API_MOD = {
  REPORT: "report",
  OPERATOR: "operator",
  AGGREGATOR: "aggregator",
  USER: "user",
};
