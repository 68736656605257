import SignInForm from "./forms/signIn.form";
import styles from "./signIn.module.scss";
import logo from "../../assets/images/goRacingLogo.png";

const SignInPage = () => {
  return (
    <div className={styles.body}>
      <div className={styles.wrapper}>
        {/* <div className={styles.logo}> */}
        <img src={logo} alt="Go Racing Logo" className={styles.logo} />
        {/* </div> */}
        <div className={styles.title}>Sign In To Your Account</div>
        <div className={styles.subtitle}>Enter Your Credentials To Sign In</div>
        <SignInForm />
      </div>
    </div>
  );
};

export default SignInPage;
