import { useState, KeyboardEvent } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useSetRecoilState } from "recoil";
import { API } from "../../../common/config/api.config";
import { LS_KEYS } from "../../../common/constants/general.constant";
import { checkError } from "../../../common/helpers/validation.helper";
import { ROUTES } from "../../../common/routes/default.routes";
import { ElementChangeEvent } from "../../../common/types/ui.types";
import FlatButton from "../../../components/elements/buttons/flat.button";
import InputText from "../../../components/elements/input/inputText.element";
import { AtomAuthUser } from "../../../store/auth.store";
import styles from "./forms.module.scss";

const SignInForm = () => {
  const [user, setUser] = useState({ username: "", password: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMassage] = useState("");
  const setAuthUser = useSetRecoilState(AtomAuthUser);
  const navigate = useNavigate();
  const handleChange = (event: ElementChangeEvent) => {
    setErrorMassage("");
    setUser({
      ...user,
      [event.name]: event.data,
    });
  };

  const handleKeyUp = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === "Enter") onSignIn();
  };

  const onSignIn = () => {
    if (isLoading) return;
    if (user.username === "") {
      setErrorMassage("Email Should Not Be Empty!!");
      return;
    }
    if (user.password === "") {
      setErrorMassage("Password Should Not Be Empty!!");
      return;
    }
    setIsLoading(true);
    API.post(`login/auth`, user)
      .then((res) => {
        const user = res.data;
        localStorage.setItem(LS_KEYS.USER, JSON.stringify(user));
        setAuthUser(user);
        navigate(ROUTES.DASHBOARD);
        toast.success("Admin Successfully Logged in.", {
          bodyClassName: "text",
          className: "text",
          position: "bottom-right",
        });
        setIsLoading(false);
      })
      .catch((err) => {
        checkError(err, true);
        let _error = err?.response?.data?.message;
        setErrorMassage(_error);
        setIsLoading(false);
      });
  };

  return (
    <>
      
      <div className={styles.inputSection}>
        <InputText
          name="username"
          value={user.username}
          inputClass={styles.input}
          type="text"
          placeholder="User Name"
          onChange={handleChange}
          autoFocus={true}
          onKeyUp={handleKeyUp}
        />

        <InputText
          name="password"
          value={user.password}
          inputClass={styles.input}
          type="password"
          placeholder="Password"
          onChange={handleChange}
          onKeyUp={handleKeyUp}
        />
        <div className={styles.errorMessage}>
          {errorMessage ? errorMessage : ""}
        </div>
      </div>
      <div className={styles.login}>
        <FlatButton
          label="Sign In"
          severity="primary"
          className={styles.loginBtn}
          onClick={onSignIn}
          size="md"
          isLoading={isLoading}
        />
      </div>
    </>
  );
};
export default SignInForm;
