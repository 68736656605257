export const LoginScreens = ["admin", "operator", "agent"];

export type RolesType = "aggregator" | "operator";

export const LS_KEYS = {
  USER: "$user",
};

export const ROLES = {
  AGGREGATOR: "AGGREGATOR",
  ADMIN: "ADMIN",
  OPERATOR: "OPERATOR",
};

export const STATUS = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  SIM: "SIM",
  LIVE: "LIVE",
};

export const FILTER = {
  user: "user",
  aggregator: "aggregator",
  operator: "operator",
  role: "role",
  currency: "currency",
  environment: "environment",
  status: "status",
  isDefault: "isDefault",
  methodType: "methodType",
  clientId: "clientId",
  roundId: "roundId",
  betStatus: "betStatus",
  poolType: "poolType",
  raceKey: "raceKey",
  connectorId: "connectorId",
  settlementType: "settlementType",
  settledBy: "settledBy",
  username: "username",
  wagerCurrency: "wagerCurrency",
};
