import { KeyboardEvent, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { ROLES } from "../../../common/constants/general.constant";
import { UserRoles } from "../../../common/data/options.data";
import { UserDTO } from "../../../common/models/user.model";
import { ElementChangeEvent } from "../../../common/types/ui.types";
import FlatButton from "../../../components/elements/buttons/flat.button";
import Dropdown from "../../../components/elements/dropdown/dropdown.element";
import InputText from "../../../components/elements/input/inputText.element";
import Dialog from "../../../components/ui/dialog/dialog.ui";
import { AtomOperators } from "../../../store/operator.store";
import { AtomAuthUser } from "../../../store/auth.store";
import { AtomAggregatorsObj } from "../../../store/aggregator.store";
import styles from "../userManagement.module.scss";
import { NameIdTemplate } from "../../../common/helpers/general.helper";

interface AddUserDialogProps {
  show: boolean;
  setShow: (b: boolean) => void;
  userDetails: UserDTO | any;
  onChange: (user: UserDTO) => void;
  onSave: () => void;
}

const AddUserDialog = ({
  show,
  setShow,
  userDetails,
  ...props
}: AddUserDialogProps) => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [options, setOptions] = useState<{
    operators: string[];
    aggregators: string[];
  }>({ operators: [], aggregators: [] });
  const operators = useRecoilValue(AtomOperators);
  const aggregators = useRecoilValue(AtomAggregatorsObj);
  const [roles, setRoles] = useState<string[]>([]);
  const authUser = useRecoilValue(AtomAuthUser);

  useEffect(() => {
    if (authUser?.role) {
      switch (authUser.role) {
        case ROLES.AGGREGATOR:
          setRoles([ROLES.OPERATOR]);
          break;
        case ROLES.ADMIN:
          setRoles(UserRoles);
          break;
        default:
          setRoles([]);
          break;
      }
    }
  }, [authUser]);
  // console.log({ aggregators });
  /* Functions */
  const handleChange = (e: ElementChangeEvent) => {
    setErrorMessage("");
    let _update = {
      ...userDetails,
      [e.name]: e.data ?? "",
    };
    if (e.name === "operatorId" && authUser?.role === ROLES.AGGREGATOR) {
      props.onChange({
        ..._update,
        aggregatorId: authUser?.aggregatorId,
      });
      return;
    }

    props.onChange(_update);
  };

  const onUserRoleSelect = (e: ElementChangeEvent) => {
    // console.log(e);
    let _user: UserDTO = { ...userDetails };
    const _opOpt = Object.keys(aggregators);
    switch (e.data) {
      case ROLES.AGGREGATOR:
        setOptions({ ...options, aggregators: _opOpt });
        break;
      case ROLES.OPERATOR:
        let _operator =
          authUser?.role === ROLES.AGGREGATOR
            ? authUser.aggregatorId
            : _opOpt[0]
            ? _opOpt[0]
            : "";
        // console.log({ _operator });

        const _agentOpt = Object.values(operators)
          .filter((a) => a.aggregatorId === _operator && !a.isDefault)
          .map((a) => a.operatorId);

        setOptions({ aggregators: _opOpt, operators: _agentOpt });
        break;
      default:
        break;
    }
    props.onChange({
      ..._user,
      role: e.data,
      aggregatorId: "",
      operatorId: "",
    });
  };

  const onOperatorChange = (e: ElementChangeEvent) => {
    const opId = e.data;
    let _agents = Object.values(operators).filter(
      (a) => a.aggregatorId === opId && !a.isDefault
    );
    let _ids = _agents.map((a) => a.operatorId);
    // console.log(opId);

    // console.log({ _agents });

    setOptions({ ...options, operators: _ids });
    props.onChange({ ...userDetails, aggregatorId: opId, operatorId: "" });
  };

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      props.onSave();
    }
  };

  const operatorOptTemplate = (option: string | any) => {
    const _name = operators[option] ? operators[option].detail : option;
    // console.log({ _name });
    // console.log({ option /*  */ });

    return NameIdTemplate(_name, option);
  };

  const aggregatorOptTemplate = (option: string) => {
    const name = aggregators[option] ? aggregators[option].detail : option;
    return NameIdTemplate(name, option);
  };

  const aggregatorValueTemplate = (value: string) => {
    const _val = aggregators[value] ? aggregators[value].detail : value;
    return _val;
  };

  const operatorValueTemplate = (value: string) => {
    const _val = operators[value] ? operators[value].detail : value;
    return _val;
  };

  return (
    <Dialog
      closeOnEsc
      hideCloseBtn={false}
      show={show}
      setShow={setShow}
      headerTemplate={() => <h1>Add New User</h1>}
      glassEffect={true}
    >
      <div className={styles.dialogBody}>
        <div className={styles.GridWrapper}>
          <div className={styles.section1}>
            <InputText
              name={"fullName"}
              value={userDetails?.fullName ?? ""}
              className={styles.input}
              type="text"
              label="Full Name"
              labelClass={styles.label}
              placeholder="Enter Name"
              onChange={handleChange}
              onKeyUp={handleKeyUp}
            />
            <InputText
              name="username"
              value={userDetails?.username ?? ""}
              className={styles.input}
              type="text"
              label="Username"
              labelClass={styles.label}
              placeholder="Enter Username"
              onChange={handleChange}
              onKeyUp={handleKeyUp}
            />
            <Dropdown
              options={roles}
              selectedValue={userDetails?.role ?? ""}
              name="role"
              label="User Role"
              placeholder="Select User Role"
              onChange={(e) => {
                onUserRoleSelect(e);
              }}
            />

            {userDetails.role !== ROLES.ADMIN && (
              <>
                {authUser?.role !== ROLES.AGGREGATOR &&
                  (
                  (
                    <Dropdown
                      options={options.aggregators} // dynamic
                      selectedValue={userDetails?.aggregatorId ?? ""}
                      optionTemplate={aggregatorOptTemplate}
                      name={"aggregatorId"}
                      label={
                        !userDetails?.role
                          ? "Select Role First"
                          : `Select Aggregator`
                      }
                      placeholder={
                        !userDetails?.role
                          ? "Select Role First..."
                          : `Select Aggregator`
                      }
                      onChange={onOperatorChange}
                      disabled={
                        !userDetails?.role || userDetails?.role === "ADMIN"
                      }
                      valueTemplate={aggregatorValueTemplate}
                      optionKey="aggregatorId"
                    />
                  ))}

                {userDetails?.role === ROLES.OPERATOR && (
                  <Dropdown
                    options={options.operators} // dynamic
                    selectedValue={userDetails?.operatorId ?? ""}
                    name={"operatorId"}
                    label={`Select Operator`}
                    placeholder={`Select Operator`}
                    onChange={handleChange}
                    disabled={userDetails?.role === "ADMIN"}
                    optionTemplate={operatorOptTemplate}
                    valueTemplate={operatorValueTemplate}
                  />
                )}
              </>
            )}

            <InputText
              name={"email"}
              value={userDetails?.email ?? ""}
              type="email"
              label="Email ID"
              labelClass={styles.label}
              placeholder="Enter here"
              onChange={handleChange}
              onKeyUp={handleKeyUp}
            />
            <InputText
              name={"password"}
              autoComplete={"off"}
              value={userDetails?.password ?? ""}
              type="password"
              label="Password"
              labelClass={styles.label}
              placeholder="Enter Password"
              onChange={handleChange}
              onKeyUp={handleKeyUp}
            />
          </div>
          <div className="errorMsg">{errorMessage}</div>
          <div className={styles.buttonDiv}>
            <FlatButton
              onClick={props.onSave}
              className={styles.dialogButton}
              label="SAVE"
            />
            <FlatButton
              className={styles.dialogButton}
              onClick={() => setShow(false)}
              label="CANCEL"
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default AddUserDialog;
