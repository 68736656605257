import { ReactNode, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { AtomAuthUser } from "../store/auth.store";

interface ReportProviderProps {
  children: ReactNode;
}
const ReportProvider = ({ children }: ReportProviderProps) => {
  const authUser = useRecoilValue(AtomAuthUser);

  // const getTransaction = () => {
  //   if (!authUser?.token) return;
  //   let transactionPayload: any = {
  //     startDate: startDate.toString(),
  //     endDate: endDate.toString(),
  //   };
  //   API.post(`${API_MOD.REPORT}/transactions`, transactionPayload, {
  //     headers: {
  //       Authorization: `${authUser?.token}`,
  //     },
  //   })
  //     .then((res) => {
  //       const data: TransactionDTO[] = res?.data ?? [];

  //       const _sorted = data.sort((a, b) =>
  //         a.logTimestamp < b.logTimestamp
  //           ? 1
  //           : a.logTimestamp > b.logTimestamp
  //           ? -1
  //           : 0
  //       );

  //       // console.log({ _sorted }, "transaction");

  //       setTransaction(_sorted);
  //     })
  //     .catch((error) => {
  //       checkError(error, true, navigate);
  //     });
  // };

  // const getWager = () => {
  //   let wagerPayload: any = {
  //     startDate: startWagerDate.toString(),
  //     endDate: endWagerDate.toString(),
  //   };
  //   API.post(`${API_MOD.REPORT}/wagers`, wagerPayload, {
  //     headers: {
  //       Authorization: `${authUser?.token}`,
  //     },
  //   })
  //     .then((res) => {
  //       const data: WagerDTO[] = res?.data ?? [];

  //       const _sorted = data.sort((a, b) =>
  //         a.resTimeStamp < b.resTimeStamp
  //           ? 1
  //           : a.resTimeStamp > b.resTimeStamp
  //           ? -1
  //           : 0
  //       );
  //       console.log({ _sorted }, "wagers");

  //       setWager(_sorted);
  //     })
  //     .catch((error) => {
  //       checkError(error, true, navigate);
  //     });
  // };

  // const getSettlementLog = () => {
  //   let settlementLogPaylod: any = {
  //     startDate: startWagerDate.toString(),
  //     endDate: endWagerDate.toString(),
  //   };
  //   API.post(`${API_MOD.REPORT}/settlements`, settlementLogPaylod, {
  //     headers: {
  //       Authorization: `${authUser?.token}`,
  //     },
  //   })
  //     .then((res) => {
  //       const data: SettlementLogDTO[] = res?.data ?? [];

  //       const _sorted = data.sort((a, b) =>
  //         a.settledTimeStamp < b.settledTimeStamp
  //           ? 1
  //           : a.settledTimeStamp > b.settledTimeStamp
  //           ? -1
  //           : 0
  //       );

  //       setSettlementLog(_sorted);
  //     })
  //     .catch((err) => {
  //       checkError(err, true, navigate);

  //       console.log(err);
  //     });
  // };

  // const getAuditReport = () => {
  //   if (authUser?.role !== ROLES.ADMIN) return;
  //   let auditReportPayload: any = {
  //     startDate: startWagerDate.toString(),
  //     endDate: endWagerDate.toString(),
  //   };
  //   API.post(`${API_MOD.REPORT}/auditReport`, auditReportPayload, {
  //     headers: {
  //       Authorization: `${authUser?.token}`,
  //     },
  //   })
  //     .then((res) => {
  //       const data: AuditDTO[] = res?.data ?? [];

  //       const _sorted = data.sort((a, b) =>
  //         a.localDate < b.localDate ? 1 : a.localDate > b.localDate ? -1 : 0
  //       );

  //       setAuditReport(_sorted);
  //     })
  //     .catch((err) => {
  //       checkError(err, true, navigate);

  //       console.log(err);
  //     });
  // };

  useEffect(() => {
    // getTransaction();
    // getWager();
    // getSettlementLog();
    // getAuditReport();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  return <>{children}</>;
};

export default ReportProvider;
