import styles from "./reportHeader.module.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchBar from "../../ui/searchBar/searchBar";
import FlatButton from "../../elements/buttons/flat.button";
import { ElementChangeEvent } from "../../../common/types/ui.types";
import { CSVLink } from "react-csv";
import { formatDate } from "../../../common/helpers/date.helper";
import { FaFilter } from "react-icons/fa";
import { useState } from "react";
import _ from "lodash";

export interface reportHeaderProps {
  selectedDate: Date;
  selectedEndDate: Date;
  onSubmit: () => void;
  InputChange?: (e: ElementChangeEvent) => void | undefined;
  onDateChange: (date: any) => void;
  // maxDate: () => void;
  exportData?: any[];
  onFilter?: () => void;
  search?: string;
  setSearch?: () => void;

  endDate?: Date;
  onEndDateChange: (date: any) => any;
  onExport?: () => void;
  exportFileName?: string;
}

const ReportHeader = ({ ...props }: reportHeaderProps) => {
  const [convertedObj, setConvertedObj] = useState<any>([]);

  const handleStringChange = (exportData: any) => {
    if (!exportData) return;

      let entries: any = [];
      for (const entry of exportData) {
        let _entry = { ...entry };
      if (_entry.selectionsOdds && _entry.poolPayout) {
        _entry.selectionsOdds = JSON.stringify(entry.selectionsOdds).replace(
          /\"/g,
          "'"
        );
        _entry.poolPayout = JSON.stringify(entry.poolPayout).replace(
          /\"/g,
          "'"
        );
      }
      if (_entry.request && _entry.response) {
        _entry.request = JSON.stringify(entry.request).replace(/\"/g, "'");
        _entry.response = JSON.stringify(entry.response).replace(/\"/g, "'");
      }
      entries.push(_entry);
    }
    setConvertedObj(entries);

    return exportData;
  };

  return (
    <div className={styles.headerWrapper}>
      <div className={styles.section1}>
        <div className={styles.dateWrap}>
          <p className={styles.label}> Start Date</p>
          <DatePicker
            selected={props.selectedDate}
            className={styles.dateWrp}
            onChange={props.onDateChange}
            dateFormat="dd-MM-yyyy"
            maxDate={new Date()}
          />
        </div>

        <div className={styles.dateWrap}>
          <p className={styles.label}>End Date</p>
          <DatePicker
            selected={props.selectedEndDate}
            className={styles.dateWrp}
            onChange={props.onEndDateChange}
            dateFormat="dd-MM-yyyy"
            maxDate={new Date()}
          />
        </div>

        <div className={styles.btnSet}>
          <FlatButton label="Submit" onClick={props.onSubmit} />
        </div>

        <div className={styles.exportSet}>
          {props.exportData
            ? props.exportData?.length !== 0 && (
                <FlatButton
                  isDisable={!props.exportData?.length}
                  className={styles.exportButton}
                >
                  <CSVLink
                    data={convertedObj}
                    filename={props.exportFileName}
                    onClick={() => handleStringChange(props.exportData)}
                  >
                    Export
                  </CSVLink>
                </FlatButton>
              )
            : ""}
        </div>
      </div>
      <div className={styles.section2}>
        {props.onFilter ? (
          <FlatButton
            className={styles.filterIcon}
            onClick={props.onFilter && props.onFilter}
          >
            <FaFilter size={14} />
          </FlatButton>
        ) : (
          ""
        )}
        {props.InputChange ? (
          <SearchBar
            onChange={(e) =>
              props.InputChange && props.InputChange({ data: e.data, name: "" })
            }
            value={props.search && props.search}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ReportHeader;
