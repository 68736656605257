import { API, API_MOD } from "../config/api.config";
import { getHeader } from "../helpers/apis.helper";
import { checkError } from "../helpers/validation.helper";
import { FDRowDTO } from "../models/dashborad.model";

interface GetFDSummaryParams {
  startDate: string;
  endDate: string;
  setFDData: (fdRow: FDRowDTO[]) => void;
  setFilteredFDData: (fdRow: FDRowDTO[]) => void;
  setFDOptions: (obj: any) => void;
  setLoader: (r: boolean) => void;
  navigate?: any;
}

export const FinancialDashboardService = () => {
  const getFDSummary = ({
    startDate,
    endDate,
    ...params
  }: GetFDSummaryParams) => {
    params.setLoader(true);
    API.post(
      `${API_MOD.REPORT}/summary`,
      { startDate, endDate },
      { headers: getHeader() }
    )
      .then((res) => {
        const _data = (res.data as FDRowDTO[]) ?? [];
        const _sorted = _data.sort((a, b) =>
          b.localDate < a.localDate ? -1 : b.localDate > a.localDate ? 1 : 0
        );
        params.setFDData(_sorted);
        params.setFilteredFDData(_sorted);
        const aggregators = Array.from(
          new Set(_data.map((x) => x.aggregatorId))
        );
        const operators = Array.from(new Set(_data.map((x) => x.operatorId)));
        params.setFDOptions({ aggregators, operators });
        params.setLoader(false);
      })
      .catch((err) => {
        checkError(err, true, params.navigate);
        params.setFDData([]);
        params.setFilteredFDData([]);
        params.setLoader(false);
      });
  };

  return { getFDSummary };
};
