import { ColumnsDTO } from "../types/ui.types";

export const AggregatorColumns: ColumnsDTO[] = [
  { field: "detail", header: "Aggregator", sortable: true },
  {
    field: "environment",
    header: "Environment",
    sortable: true,
    style: { width: "10rem" },
  },
  {
    field: "status",
    header: "Status",
    sortable: true,
    style: { width: "10rem" },
  },
  { field: "callBackUrl", header: "Callback URL", sortable: true },
  // { field: "oddsAccess", header: "Odds Access", sortable: false },



  { field: "rebateRate", header: "Rebate Rate", sortable: true },
  // { field: "currency", header: "Currency", sortable: true },
  { field: "action", header: "Action" },
];

export const OperatorColumn: ColumnsDTO[] = [
  { field: "aggregatorId", header: "Aggregator", sortable: true },
  { field: "detail", header: "Operator", sortable: true },

  {
    field: "status",
    header: "Status",
    sortable: true,
    style: { width: "10rem" },
  },
  { field: "originUrls", header: "Origin URL", sortable: true },
  { field: "rebateRate", header: "Rebate Rate", sortable: true },
  { field: "action", header: "Action" },
];

export const UserColumns: ColumnsDTO[] = [
  { field: "fullName", header: "Full Name", sortable: true },
  { field: "username", header: "User Name", sortable: true },
  { field: "email", header: "Email", sortable: true },
  { field: "role", header: "User Type", sortable: true },
  { field: "aggregatorId", header: "Aggregator", sortable: true },
  { field: "operatorId", header: "Operator", sortable: true },
  { field: "action", header: "Action" },
];

export const AuditColumns: ColumnsDTO[] = [
  { field: "timestamp", header: "Date/time", sortable: true },
  // { field: "localDate", header: "Local Date", sortable: true },
  { field: "methodType", header: "Method Type", sortable: true },
  { field: "username", header: "Username", sortable: true },
  { field: "impact", header: "Impact", sortable: true },
  { field: "message", header: "Message", sortable: true },
  { field: "error", header: "Error", sortable: true },
];

export const WalletColumns: ColumnsDTO[] = [
  { field: "referenceId", header: "Reference Id" },
  { field: "status", header: "Status" },
  { field: "Currency", header: "Currency" },
  { field: "creditLimit", header: "Credit Limit" },
  { field: "utilizeLimit", header: "Utilize Limit" },
  { field: "lastStatement", header: "Last Statement" },
  { field: "nextStatement", header: "Next statement" },
  { field: "action", header: "Action" },
];

export const TransactionLogColumns: ColumnsDTO[] = [
  { field: "logTimestamp", header: "Date/Time", sortable: true },
  { field: "aggregatorId", header: "Aggregator ID", sortable: true },
  { field: "operatorId", header: "Operator ID", sortable: true },
  { field: "clientId", header: "Client ID", sortable: true },
  { field: "poolType", header: "Pool Type", sortable: true },

  // { field: "refId", header: "Reference ID", sortable: true },
  {
    field: "roundId",
    header: "Round ID",
    sortable: true,
    style: { width: "8rem" },
  },
  { field: "methodType", header: "Method Type", sortable: true },
  // { field: "localDate", header: "Local Date", sortable: true },
];

export const WagerReportColumns: ColumnsDTO[] = [
  { field: "timestamp", header: "Date/Time", sortable: true },
  { field: "aggregatorId", header: "Aggregator", sortable: true }, // TODO: Change agent to operator
  { field: "operatorId", header: "Operator", sortable: true }, // TODO: Change agent to operator
  { field: "clientId", header: "Client", sortable: true },
  // { field: "userId", header: "Client", sortable: true }, // TODO: Change agent to operator
  { field: "betStatus", header: "Bet Status", sortable: true },
  { field: "roundId", header: "Round ID", sortable: true },
  { field: "wagerCurrency", header: "Currency", sortable: true },
  { field: "poolType", header: "Pool Type", sortable: true },
  { field: "raceKey", header: "Race Key", sortable: true },
  { field: "totalInvested", header: "Investment", sortable: true },
  { field: "totalWinnings", header: "Total Winnings", sortable: true },
  { field: "totalRefunds", header: "Refund Amount", sortable: true },
  { field: "pnl", header: "PNL", sortable: true },
  // { field: "provider", header: "Provider", sortable: true },
  { field: "connectorId", header: "Connector", sortable: true },
];

export const SettlementLogColumns: ColumnsDTO[] = [
  { field: "settledTimeStamp", header: "Date/Time", sortable: true },
  // { field: "aggregatorId", header: "Aggregator ID", sortable: true },
  { field: "operatorId", header: "Operator ID", sortable: true },
  { field: "currency", header: "Currency", sortable: true },

  // { field: "localDate", header: "Local Date", sortable: true },
  { field: "settlePeriod", header: "Period", sortable: true },
  { field: "settlementType", header: "Settlement Type", sortable: true },

  { field: "openingBalance", header: "Opening Balance", sortable: true },
  { field: "reportBalance", header: "Report Balance", sortable: true },
  { field: "settlementAmount", header: "Settlement Amount", sortable: true },
  { field: "closingBalance", header: "Closing Balance", sortable: true },
  { field: "settlementNote", header: "Settlement Note", sortable: true },
  { field: "settledBy", header: "Settlement By", sortable: true },
  { field: "settlementId", header: "Settlement ID", sortable: true },
];

export const COLSOperatorBalanceReport: ColumnsDTO[] = [
  {
    field: "detail",
    header: "Operator",
    sortable: true,
  },
  { field: "operatorDetail", header: "Aggregator", sortable: true },
  // {
  //   field: "environment",
  //   header: "Environment",
  //   sortable: true,
  // },
  { field: "closingBalance", header: "Closing Balance", sortable: true },
  { field: "currency", header: "Currency", sortable: true },
  { field: "rebateRate", header: "Rebate Rate", sortable: true },
  // {
  //   field: "lastReportStartDate",
  //   header: "Last Report Start Date",
  //   sortable: true,
  // },
  // {
  //   field: "lastReportEndDate",
  //   header: "Last Report End Date",
  //   sortable: true,
  // },
  { field: "action", header: "Action" },
];

export const COLSAggregatorBalanceReport: ColumnsDTO[] = [
  { field: "operatorDetail", header: "Aggregator", sortable: true },
  {
    field: "environment",
    header: "Environment",
    sortable: true,
  },
  { field: "closingBalance", header: "Closing Balance", sortable: true },
  { field: "currency", header: "Currency", sortable: true },
  { field: "rebateRate", header: "Rebate Rate", sortable: true },
  // {
  //   field: "lastReportStartDate",
  //   header: "Last Report Start Date",
  //   sortable: true,
  // },
  // {
  //   field: "lastReportEndDate",
  //   header: "Last Report End Date",
  //   sortable: true,
  // },
  { field: "action", header: "Action" },
];

export const COLSFinancialDashboard: ColumnsDTO[] = [
  { field: "localDate", header: "Period", sortable: true },
  { field: "aggregatorId", header: "Aggregator", sortable: true },
  { field: "operatorId", header: "Operator", sortable: true },
  { field: "currency", header: "Currency", sortable: true },
  { field: "rebateRate", header: "Rebate Rate", sortable: true },
  { field: "totalInvestment", header: " Turnover", sortable: true },
  { field: "totalRebates", header: "Total Rebate", sortable: true },
  { field: "totalWinnings", header: " Winnings", sortable: true },
  { field: "totalRefund", header: " Refund", sortable: true },
];

export const CsvHeaders = [
  { label: "Date/Time", key: "settledTimeStamp" },
  { label: "Aggregator ID", key: "aggregatorId" },
  { label: "Aggregator Name", key: "aggregatorDetail" },
  { label: "operator ID", key: "operatorId" },
  { label: "Operator Name", key: "operatorDetails" },
  { label: "Currency", key: "currency" },
  { label: "Period Start", key: "lastReportStartDate" },
  { label: "Period end", key: "lastReportEndDate" },
  { label: "Settlement Type", key: "settlementType" },
  { label: "Opening Balance", key: "openingBalance" },
  { label: "Report Balance", key: "reportBalance" },
  { label: "Settlement Amount", key: "settlementAmount" },
  { label: "Closing Balance", key: "closingBalance" },
  { label: "Settlement Note", key: "settlementNote" },
  { label: "Settlement By", key: "settledBy" },
  { label: "Settlement Id", key: "settlementId" },
];

export const PoolType = [
  { label: "Fixed Odds", value: "FIXED", id: "isFixedEnable" },
  { label: "Tote", value: "TOTE", id: "isToteEnable" },
];
