import { useCallback, useEffect, useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import FlatButton from "../../components/elements/buttons/flat.button";
import SearchBar from "../../components/ui/searchBar/searchBar";
import { UserDTO } from "../../common/models/user.model";
import { AtomUsers } from "../../store/user.store";
import { useRecoilState, useRecoilValue } from "recoil";
import { API, API_MOD } from "../../common/config/api.config";
import { toast } from "react-toastify";
import { AtomAuthUser, AtomShowSidebar } from "../../store/auth.store";

import { MdEdit, MdPersonAddAlt1 } from "react-icons/md";
import { FiMenu } from "react-icons/fi";
import AddUserDialog from "./components/addUser.dialog";
import ConfirmDialog from "../../components/ui/dialog/confirmDialog";
import EditUserDialog from "./components/editUser.dialog";
import { checkError } from "../../common/helpers/validation.helper";
import { useNavigate } from "react-router";
import { showToast } from "../../common/helpers/toast.helper";
import { UserColumns } from "../../common/constants/columns.constant";
import PrimeTable from "../../components/ui/table/primeTable.ui";
import {
  isLessWidth,
  NameIdTemplate,
  objToStringList,
} from "../../common/helpers/general.helper";
import { AtomOperators } from "../../store/operator.store";
import { AtomAggregatorsObj } from "../../store/aggregator.store";
import styles from "./userManagement.module.scss";
import { FILTER, ROLES } from "../../common/constants/general.constant";
import { FaFilter } from "react-icons/fa";
import Filter from "../../components/elements/Filter/filter.element";
import { ElementChangeEvent } from "../../common/types/ui.types";
import { __FilterDTO } from "../../common/models/filter.modal";
import { FilterHelper } from "../../common/helpers/filter.helper";

const InitFilter = {
  [FILTER.user]: { options: [], selected: [] },
  [FILTER.role]: { options: [], selected: [] },
  // [FILTER.aggregator]: { options: [], selected: [] },
  // [FILTER.operator]: { options: [], selected: [] },
};

//* UserManagementPage
const UserManagementPage = () => {
  /* VARIABLES */
  const [search, setSearch] = useState("");
  const [filteredUsers, setFilteredUsers] = useState<any[]>([]);
  const [show, setShow] = useState(false);

  // const userType = ["AGENT", "ADMIN", "OPERATOR"];
  // const [userTypes, setUserTypes] = useState<string[]>([]);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserDTO | null>(null);
  const [userDetails, setUserDetails] = useState<any>({} as UserDTO); // for form

  const [showEditDialog, setShowEditDialog] = useState(false);
  const authToken = useRecoilValue<any>(AtomAuthUser);

  /* RECOIL VARIABLES */
  const [users, setUsers] = useRecoilState(AtomUsers); // for table
  const authUser = useRecoilValue(AtomAuthUser);
  const [isOpen, setIsOpen] = useRecoilState(AtomShowSidebar);
  // const agents = useRecoilValue(AtomAgents);
  // const Operators = useRecoilValue(AtomOperatorsList);
  const navigate = useNavigate();
  const agents = useRecoilValue(AtomOperators);
  const operators = useRecoilValue(AtomAggregatorsObj);
  const [filterShow, setFilterShow] = useState(false);
  const [, setLoader] = useState(false);

  const [filterData, setFilterData] = useState<__FilterDTO>(InitFilter);

  const initializeOptions = useCallback(() => {
    const _names = users.map((X) => X.username);
    const _role = objToStringList(users, "role");

    setFilterData({
      ...filterData,
      user: { ...filterData.user, options: _names },
      role: { ...filterData.role, options: _role },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  /* Callbacks */
  useEffect(() => {
    //todo: Filter search wise
    setFilteredUsers(users);
    initializeOptions();
    onChange({ data: search, name: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  const getUsers = useCallback(() => {
    if (!authUser) return;
    const { token, role } = authUser;

    if (role === ROLES.ADMIN || role === ROLES.AGGREGATOR)
      API.get(`${API_MOD.USER}/fetchAll`, {
        headers: {
          Authorization: `${token}`,
        },
      })
        .then((res) => {
          const _data = (res?.data as UserDTO[]) ?? [];
          const _sorted = _data.sort((a, b) =>
            a.fullName < b.fullName ? -1 : a.fullName > b.fullName ? 1 : 0
          );
          setUsers(_sorted);
        })
        .catch((err) => {
          console.log(err);
          checkError(err, true, navigate);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const onDelete = (user: UserDTO | null) => {
    API.post(
      `${API_MOD.USER}/remove`,
      // user?.username,
      {
        username: user?.username,
      },
      {
        headers: {
          Authorization: authUser?.token ?? "",
        },
      }
    )
      .then((response) => {
        if (response) {
          let userList = [...users];
          let index = userList.findIndex(
            (val) => val.username === user?.username
          );
          userList.splice(index, 1);
          setUsers(userList);
          toast.success("User Deleted successfully.", {
            bodyClassName: "text",
            className: "text",
            position: "bottom-right",
          });
        }
      })
      .catch((error) => {
        console.error("There was an error", error);
        checkError(error, true, navigate);
      });
    setShowDeleteConfirm(false);
  };

  const onSave = () => {
    setLoader(true);
    let _userObj = { ...userDetails } as any;
    _userObj["enable"] = true;

    API.post(`${API_MOD.USER}/add`, _userObj, {
      headers: { Authorization: authUser?.token ?? "" },
    })
      .then((res) => {
        if (res) {
          setUsers([userDetails, ...users]);
          setFilteredUsers([userDetails, ...filteredUsers]);
          setUserDetails({} as UserDTO);
          setShow(false);
          setLoader(false);
        }
        showToast("success", "User added successfully..!");
      })
      .catch((error) => {
        setLoader(false);
        checkError(error, true, navigate);
      });
  };

  // CHANGE FUNCTIONS

  const onChange = (e: ElementChangeEvent) => {
    initializeOptions();
    const val = e.data.toLowerCase();
    setSearch(e.data);
    const searchData = [...users].filter((c) => {
      // let opDetail = agents[c.operatorId].detail;
      let operator = agents[c.operatorId]?.detail;
      let aggregator = agents[c.aggregatorId]?.detail;
      const isValid =
        // opDetail?.toLowerCase().startsWith(val) ||
        c.fullName?.toLowerCase().startsWith(val) ||
        c.username?.toLowerCase().startsWith(val) ||
        c.operatorId?.toLowerCase().startsWith(val) ||
        c.aggregatorId?.toLowerCase().startsWith(val) ||
        c.fullName?.toLowerCase().includes(val) ||
        operator?.toLowerCase().startsWith(val) ||
        aggregator?.toLowerCase().startsWith(val) ||
        c.operatorId?.toLowerCase().includes(val) ||
        c.aggregatorId?.toLowerCase().includes(val) ||
        c.username?.toLowerCase().includes(val) ||
        operator?.toLowerCase().includes(val) ||
        c.role?.toLowerCase().startsWith(val) ||
        c.role?.toLowerCase().includes(val) ||
        aggregator?.toLowerCase().includes(val);
      return isValid;
    });
    setFilteredUsers(searchData);
  };

  const onRowDelete = (user: UserDTO) => {
    setSelectedUser(user);
    setShowDeleteConfirm(true);
  };

  // * Open edit dialog to update user.
  const handleFullNameEditDialog = (rowData: UserDTO) => {
    setSelectedUser(rowData);
    setShowEditDialog(true);
  };

  // * On submit of update user dialog form
  const onUpdateUserDetails = (user: UserDTO | null) => {
    if (!user) return;
    API.post(`${API_MOD.USER}/update`, user, {
      headers: {
        Authorization: `${authToken.token}`,
      },
    })
      .then((res) => {
        if (res) {
          let _res = res?.data;
          if (_res) {
            let _userList = [...users];
            let _userObj: UserDTO | any = { ...user };
            _userObj["fullName"] = _res.fullName;
            _userObj["email"] = _res.email;

            let _index = _userList.findIndex((val) => {
              return val.username === _res.username;
            });
            _userList.splice(_index, 1, _userObj);
            setFilteredUsers(_userList);
            setUsers(_userList);
            setShowEditDialog(false);
            toast.success(
              `Full name updated successfully for ${selectedUser?.fullName}..!`,
              {
                bodyClassName: "text",
                className: "text",
                position: "bottom-right",
              }
            );
          }
        }
      })
      .catch((error) => {
        checkError(error, true, navigate);
      });
  };
  const ColBody = (rowData: any, { field, rowIndex }: any) => {
    switch (field) {
      case "Sr_no":
        return <>{++rowIndex}</>;
      case "fullName":
        return (
          <>
            <div className={styles.editBtnWpr}>
              {rowData?.fullName}
              <button
                className={styles.editBtn}
                onClick={() => handleFullNameEditDialog(rowData)}
              >
                <MdEdit size={15} />
              </button>
            </div>
          </>
        );
      case "email":
        const _mail = rowData?.email;
        if (!_mail) return "-";
        return (
          <>
            <div className={styles.editBtnWpr}>
              {_mail}
              <button
                className={styles.editBtn}
                onClick={() => handleFullNameEditDialog(rowData)}
              >
                <MdEdit size={15} />
              </button>
            </div>
          </>
        );

      case "action":
        if (authUser?.username === rowData.username) return null;
        return (
          <>
            <FlatButton
              className={styles.actionBtn}
              onClick={() => onRowDelete(rowData)}
              size="xs"
              severity="warning"
              // isDisable={authUser?.username === rowData.username}
            >
              <RiDeleteBin6Line />
            </FlatButton>
          </>
        );
      case "operatorId":
        let aId = rowData?.operatorId;
        if (aId === "ADMIN") return aId;
        const nameA =
          agents[aId] && agents[aId].detail ? agents[aId].detail : "-";
        aId = agents[aId] && agents[aId].operatorId ? aId : "";
        return NameIdTemplate(nameA, aId);
      case "aggregatorId":
        const oId = rowData.aggregatorId;
        if (oId === "ADMIN") return oId;
        const nameO =
          operators[oId] && operators[oId].detail ? operators[oId].detail : "-";
        return NameIdTemplate(nameO, oId);

      default:
        return <>{rowData[field] ?? "-"}</>;
    }
  };

  const handleFilterChange = (e: ElementChangeEvent) => {
    let _filter = { ...filterData };
    _filter = { ..._filter, [e.name]: e.data };
    setFilterData(_filter);
  };

  const onFilterClick = () => {
    setSearch("");
    const _userFilter = filterData[FILTER.user];
    const _roleFilter = filterData[FILTER.role];
    let _filteredUsers: UserDTO[] = [];

    for (const user of users) {
      if (
        _userFilter &&
        _userFilter?.selected?.length &&
        !_userFilter?.selected.includes(user.username)
      )
        continue;
      if (
        _roleFilter &&
        _roleFilter?.selected?.length &&
        !_roleFilter.selected.includes(user.role)
      )
        continue;
      _filteredUsers.push(user);
    }

    setFilteredUsers(_filteredUsers);
  };

  const onClearClick = () => {
    setSearch("");
    initializeOptions();
    setFilteredUsers(users);
  };

  const filterSearch = (e: ElementChangeEvent, type?: string) => {
    switch (type) {
      case FILTER.user:
        const filterResult = FilterHelper.Username(e.data, users);

        const _user = objToStringList(filterResult, "username");

        setFilterData({
          ...filterData,
          user: { ...filterData.user, options: _user },
        });

        break;
      case FILTER.role:
        const _filterResult = FilterHelper.Role(e.data, users);
        const _role = objToStringList(_filterResult, "role");

        setFilterData({
          ...filterData,
          role: { ...filterData.role, options: _role },
        });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div>
        <header className={"headerWrapper"}>
          <span className={styles.iconNameWrp}>
            <FiMenu
              // className={styles.burgerMenu}
              onClick={() => setIsOpen(!isOpen)}
              size={40}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              className="burgerMenuIcon"
            />
            <span className={styles.nameWrp}>User Management</span>
          </span>
          <div className={styles.searchWrapper}>
            <FlatButton
              className={styles.filterIcon}
              onClick={() => setFilterShow(!filterShow)}
            >
              <FaFilter size={20} />
            </FlatButton>
            <SearchBar onChange={onChange} value={search} />
            <FlatButton
              icon={<MdPersonAddAlt1 size={20} />}
              label={isLessWidth() ? "" : "Add User"}
              severity="light"
              onClick={() => setShow(true)}
              className="addBtn"
            ></FlatButton>
          </div>
        </header>
        <Filter
          show={filterShow}
          filterData={filterData}
          onFilterCHange={handleFilterChange}
          onFilterClick={onFilterClick}
          onClearClick={onClearClick}
          onFilter={filterSearch}
        />
        <PrimeTable
          data={filteredUsers}
          columns={UserColumns}
          ColumnBody={ColBody}
        />

        <AddUserDialog
          setShow={setShow}
          show={show}
          onChange={(user) => setUserDetails(user)}
          onSave={onSave}
          userDetails={userDetails}
        />
        <EditUserDialog
          selectedUser={selectedUser}
          onSubmit={onUpdateUserDetails}
          show={showEditDialog}
          setShow={setShowEditDialog}
        />
        <ConfirmDialog
          message={`Are you sure you want to delete  data of ${
            selectedUser?.username ?? ""
          }?`}
          onConfirm={() => onDelete(selectedUser)}
          show={showDeleteConfirm}
          setShow={setShowDeleteConfirm}
          confirmLabel="Delete"
          confirmSeverity="danger"
          title="Delete User ?"
        />
      </div>
    </>
  );
};

export default UserManagementPage;
