import { useState } from "react";
import { FaFlag } from "react-icons/fa";
import { useRecoilState, useRecoilValue } from "recoil";
import { FORMAT } from "../../../../common/constants/datetime.constant";
import { STATUS } from "../../../../common/constants/general.constant";
import { COLSAdminBalanceReport } from "../../../../common/constants/userColumns.constant";
import { formatDateString } from "../../../../common/helpers/date.helper";
import {
  NameIdTemplate,
  toLocaleNumber,
} from "../../../../common/helpers/general.helper";
import { ReportBalanceDTO } from "../../../../common/models/dashborad.model";
import FlatButton from "../../../../components/elements/buttons/flat.button";
import PrimeTable from "../../../../components/ui/table/primeTable.ui";
import { AtomAdminBalanceReport } from "../../../../store/dashboard.store";
import { AtomAggregatorsObj } from "../../../../store/aggregator.store";
import SettleDialog from "../settleDialog/settleDialog.component";
import styles from "./adminTable.module.scss";
import { AtomOperators } from "../../../../store/operator.store";

interface AgentTableProps {
  fetchAgentBalanceReport: () => void;
  loader?: boolean;
}

const AdminTable = ({ fetchAgentBalanceReport, loader }: AgentTableProps) => {
  const [adminBalanceReport] = useRecoilState(AtomAdminBalanceReport);
  const [showSettle, setShowSettle] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const aggregators = useRecoilValue(AtomAggregatorsObj);
  const operators = useRecoilState(AtomOperators);

  // console.log(operators);
  // console.log("---", aggregators);

  const handleSettle = (rowData: any) => {
    setShowSettle(true);
    setSelectedRow(rowData);
  };

  const TableColumn = (
    rowData: ReportBalanceDTO | any,

    { rowIndex, field }: any
  ) => {
    switch (field) {
      case "Sr_no":
        return ++rowIndex;
      case "agentDetail":
        return (
          <>
            {NameIdTemplate(
              rowData?.agentDetail,
              rowData.operatorId,
              rowData.isDefault,
              aggregators[rowData.aggregatorId]?.isFixedEnable,
              aggregators[rowData.aggregatorId]?.isToteEnable
            )}
          </>
        );
      case "aggregatorId":
        const name =
          aggregators[rowData.aggregatorId] &&
          aggregators[rowData.aggregatorId].detail
            ? aggregators[rowData.aggregatorId].detail
            : rowData.aggregatorId;
        return NameIdTemplate(name, rowData.aggregatorId);

      case "rebateRate":
        return (
          <>
            <div className={styles.alignRight}>
              {rowData?.rebateRate
                ? `${rowData?.rebateRate?.toFixed(2)} %`
                : "-"}
            </div>
          </>
        );
      case "closingBalance":
        return (
          <div className={styles.closingBalance}>
            {toLocaleNumber(rowData?.closingBalance)}
          </div>
        );
      case "environment":
        return rowData.environment === true ? STATUS.LIVE : STATUS.SIM;
      case "lastReportEndDate":
        return (
          <div className="text-center">
            {formatDateString(rowData[field], FORMAT.ddMMyyyy_slash)}
          </div>
        );
      case "lastReportStartDate":
        return (
          <div className="text-center">
            {formatDateString(rowData[field], FORMAT.ddMMyyyy_slash)}
          </div>
        );
      case "action":
        return (
          <div className={styles.actionButtons}>
            <FlatButton
              label="Report/Settle"
              onClick={() => handleSettle(rowData)}
            />
          </div>
        );
      default:
        return rowData[field] ?? "-";
    }
  };

  return (
    <>
      <SettleDialog
        show={showSettle}
        setShow={setShowSettle}
        selectedRow={selectedRow}
        fetchAgentBalanceReport={fetchAgentBalanceReport}
        showCreditDebit
      />

      <PrimeTable
        data={adminBalanceReport}
        columns={COLSAdminBalanceReport}
        ColumnBody={TableColumn}
        isLoading={loader}
      />
    </>
  );
};

export default AdminTable;
