import { SyncLoader } from "react-spinners";
import { Severity } from "../../../common/types/ui.types";
import styles from "./loader.module.scss";
interface LoaderProps {
  severity?: Severity;
  size?: number;
  color?: string;
  show?: boolean;
  setShow?: (b: boolean) => void;
  className?: string;
}

const Loader = ({ size, setShow, show, color, ...props }: LoaderProps) => {
  if (!show) return null;
  return (
    <div className={`${styles.wrapper} ${props.className ?? ""}`}>
      <SyncLoader
        color={color ?? "#236AA5"}
        speedMultiplier={0.75}
        loading
        size={size ?? 20}
      />
    </div>
  );
};

export default Loader;
