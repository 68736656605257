import { format } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { FaFilter } from "react-icons/fa";
import { useRecoilState, useRecoilValue } from "recoil";
import { FORMAT } from "../../../../../../common/constants/datetime.constant";
import {
  FILTER,
  ROLES,
  RolesType,
  STATUS,
} from "../../../../../../common/constants/general.constant";
import { formatDate } from "../../../../../../common/helpers/date.helper";
import { FilterHelper } from "../../../../../../common/helpers/filter.helper";
import {
  NameIdTemplate,
  objToStringList,
} from "../../../../../../common/helpers/general.helper";
import { showToast } from "../../../../../../common/helpers/toast.helper";
import { validateFDFilter } from "../../../../../../common/helpers/validation.helper";
import {
  FDFilterDTO,
  FDRowDTO,
} from "../../../../../../common/models/dashborad.model";
import { __FilterDTO } from "../../../../../../common/models/filter.modal";
import { ElementChangeEvent } from "../../../../../../common/types/ui.types";
import FlatButton from "../../../../../../components/elements/buttons/flat.button";
import Filter from "../../../../../../components/elements/Filter/filter.element";
import Calender from "../../../../../../components/ui/calender/calender.ui";
import SearchBar from "../../../../../../components/ui/searchBar/searchBar";
import { AtomAggregatorsObj } from "../../../../../../store/aggregator.store";
import { AtomFDFilter } from "../../../../../../store/dashboard.store";
import {
  AtomAllFDData,
  AtomFilteredFDData,
} from "../../../../../../store/financialDashboard.store";
import { AtomOperators } from "../../../../../../store/operator.store";
import styles from "./fdFilter.module.scss";

interface FDFilterProps {
  onSubmit: (filter: FDFilterDTO) => void;
  onSearch: (e: ElementChangeEvent) => void;
  search: string;
  setSearch: (val: string) => void;
}

const InitFilter = {
  [FILTER.currency]: { options: [], selected: [] },
  [FILTER.operator]: { showDefault: false },
  // [FILTER.isDefault]: { options: [], selected: [] },
};

const FDFilter = ({ onSubmit, onSearch, search, setSearch }: FDFilterProps) => {
  const [filter, setFilter] = useRecoilState(AtomFDFilter);

  const operators = useRecoilValue(AtomOperators);
  const aggregators = useRecoilValue(AtomAggregatorsObj);
  const allFDData = useRecoilValue(AtomAllFDData);
  // const fdOptions = useRecoilValue(AtomFDOptions);
  // const authUser = useRecoilValue(AtomAuthUser);
  const [fdFilteredData, setFilteredFDData] =
    useRecoilState(AtomFilteredFDData);
  const [filterShow, setFilterShow] = useState(false);
  const [filterData, setFilterData] = useState<__FilterDTO>(InitFilter);

  useEffect(() => {
    initializeOptions(allFDData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const onDateFilterChange = (e: ElementChangeEvent) => {
    let _filter = { ...filter };
    _filter = { ..._filter, [e.name]: e.data };
    setFilter(_filter);
  };

  const handleFilterChange = (e: ElementChangeEvent) => {
    let _filter = { ...filterData };
    _filter = { ..._filter, [e.name]: e.data };
    setFilterData(_filter);
  };

  const initializeOptions = useCallback(
    (allFDData: FDRowDTO[]) => {
      const _currency = objToStringList(allFDData, "currency");
      const _operator = objToStringList(allFDData, "operatorId");
      const _aggregator = objToStringList(allFDData, "aggregatorId");
      setFilterData({
        ...filterData,
        currency: { ...filterData.currency, options: _currency },
        operator: { ...filterData.operatorId, options: _operator },
        aggregator: { ...filterData.aggregatorId, options: _aggregator },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allFDData]
  );

  useEffect(() => {
    initializeOptions(allFDData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFDData]);

  const onFilterClick = () => {
    setSearch("");
    const _currencyFilter = filterData[FILTER.currency];
    const _operatorFilter = filterData[FILTER.operator];
    const _aggregatorFilter = filterData[FILTER.aggregator];
    // const _opCheck = filterData[FILTER.operator];
    let _filteredReport: FDRowDTO[] = [];
    for (const fdData of allFDData) {
      // const isDefault = fdData?.operatorId === fdData.aggregatorId;
      if (
        _currencyFilter &&
        _currencyFilter?.selected?.length &&
        !_currencyFilter?.selected?.includes(fdData.currency)
      )
        continue;
      if (
        _operatorFilter &&
        _operatorFilter?.selected?.length &&
        !_operatorFilter?.selected?.includes(fdData.aggregatorId)
      )
        continue;
      if (
        _aggregatorFilter &&
        _aggregatorFilter?.selected?.length &&
        !_aggregatorFilter?.selected?.includes(fdData.operatorId)
      )
        continue;

      // if (_opCheck.showDefault === true && !isDefault) continue;
      _filteredReport.push(fdData);
    }
    setFilteredFDData(_filteredReport);
  };
  const onClearClick = () => {
    initializeOptions(allFDData);
    setSearch("");
    setFilteredFDData(allFDData);
  };
  const onSubmitClick = () => {
    const error = validateFDFilter(filter);
    if (error) return showToast("error", error);
    let _filter = { ...filter };
    _filter.endDateStr = format(_filter.endDate, FORMAT.yyyyMMdd);
    _filter.startDateStr = format(_filter.startDate, FORMAT.yyyyMMdd);
    onSubmit(_filter);
    initializeOptions(allFDData);
  };

  // const onAgentSelect = ({ data, name }: ElementChangeEvent) => {
  //   const _allData = [...allFDData];
  //   const _filter = { ...filter, [name]: data };
  //   let _fdData: FDRowDTO[] = [];
  //   for (const fdData of _allData) {
  //     if (_filter.agents?.length && !_filter.agents.includes(fdData.operatorId))
  //       continue;
  //     if (
  //       _filter.operators?.length &&
  //       !_filter.operators.includes(fdData.aggregatorId)
  //     )
  //       continue;
  //     _fdData.push(fdData);
  //   }
  //   setFilteredFDData(_fdData);
  //   setFilter(_filter);
  // };

  // const aggregatorsObjTemplate = (option: string) => {
  //   const name = aggregators[option]
  //     ? aggregators[option].detail
  //     : option ?? "";
  //   return NameIdTemplate(name, option);
  // };

  // const operatorObjTemplate = (option: any, optKey?: string) => {
  //   const name = operators[option] ? operators[option].detail : option ?? "";
  //   return NameIdTemplate(name, option);
  // };

  // const onClear = () => {
  //   setFilteredFDData([...allFDData]);
  //   setFilter({ ...filter, agents: [], operators: [] });
  // };

  // * Filter for operators and agents by their Ids
  // const LocalFilter = () => {
  //   if (authUser?.role === ROLES.OPERATOR) return null;
  //   return (
  //     <div className={styles.firstPart}>
  //       <div className={styles.filterInputsWrapper}>
  //         {authUser?.role === ROLES.ADMIN && (
  //           <PzMultiSelect
  //             onChange={onAgentSelect}
  //             options={fdOptions.aggregators}
  //             selected={filter.operators ?? []}
  //             label="Aggregators"
  //             name="operators"
  // optionTemplate={aggregatorsObjTemplate}
  //           />
  //         )}
  //         <PzMultiSelect
  //           onChange={onAgentSelect}
  //           options={fdOptions.operators}
  //           selected={filter.agents ?? []}
  //           label="Operators"
  //           name="agents"
  //           optionTemplate={operatorObjTemplate}
  //         />
  //       </div>
  //       <div>
  //         <FlatButton label="Clear" onClick={onClear} />
  //       </div>
  //     </div>
  //   );
  // };

  const filterSearch = (e: ElementChangeEvent, type?: string) => {
    switch (type) {
      case FILTER.aggregator:
        const filterResult = FilterHelper.Aggregator(
          e.data,
          fdFilteredData,
          aggregators
        );
        const _aggregators = objToStringList(filterResult, "aggregatorId");

        setFilterData({
          ...filterData,
          aggregator: { ...filterData.aggregator, options: _aggregators },
        });
        break;
      case FILTER.operator:
        const _filterResult = FilterHelper.Operator(
          e.data,
          fdFilteredData,
          operators
        );
        const _operator = objToStringList(_filterResult, "operatorId");

        setFilterData({
          ...filterData,
          operator: { ...filterData.operator, options: _operator },
        });
        break;
      case FILTER.currency:
        const currencyRes = FilterHelper.Currency(e.data, fdFilteredData);
        const _currency = objToStringList(currencyRes, "currency");
        console.log("---_methodType", _currency);

        setFilterData({
          ...filterData,
          currency: { ...filterData.currency, options: _currency },
        });
        break;

      default:
        break;
    }
  };

  return (
    <div className={styles.filterWrapper}>
      <div className={styles.row}>
        <div className={styles.firstPart}>
          <div className={styles.filterInputsWrapper}>
            <p className={styles.label}> Start Date</p>
            <Calender
              onChange={onDateFilterChange}
              selectedDate={filter.startDate}
              name="startDate"
            />
          </div>
          <div className={styles.filterInputsWrapper}>
            <p className={styles.label}> End Date</p>
            <Calender
              onChange={onDateFilterChange}
              selectedDate={filter.endDate}
              name="endDate"
            />
          </div>
          <div className={styles.filterButtons}>
            <FlatButton onClick={onSubmitClick} label="Submit" />
            <div>
              {fdFilteredData.length !== 0 && (
                <FlatButton className={styles.exportButton}>
                  <CSVLink
                    data={fdFilteredData}
                    filename={`SummaryLog_${formatDate(
                      filter.startDate
                    )}_${formatDate(filter.endDate)}`}
                  >
                    <span className={styles.exportButton}>Export</span>
                  </CSVLink>
                </FlatButton>
              )}
            </div>
          </div>
        </div>
        <div className={styles.filterWpr}>
          <FlatButton
            className={styles.filterIcon}
            onClick={() => setFilterShow(!filterShow)}
          >
            <FaFilter size={14} />
          </FlatButton>
          <SearchBar
            onChange={onSearch}
            value={search}
            className={styles.search}
          />
        </div>
      </div>

      <div>
        <Filter
          show={filterShow}
          filterData={filterData}
          onFilterCHange={handleFilterChange}
          onFilterClick={onFilterClick}
          onClearClick={onClearClick}
          onFilter={filterSearch}
        />
      </div>
    </div>
  );
};

export default FDFilter;
