import styles from "./multiselect.module.scss";

import { MultiSelect } from "primereact/multiselect";
import { ElementChangeEvent } from "../../../common/types/ui.types";
import { ReactNode, useRef, useState } from "react";
import { HiHand } from "react-icons/hi";

interface PzMultiSelectProps {
  onChange: (e: ElementChangeEvent) => void;
  options: any[];
  optionKey?: string;
  selected: any[];
  label?: string;
  optionObjKey?: string;
  name: string;
  optionTemplate?: (op: any) => ReactNode;
  className?: string;
  searchBy?: any;

  onFilter?: (e: ElementChangeEvent) => void;
}

const PzMultiSelect = ({ options, selected, ...props }: PzMultiSelectProps) => {
  const handleChange = (e: any) => {
    const { value } = e.target;
    props.onChange && props.onChange({ data: value, name: props.name });
  };

  return (
    <div className={styles.body}>
      <div className={styles.multiSelectWrapper}>
        <MultiSelect
          optionLabel={props.optionKey}
          value={selected}
          maxSelectedLabels={0}
          className={styles.pzMultiSelect}
          options={options}
          resetFilterOnHide
          filterMatchMode="contains"
          emptyFilterMessage={<div className="noData"> No Data available</div>}
          name={props.name}
          onChange={(e) => handleChange(e)}
          itemTemplate={props.optionTemplate}
          filter
        />

        <div className={styles.label}>{props.label}</div>
      </div>
    </div>
  );
};

export default PzMultiSelect;
