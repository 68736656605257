import { format } from "date-fns";
import { useEffect, useState } from "react";
import { FiMenu } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { FORMAT } from "../../../../common/constants/datetime.constant";
import { FDFilterDTO } from "../../../../common/models/dashborad.model";
import { REPORT_TAB } from "../../../../common/routes/default.routes";
import { FinancialDashboardService } from "../../../../common/services/financialDashboard.service";
import FlatButton from "../../../../components/elements/buttons/flat.button";
import { AtomAuthUser, AtomShowSidebar } from "../../../../store/auth.store";
import { AtomFDFilter } from "../../../../store/dashboard.store";
import {
  AtomAllFDData,
  AtomFDOptions,
  AtomFilteredFDData,
} from "../../../../store/financialDashboard.store";
import FDFilter from "./components/FDFilter/fdFilter.component";
import FDTable from "./components/FDTable/fdTable.component";
import styles from "../_reports.module.scss";
import { ElementChangeEvent } from "../../../../common/types/ui.types";
import { AtomAggregatorsObj } from "../../../../store/aggregator.store";
import { AtomOperators } from "../../../../store/operator.store";
import { toast } from "react-toastify";
import { formatDateString } from "../../../../common/helpers/date.helper";

const FinancialDashboard = () => {
  const [isOpen, setIsOpen] = useRecoilState(AtomShowSidebar);
  const [fdData, setFDData] = useRecoilState(AtomAllFDData);
  const aggregators = useRecoilValue(AtomAggregatorsObj);
  const operators = useRecoilValue(AtomOperators);
  const setFilteredFDData = useSetRecoilState(AtomFilteredFDData);
  const { getFDSummary } = FinancialDashboardService();
  const filterFD = useRecoilValue(AtomFDFilter);
  const navigate = useNavigate();
  const setFDOptions = useSetRecoilState(AtomFDOptions);
  const [loader, setLoader] = useState(false);
  const handleRoute = (route: string) => {
    navigate(route);
  };
  const [search, setSearch] = useState("");
  const authUser = useRecoilValue(AtomAuthUser);

  const path = useLocation().pathname;

  const handleSubmit = (filter: FDFilterDTO) => {
    let timeDiff = Math.abs(
      filter.startDate.getTime() - filter.endDate.getTime()
    );
    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

    if (diffDays > 30) {
      toast.error("Start Date Should Not Be More Than 30 Days", {
        bodyClassName: "text",
        className: "text",
        position: "bottom-right",
      });
      return;
    }

    if (filter.startDate > filter.endDate) {
      toast.error("End date should not be greater than start date", {
        bodyClassName: "text",
        className: "text",
        position: "bottom-right",
      });
      return;
    }
    const startDate = format(filter.startDate, FORMAT.yyyyMMdd);
    const endDate = format(filter.endDate, FORMAT.yyyyMMdd);

    getFDSummary({
      endDate,
      startDate,
      setFDData,
      setFilteredFDData,
      navigate,
      setFDOptions,
      setLoader,
    });
  };

  useEffect(() => {
    if (fdData.length === 0) handleSubmit(filterFD);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = (e: ElementChangeEvent) => {
    let val = e.data.toLowerCase();
    setSearch(e.data);

    const _filterData = [...fdData].filter((c) => {
      let aggregator = aggregators[c.aggregatorId]?.detail;
      let operator = operators[c.operatorId]?.detail;
      return (
        aggregator?.toLowerCase().startsWith(val) ||
        operator?.toLowerCase().startsWith(val) ||
        c.aggregatorId?.toLowerCase().startsWith(val) ||
        c.operatorId?.toLowerCase().startsWith(val) ||
        c.currency?.toLowerCase().startsWith(val) ||
        c.operatorId?.toLowerCase().includes(val) ||
        aggregator?.toLowerCase().includes(val) ||
        operator?.toLowerCase().includes(val) ||
        c.currency?.toLowerCase().includes(val) ||
        formatDateString(c.localDate)?.toLowerCase().includes(val) ||
        formatDateString(c.localDate).toLowerCase().startsWith(val)
      );
    });
    setFilteredFDData(_filterData);
  };

  return (
    <div>
      <header className={styles.headerWrapper1}>
        <div className={styles.menuIcon}>
          <FiMenu
            // className={styles.burger}
            onClick={() => setIsOpen(!isOpen)}
            size={40}
            className="burgerMenuIcon"
          />
        </div>
        {REPORT_TAB.map((tab, key) => {
          const isActive = path.startsWith(tab.href);
          if (tab.roles?.includes(authUser?.role ?? ""))
            return (
              <FlatButton
                key={key}
                label={tab.label}
                className={`${styles.iconSet} ${isActive && styles.activeLink}`}
                onClick={() => handleRoute(tab.href)}
              />
              // </span>
            );
          return null;
        })}
      </header>

      <div>
        <FDFilter
          onSearch={onSearch}
          search={search}
          setSearch={setSearch}
          onSubmit={handleSubmit}
        />
        <FDTable loader={loader} />
      </div>
    </div>
  );
};

export default FinancialDashboard;
