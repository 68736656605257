import styles from "./calender.module.scss";
import DatePicker from "react-datepicker";
import { ElementChangeEvent } from "../../../common/types/ui.types";

interface CalenderProps {
  selectedDate: Date;
  onChange: (e: ElementChangeEvent) => void;
  name?: string;
  label?: string;
  className?: string;
}

const Calender = (props: CalenderProps) => {
  return (
    <div className={styles.calenderWrapper}>
      <label className={styles.label}>{props.label}</label>
      <DatePicker
        selected={props.selectedDate}
        className={styles.dateWrp}
        onChange={(date) =>
          props.onChange({ data: date, name: props.name ?? "" })
        }
        dateFormat="dd-MM-yyyy"
        maxDate={new Date()}
      />
    </div>
  );
};

export default Calender;
