import { memo, ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useRecoilValue } from "recoil";
import { API, API_MOD } from "../../../common/config/api.config";
import { PoolType } from "../../../common/constants/columns.constant";
import { checkError } from "../../../common/helpers/validation.helper";
import { AggregatorDTO } from "../../../common/models/aggregator.model";
import { AtomAuthUser } from "../../../store/auth.store";
import FlatButton from "../../elements/buttons/flat.button";
import styles from "../dialog/dialog.module.scss";

//* Dialog Prop Types
interface DialogProps {
  headerTemplate?: any;
  title?: string;
  children?: ReactNode;
  hideCloseBtn?: boolean;
  show?: boolean;
  preventBackdropClose?: boolean;
  closeOnEsc?: boolean;
  setShow?: (b: boolean) => void;
  glassEffect?: boolean;
  className?: string;
  onClose?: () => void;
  selectedRow: AggregatorDTO | undefined;
  updatePool: (val: any) => void;
}

//* Dialog
const PoolsDialog = memo(
  ({ setShow, show, closeOnEsc, selectedRow, ...props }: DialogProps) => {
    const [permissions, setPermissions] = useState<string[]>([]);
    const authToken = useRecoilValue<any>(AtomAuthUser);
    const navigate = useNavigate();

    useEffect(() => {
      if (selectedRow) {
        let _perms: string[] = [];
        if (selectedRow?.isFixedEnable) {
          _perms.push("FIXED");
        } 
         if (selectedRow?.isToteEnable) {
          _perms.push("TOTE");
        }
        setPermissions(_perms);
      }

      return () => {};
    }, [selectedRow]);

    const handleClick = (poolType: string) => {
      if (selectedRow) {
        let _permissions = [...permissions];
        const i = permissions.findIndex((x) => x === poolType);
        if (i === -1) {
          _permissions.push(poolType);
        } else {
          _permissions.splice(i, 1);
        }
        setPermissions(_permissions);
      }
    };

    // * Effect to handle keyUp event listener.
    useEffect(() => {
      // * Handle Key Up Event change to hide dialog on event click
      const handleKeyUp = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
          setShow && setShow(false);
          props.onClose && props.onClose();
        }
      };

      if (closeOnEsc && show) window.addEventListener("keyup", handleKeyUp);
      else if (!show) window.removeEventListener("keyup", handleKeyUp);
      // * Destroy the listener. CallBack should be same to be destroyed
      return () => window.removeEventListener("keyup", handleKeyUp);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [closeOnEsc, setShow, show]);

    /* handle Close Panel */

    const handleClose = () => {
      setShow && setShow(false);
    };

    const onSubmit = () => {
      let _isFo: boolean = false;
      let _isTote: boolean = false;

      for (const pool of permissions) {
        if (pool === "FIXED") {
          _isFo = true;
        } else if (pool === "TOTE") {
          _isTote = true;
        }
      }

      API.post(
        `${API_MOD.AGGREGATOR}/pools`,
        {
          aggregatorId: selectedRow?.aggregatorId,
          isFixedEnable: _isFo,
          isToteEnable: _isTote,
        },
        { headers: { Authorization: authToken?.token ?? "" } }
      )
        .then((response) => {
          console.log(response);
          let _sele = { ...selectedRow };
          _sele.isFixedEnable = _isFo;
          _sele.isToteEnable = _isTote;
          props.updatePool && props.updatePool(_sele);
        })
        .catch((error) => {
          checkError(error, true, navigate);
        });
    };

    if (show)
      return (
        <div className={styles.dialogWrapper}>
          <div
            className={`${styles.backdrop} ${
              props.glassEffect ? styles.blurBg : ""
            }`}
            onClick={() =>
              !props.preventBackdropClose && setShow && setShow(false)
            }
          ></div>
          <div className={styles.dialogCard}>
            <div className={styles.dialogHeader}>
              {props.headerTemplate ? props.headerTemplate() : props.title}
            </div>

            <div className={`${styles.dialogBody}`}>
              <div className={styles.poolsDialog}>
                Please select odds to give access.{" "}
              </div>

              <div className={styles.oddsButton}>
                <div className={styles.multiselectButtons}>
                  {PoolType.map((pool, key) => {
                    return (
                      <button
                        className={`${styles.multiSelectButton} ${
                          permissions.includes(pool.value) ? styles.active : ""
                        }`}
                        onClick={() => handleClick(pool.value)}
                        key={key}
                      >
                        <div className={styles.buttonContent}>
                          <span>
                            <input
                              type="checkbox"
                              checked={permissions.includes(pool.value)}
                              readOnly
                            />
                          </span>
                          <span>{pool.label}</span>
                        </div>
                      </button>
                    );
                  })}
                </div>

                <div className={styles.submitButtons}>
                  <FlatButton onClick={onSubmit}>Submit</FlatButton>

                  <FlatButton severity="warning" onClick={props.onClose}>
                    Cancel
                  </FlatButton>
                </div>
              </div>
            </div>
            {!props.hideCloseBtn && (
              <button
                onClick={props.onClose ? props.onClose : handleClose}
                className={styles.closeBtn}
              >
                &times;
              </button>
            )}
          </div>
        </div>
      );
    return null;
  }
);

//* Display name for memoization.
PoolsDialog.displayName = "PoolsDialog";
export default PoolsDialog;
