import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { ROLES } from "../../common/constants/general.constant";
import { DashboardService } from "../../common/services/dashboard.service";
import { AtomOperatorsBalanceReport } from "../../store/operator.store";
import { AtomAuthUser } from "../../store/auth.store";
import { AtomAdminBalanceReport } from "../../store/dashboard.store";
import {
  AtomAggregatorBalanceReport,
  AtomDashboardData,
} from "../../store/aggregator.store";
import AdminTable from "./components/adminTable/adminTable.component";
import OperatorTable from "./components/operatorTable/operatorTable.component";
import AggregatorTable from "./components/aggregatorTable/aggregatorTable.component";

const DashboardPage = () => {
  const navigate = useNavigate();
  const { getBalanceReport } = DashboardService();
  const setOperatorBalanceReport = useSetRecoilState(
    AtomOperatorsBalanceReport
  ); //Agent Report get
  const [loader, setLoader] = useState(false);
  const setAggregatorBalanceReport = useSetRecoilState(
    AtomAggregatorBalanceReport
  );
  const setAllDashboardData = useSetRecoilState(AtomDashboardData);

  const setAdminBalanceReport = useSetRecoilState(AtomAdminBalanceReport);

  const authUser = useRecoilValue(AtomAuthUser);

  const fetchBalanceReport = useCallback(() => {
    getBalanceReport({
      province: "AU",
      setOperatorBalanceReport,
      setAggregatorBalanceReport,
      setAdminBalanceReport,
      setLoader,
      navigate,
      setAllDashboardData,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // * Initially fetch data if data is undefined.
  useEffect(() => {
    fetchBalanceReport();
    return () => {
      setOperatorBalanceReport([]);
      setAggregatorBalanceReport([]);
      setAdminBalanceReport([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {authUser?.role === ROLES.ADMIN && (
        <AdminTable
          loader={loader}
          fetchAgentBalanceReport={fetchBalanceReport}
        />
      )}
      {authUser?.role !== ROLES.OPERATOR && authUser?.role !== ROLES.ADMIN ? (
        <>
          <AggregatorTable
            loader={loader}
            fetchBalanceReport={fetchBalanceReport}
          />
          <OperatorTable
            loader={loader}
            fetchAgentBalanceReport={fetchBalanceReport}
          />
        </>
      ) : (
        authUser?.role !== ROLES.AGGREGATOR &&
        authUser?.role !== ROLES.ADMIN && (
          <OperatorTable
            loader={loader}
            fetchAgentBalanceReport={fetchBalanceReport}
          />
        )
      )}
    </>
  );
};

export default DashboardPage;
