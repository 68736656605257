import { format } from "date-fns";
import { atom } from "recoil";
import { FORMAT } from "../common/constants/datetime.constant";
import {
  FDFilterDTO,
  ReportBalanceDTO,
} from "../common/models/dashborad.model";
import { TransactionDTO } from "../common/models/report.model";

// Fetch all provinces provided by API.
export const AtomProvinces = atom<string[] | undefined>({
  key: "atomProvinces",
  default: undefined,
});

// export const AtomSelectedProvince = atom<ProvinceDTO | null>({
//   key: "atomSelectedProvince",
//   default: null,
// });

// TODO: change types from transaction to settlementReport
export const AtomSettlementReport = atom<TransactionDTO[] | undefined>({
  key: "atomSettlementReport",
  default: undefined,
});

export const AtomFDFilter = atom<FDFilterDTO>({
  key: "atomFDFilter",
  default: {
    startDate: new Date(),
    endDate: new Date(),
    startDateStr: format(new Date(), FORMAT.yyyyMMdd),
    endDateStr: format(new Date(), FORMAT.yyyyMMdd),
    operators: [],
    agents: [],
  },
});

export const AtomAdminBalanceReport = atom<ReportBalanceDTO[]>({
  key: "atomAdminBalanceReport",
  default: [],
});
