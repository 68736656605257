import { AggregatorDTO } from "../common/models/aggregator.model";
import { atom } from "recoil";
import { __AggregatorDTO } from "../common/types/dynamic.types";
import { ReportBalanceDTO } from "../common/models/dashborad.model";

export const AtomAggregatorEntries = atom<AggregatorDTO>({
  key: "atomAggregatorEntries",
  default: {} as AggregatorDTO,
});

export const AtomAggregatorsObj = atom<__AggregatorDTO>({
  key: "AtomAggregatorObj",
  default: {},
});

export const AtomAggregatorIds = atom<string[]>({
  key: "aggregatorIdList",
  default: [],
});

export const AtomAggregatorBalanceReport = atom<ReportBalanceDTO[]>({
  key: "atomAggregatorBalanceReport",
  default: [],
});

export const AtomDashboardData = atom<ReportBalanceDTO[]>({
  key: "atomDashboardData",
  default: [],
});
