import { ReactNode, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { useRecoilValue } from "recoil";
import { ROUTES } from "../common/routes/default.routes";
import { AtomAuthUser } from "../store/auth.store";

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const authUser = useRecoilValue(AtomAuthUser);
  const route = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (authUser === null && !route.pathname.startsWith("/auth")) {
      navigate(ROUTES.SIGN_IN);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  return <>{children}</>;
};

export default AuthProvider;
