import { FaHome, FaUser } from "react-icons/fa";
import { AiFillDashboard } from "react-icons/ai";
import { MdOutlineSupervisedUserCircle } from "react-icons/md";
import { HiUsers } from "react-icons/hi";
import { IoDocumentText } from "react-icons/io5";
import { ROLES } from "../constants/general.constant";
// * Routes constants to reuse.
export const ROUTES = {
  HOME: "/",
  DASHBOARD: "/dashboard",
  DASH_PROVINCE: "/dashboard/:province",
  USER_MANAGE: "/user-management",
  AGGREGATOR_MANAGE: "/aggregator-management",
  OPERATOR_MANAGE: "/operator-management",
  WALLET_MANAGE: "/wallet-management",
  SUMMARY_LOG: "/report-management/summary-log",
  SIGN_IN: "/auth/sign-in",
  REPORT_MANAGE: "/report-management",
  TRANSACTION_MANAGE: "/report-management/transaction-log",
  WAGER_LOG: "/report-management/wager-log",
  SETTLEMENT_LOG: "/report-management/settlement-log",
  AUDIT_LOG: "/report-management/audit-log",
  DOCS: "/documentation",
  LOGOUT: "/",
};

export const PERMISSION: any = {
  AGENT: [],
  OPERATOR: [ROLES.OPERATOR],
  ADMIN: [ROLES.ADMIN, ROLES.AGGREGATOR, ROLES.OPERATOR, "USER"],
};

// * Menu for navigation.
export const MENU = [
  {
    href: ROUTES.DASHBOARD,
    label: "Dashboard",
    icon: AiFillDashboard,
    roles: [ROLES.ADMIN, ROLES.AGGREGATOR, ROLES.OPERATOR],
  },
  {
    href: ROUTES.USER_MANAGE,
    label: "User management",
    icon: FaUser,
    roles: [ROLES.ADMIN, ROLES.AGGREGATOR],
  },
  {
    href: ROUTES.AGGREGATOR_MANAGE,
    label: "Aggregator management",
    icon: HiUsers,
    roles: [ROLES.ADMIN],
  },
  {
    href: ROUTES.OPERATOR_MANAGE,
    label: "Operator management",
    icon: MdOutlineSupervisedUserCircle,
    roles: [ROLES.ADMIN, ROLES.AGGREGATOR],
  },
  // {
  //   href: ROUTES.FINANCE_DASHBOARD,
  //   label: "financial dashboard",
  //   icon: GiWallet,
  //   roles: Object.values(ROLES),
  // },

  {
    href: ROUTES.REPORT_MANAGE,
    label: "Report Management",
    icon: IoDocumentText,
    roles: Object.values(ROLES),
  },
  // {
  //   href: ROUTES.AUDIT_REPORT,
  //   label: "Audit Report",
  //   icon: IoDocumentText,
  //   roles: [ROLES.ADMIN],
  // },
  // {
  //   href: "https://docs.google.com/document/d/1dcv_urQeFxZDJ17FHBw7DPYRS3wcjPvT/edit?usp=sharing&ouid=108567882538534317568&rtpof=true&sd=true",
  //   label: "API Documentation",
  //   icon: IoDocumentText,
  //   roles: [ROLES.ADMIN, ROLES.AGGREGATOR, ROLES.OPERATOR],
  //   target: "_blank",
  // },
];

export const REPORT_TAB = [
  {
    href: ROUTES.SUMMARY_LOG,
    label: "Summary Log",
    icon: FaHome,
    roles: Object.values(ROLES),
  },
  {
    href: ROUTES.TRANSACTION_MANAGE,
    label: "Transaction Log",
    icon: FaHome,
    roles: Object.values(ROLES),
  },
  {
    href: ROUTES.WAGER_LOG,
    label: "Wager Log",
    icon: FaHome,
    roles: Object.values(ROLES),
  },
  {
    href: ROUTES.SETTLEMENT_LOG,
    label: "Settlement Log",
    icon: FaHome,
    roles: Object.values(ROLES),
  },
  {
    href: ROUTES.AUDIT_LOG,
    label: "Audit Log",
    icon: IoDocumentText,
    roles: [ROLES.ADMIN],
  },
];
