import { SettlementLogDTO, WagerDTO } from "../common/models/report.model";
import { atom } from "recoil";
import { TransactionDTO } from "../common/models/report.model";
import { AuditDTO } from "../common/models/audit.model";

// * Stores the all agents data of the operator.
export const AtomTransaction = atom<TransactionDTO[]>({
  key: "atomTransactions",
  default: [],
});

export const AtomTransactionDate = atom({
  key: "dateTransaction",
  default: {
    startDate: new Date(),
    endDate: new Date(),
  },
});

export const AtomWager = atom<WagerDTO[]>({
  key: "atomWager",
  default: [],
});

export const AtomWagerDate = atom<any>({
  key: "dateWager",
  default: {
    startDate: new Date(),
    endDate: new Date(),
  },
});

export const AtomSettlementLog = atom<SettlementLogDTO[]>({
  key: "atomSettlementLog",
  default: [],
});

export const AtomSettlementLogDate = atom<any>({
  key: "dateSettlementLog",
  default: {
    startDate: new Date(),
    endDate: new Date(),
  },
});

export const AtomAuditReport = atom<AuditDTO[]>({
  key: "atomAUditReport",
  default: [],
});

export const AtomAuditReportDate = atom<any>({
  key: "dateAUditReport",
  default: {
    startDate: new Date(),
    endDate: new Date(),
  },
});
