import { ReportBalanceDTO } from "../common/models/dashborad.model";
import { atom } from "recoil";
import { __OperatorDTO } from "../common/types/dynamic.types";

// * Stores the all agents data of the operator.
export const AtomOperators = atom<__OperatorDTO>({
  key: "atomOperators",
  default: {},
});

// * Stores default agent Ids
export const AtomDefaultOperators = atom<string[]>({
  key: "atomDefaultOperators",
  default: [],
});

// * Store all agent balance report for the operator.
export const AtomOperatorsBalanceReport = atom<ReportBalanceDTO[]>({
  key: "atomOperatorsBalanceReport",
  default: [],
});
