import { memo, ReactNode, useCallback, useEffect, useState } from "react";
import { FaFilter } from "react-icons/fa";
import { FiMenu } from "react-icons/fi";
import { GiWallet } from "react-icons/gi";
import { useNavigate } from "react-router";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { FILTER, ROLES, STATUS } from "../common/constants/general.constant";
import { FilterHelper } from "../common/helpers/filter.helper";
import { objToStringList } from "../common/helpers/general.helper";
import { ReportBalanceDTO } from "../common/models/dashborad.model";
import { __FilterDTO } from "../common/models/filter.modal";
import { DashboardService } from "../common/services/dashboard.service";
import { ElementChangeEvent } from "../common/types/ui.types";
import FlatButton from "../components/elements/buttons/flat.button";
import Filter from "../components/elements/Filter/filter.element";
import SearchBar from "../components/ui/searchBar/searchBar";
import AddWalletDialog from "../pages/dashboard/components/addWalletDialog/addWalletDialog.component";
import {
  AtomAggregatorBalanceReport,
  AtomAggregatorsObj,
  AtomDashboardData,
} from "../store/aggregator.store";
import { AtomAuthUser, AtomShowSidebar } from "../store/auth.store";
import { AtomAdminBalanceReport } from "../store/dashboard.store";
import {
  AtomOperators,
  AtomOperatorsBalanceReport,
} from "../store/operator.store";
import styles from "./layouts.module.scss";

interface DashboardLayoutProps {
  children: ReactNode;
}

const InitFilter = {
  [FILTER.currency]: { options: [], selected: [] },
  // [FILTER.environment]: { options: [], selected: [] },
  [FILTER.aggregator]: { options: [], selected: [] },
  [FILTER.operator]: { options: [], selected: [] },
};

const DashboardLayout = memo(({ children }: DashboardLayoutProps) => {
  const [isOpen, setIsOpen] = useRecoilState(AtomShowSidebar);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const { getBalanceReport } = DashboardService();
  const [dashboardData, setAllDashboardData] =
    useRecoilState(AtomDashboardData);
  const setAggregatorBalanceReport = useSetRecoilState(
    AtomAggregatorBalanceReport
  );
  const operators = useRecoilValue(AtomOperators);
  const aggregators = useRecoilValue(AtomAggregatorsObj);
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [filterShow, setFilterShow] = useState(false);
  const [filterData, setFilterData] = useState<__FilterDTO>(InitFilter);
  const [, setAdminBalanceReport] = useRecoilState(AtomAdminBalanceReport);
  const [, setOperatorBalanceReport] = useRecoilState(
    AtomOperatorsBalanceReport
  );

  const initializeOptions = useCallback((data: ReportBalanceDTO[]) => {
    if (!data.length) return;
    const _currency = objToStringList(data, "currency");
    const _environment = [STATUS.LIVE, STATUS.SIM];
    const _aggregatorID = objToStringList(data, "aggregatorId");
    const _operatorID = objToStringList(data, "operatorId");

    setFilterData({
      ...filterData,
      currency: { ...filterData.currency, options: _currency },
      // environment: { ...filterData.environment, options: _environment },
      aggregator: { ...filterData.user, options: _aggregatorID },
      operator: { ...filterData.user, options: _operatorID },
    });
  }, []);

  useEffect(() => {
    initializeOptions(dashboardData);
    onChange({ data: search, name: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardData]);

  const fetchBalanceReport = useCallback(() => {
    getBalanceReport({
      province: "AU",
      setOperatorBalanceReport,
      setAggregatorBalanceReport,
      setAdminBalanceReport,
      setLoader,
      navigate,
      setAllDashboardData,
    });
  }, []);

  const handleMenuOpen = () => {
    setIsOpen(!isOpen);
  };
  const authUser = useRecoilValue(AtomAuthUser);

  const onChange = (e: ElementChangeEvent) => {
    initializeOptions(dashboardData);
    setSearch(e.data);
    if (authUser?.role === ROLES.ADMIN) filter(e.data, setAdminBalanceReport);
    else if (authUser?.role === ROLES.AGGREGATOR) {
      filter(e.data, setOperatorBalanceReport);
    }
  };

  const handleFilterChange = (e: ElementChangeEvent) => {
    let _filter = { ...filterData };
    _filter = { ..._filter, [e.name]: e.data };
    setFilterData(_filter);
  };

  const onClearClick = () => {
    setSearch("");
    initializeOptions(dashboardData);
    setAdminBalanceReport(dashboardData);
    setOperatorBalanceReport(dashboardData);
  };

  const onFilterClick = () => {
    const _currencyFilter = filterData[FILTER.currency];
    // const _envFilter = filterData[FILTER.environment];
    const _operatorFilter = filterData[FILTER.operator];
    const _aggregatorFilter = filterData[FILTER.aggregator];
    let _filteredReport: ReportBalanceDTO[] = [];
    for (const cur of dashboardData) {
      if (
        _currencyFilter &&
        _currencyFilter?.selected?.length &&
        !_currencyFilter?.selected?.includes(cur.currency)
      )
        continue;
      // if (
      //   _envFilter &&
      //   _envFilter?.selected?.length &&
      //   !_envFilter?.selected?.includes(
      //     cur.environment === true ? STATUS.LIVE : STATUS.SIM
      //   )
      // )
      //   continue;
      if (
        _operatorFilter &&
        _operatorFilter?.selected?.length &&
        !_operatorFilter?.selected?.includes(cur.operatorId)
      )
        continue;
      if (
        _aggregatorFilter &&
        _aggregatorFilter?.selected?.length &&
        !_aggregatorFilter?.selected?.includes(cur.aggregatorId)
      )
        continue;
      _filteredReport.push(cur);
    }
    if (authUser?.role === ROLES.ADMIN) {
      setAdminBalanceReport(_filteredReport);
    } else if (authUser?.role === ROLES.AGGREGATOR) {
      setOperatorBalanceReport(_filteredReport);
    }
    setSearch("");
  };

  const filter = (value: string, setState: any) => {
    const searchTerm = value.toLowerCase();
    if (!searchTerm || searchTerm === "") {
      if (authUser?.role === ROLES.AGGREGATOR) {
        let _noDefaults = dashboardData.filter((x) => !x.isDefault);
        setState(_noDefaults);
      } else {
        setState([...dashboardData]);
        // setAdminBalanceReport(dashboardData);
      }
      return;
    }
    let _filtered = dashboardData.filter((c) => {
      let operator = operators[c.operatorId]?.detail;
      let aggregator = aggregators[c.aggregatorId]?.detail;
      // return c.detail?.toLowerCase().startsWith(searchTerm);
      return (
        c.operatorId?.toLowerCase().startsWith(searchTerm) ||
        c.currency?.toLowerCase().startsWith(searchTerm) ||
        c.currency?.toLowerCase().includes(searchTerm) ||
        c.aggregatorId?.toLowerCase().startsWith(searchTerm) ||
        c.aggregatorId?.toLowerCase().includes(searchTerm) ||
        c.operatorId?.toLowerCase().includes(searchTerm) ||
        operator?.toLowerCase().includes(searchTerm) ||
        aggregator?.toLowerCase().includes(searchTerm) ||
        operator?.toLowerCase().startsWith(searchTerm) ||
        aggregator?.toLowerCase().startsWith(searchTerm)
      );
    });
    if (authUser?.role === ROLES.AGGREGATOR) {
      _filtered = _filtered.filter((x) => !x.isDefault);
    }

    setState(_filtered);
  };

  const filterSearch = (e: ElementChangeEvent, type?: string) => {
    switch (type) {
      case FILTER.aggregator:
        const filterResult = FilterHelper.Aggregator(
          e.data,
          dashboardData,
          aggregators
        );
        const _aggregators = objToStringList(filterResult, "aggregatorId");

        setFilterData({
          ...filterData,
          aggregator: { ...filterData.aggregator, options: _aggregators },
        });
        break;
      case FILTER.operator:
        const _filterResult = FilterHelper.Operator(
          e.data,
          dashboardData,
          operators
        );
        const _operator = objToStringList(_filterResult, "operatorId");

        setFilterData({
          ...filterData,
          operator: { ...filterData.operator, options: _operator },
        });
        break;
      case FILTER.methodType:
        const methodRes = FilterHelper.MethodType(e.data, dashboardData);
        const _methodType = objToStringList(methodRes, "methodType");
        console.log("---_methodType", _methodType);

        setFilterData({
          ...filterData,
          methodType: { ...filterData.methodType, options: _methodType },
        });
        break;

      default:
        break;
    }
    // let val = e.data?.toLowerCase();

    // let filterResult = [...dashboardData].filter((i) => {
    //   let operator = operators[i.operatorId]?.detail;
    //   let aggregator = aggregators[i.aggregatorId]?.detail;
    //   // console.log(i.operatorDetail);

    //   return (
    //     i.aggregatorId?.toLowerCase().startsWith(val) ||
    //     operator?.toLowerCase().startsWith(val) ||
    //     i.operatorId?.toLowerCase().startsWith(val) ||
    //     aggregator?.toLowerCase().startsWith(val) ||
    //     i.aggregatorId?.toLowerCase().includes(val) ||
    //     i.operatorId?.toLowerCase().includes(val) ||
    //     aggregator?.toLowerCase().includes(val) ||
    //     i.currency?.toLowerCase().startsWith(val) ||
    //     i.currency?.toLowerCase().includes(val) ||
    //     operator?.toLowerCase().includes(val)
    //   );
    // });

    // const _currency = objToStringList(filterResult, "currency");
    // const _environment = [STATUS.LIVE, STATUS.SIM];
    // const _aggregatorID = objToStringList(filterResult, "aggregatorId");
    // const _operatorID = objToStringList(filterResult, "operatorId");

    // // console.log(_currency);
    // // console.log(filterData,"<-----filterData");
    // // console.log(val);

    // setFilterData({
    //   ...filterData,
    //   environment: { ...filterData.environment, options: _environment },
    //   currency: { ...filterData.currency, options: _currency },
    //   aggregator: { ...filterData.user, options: _aggregatorID },
    //   operator: { ...filterData.user, options: _operatorID },
    // });
  };

  return (
    <div>
      <AddWalletDialog
        onSave={fetchBalanceReport}
        setShow={setShow}
        show={show}
        loader={loader}
        setLoader={setLoader}
      />
      <header className={"headerWrapper"}>
        <span className={styles.iconNameWrp}>
          <FiMenu
            onClick={handleMenuOpen}
            size={40}
            className="burgerMenuIcon"
          />
          <span className={styles.nameWrp}>Dashboard</span>
        </span>
        <div className="flex">
          <FlatButton
            className={styles.filterIcon}
            onClick={() => setFilterShow(!filterShow)}
          >
            <FaFilter size={20} />
          </FlatButton>
          <SearchBar onChange={onChange} value={search} />
          {authUser?.role !== ROLES.OPERATOR && (
            <div className="mx-1">
              <FlatButton
                label="Add Wallet"
                severity="light"
                className="addBtn"
                icon={<GiWallet size={20} />}
                onClick={() => setShow(true)}
              />
            </div>
          )}
        </div>
      </header>
      <Filter
        show={filterShow}
        filterData={filterData}
        onFilterCHange={handleFilterChange}
        onFilterClick={onFilterClick}
        onClearClick={onClearClick}
        onFilter={filterSearch}
      />
      {children}
    </div>
  );
});

DashboardLayout.displayName = "DashboardLayout";
export default DashboardLayout;
