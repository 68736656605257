export const FilterHelper = {
  Aggregator: (term: string, list: any[], aggregators: any) => {
    return list.filter((i) => {
      const val = term?.toLowerCase() ?? "";
      let aggregator = aggregators[i.aggregatorId]?.detail;

      return (
        i.aggregatorId?.toLowerCase().startsWith(val) ||
        aggregator?.toLowerCase().startsWith(val) ||
        i.aggregatorId?.toLowerCase().includes(val) ||
        aggregator?.toLowerCase().includes(val)
      );
    });
  },
  Operator: (term: string, list: any[], operators: any) => {
    return list.filter((i) => {
      const val = term?.toLowerCase() ?? "";
      let operator = operators[i.operatorId]?.detail;

      return (
        i.operatorId?.toLowerCase().startsWith(val) ||
        operator?.toLowerCase().startsWith(val) ||
        i.operatorId?.toLowerCase().includes(val) ||
        operator?.toLowerCase().includes(val)
      );
    });
  },
  MethodType: (term: string, list: any[]) => {
    return list.filter((i) => {
      const val = term?.toLowerCase() ?? "";
      return (
        i?.methodType?.toLowerCase()?.startsWith(val) ||
        i?.methodType?.toLowerCase()?.includes(val)
      );
    });
  },
  SettlementType: (term: string, list: any[]) => {
    return list.filter((i) => {
      const val = term?.toLowerCase() ?? "";
      return (
        i?.settlementType?.toLowerCase()?.startsWith(val) ||
        i?.settlementType?.toLowerCase()?.includes(val)
      );
    });
  },
  SettleBy: (term: string, list: any[]) => {
    return list.filter((i) => {
      const val = term?.toLowerCase() ?? "";
      return (
        i?.settleBy?.toLowerCase()?.startsWith(val) ||
        i?.settleBy?.toLowerCase()?.includes(val)
      );
    });
  },
  Username: (term: string, list: any[]) => {
   
    return list.filter((i) => {
      const val = term?.toLowerCase() ?? "";
      return (
        i?.username?.toLowerCase()?.startsWith(val) ||
        i?.username?.toLowerCase()?.includes(val)
      );
    });
  },
  Role: (term: string, list: any[]) => {
    return list.filter((i) => {
      const val = term?.toLowerCase() ?? "";
      return (
        i?.role?.toLowerCase()?.startsWith(val) ||
        i?.role?.toLowerCase()?.includes(val)
      );
    });
  },
  Currency: (term: string, list: any[]) => {
    return list.filter((i) => {
      const val = term?.toLowerCase() ?? "";
      return (
        i?.currency?.toLowerCase()?.startsWith(val) ||
        i?.currency?.toLowerCase()?.includes(val)
      );
    });
  },
  PoolType: (term: string, list: any[]) => {
    return list.filter((i) => {
      const val = term?.toLowerCase() ?? "";
      return (
        i?.poolType?.toLowerCase()?.startsWith(val) ||
        i?.poolType?.toLowerCase()?.includes(val)
      );
    });
  },
  BetStatus: (term: string, list: any[]) => {
    return list.filter((i) => {
      const val = term?.toLowerCase() ?? "";
      return (
        i?.betStatus?.toLowerCase()?.startsWith(val) ||
        i?.betStatus?.toLowerCase()?.includes(val)
      );
    });
  },
  RaceKey: (term: string, list: any[]) => {
    return list.filter((i) => {
      const val = term?.toLowerCase() ?? "";
      return (
        i?.raceKey?.toLowerCase()?.startsWith(val) ||
        i?.raceKey?.toLowerCase()?.includes(val)
      );
    });
  },
  Connector: (term: string, list: any[]) => {
    return list.filter((i) => {
      const val = term?.toLowerCase() ?? "";
      return (
        i?.connectorId?.toLowerCase()?.startsWith(val) ||
        i?.connectorId?.toLowerCase()?.includes(val)
      );
    });
  },
};
