import { API, API_MOD } from "../config/api.config";
import { getHeader } from "../helpers/apis.helper";
import { ProvinceDTO, ReportBalanceDTO } from "../models/dashborad.model";
import { TransactionDTO } from "../models/report.model";
import { checkError } from "../helpers/validation.helper";

interface GetProvincesParams {
  setCurrencies?: (p: string[]) => void;
  setSelectedProvince: (p: ProvinceDTO) => void;
  navigate?: any;
}

interface GetSettlementReportParams {
  // todo: change type of report.
  setSettlementReport: (report: TransactionDTO[]) => void;
  endDate: string;
  startDate: string;
  navigate: any;
}

interface GetBalanceReportParams {
  province: string;
  setOperatorBalanceReport: (r: ReportBalanceDTO[]) => void;
  setAggregatorBalanceReport: (r: ReportBalanceDTO[]) => void;
  setAdminBalanceReport: (r: ReportBalanceDTO[]) => void;
  setAllDashboardData: (r: ReportBalanceDTO[]) => void;
  setLoader: (r: boolean) => void;

  navigate: any;
}

export const DashboardService = () => {
  // * get all province data.
  const getCurrencies = ({
    setCurrencies,
    setSelectedProvince,
    navigate,
  }: GetProvincesParams) => {
    API.get(`${API_MOD.AGGREGATOR}/currencies`, {
      headers: getHeader(),
    })
      .then((res) => {
        setCurrencies && setCurrencies(res.data ?? []);
      })
      .catch((err) => {
        console.log({ provinceErr: err });
        setCurrencies && setCurrencies([]);
        checkError(err, true, navigate);
      });
  };

  // * get settlement report.
  const getSettlementReport = ({
    setSettlementReport,
    startDate,
    endDate,
    navigate,
  }: GetSettlementReportParams) => {
    API.post(
      `${API_MOD.REPORT}/transactions`,
      { startDate, endDate },
      { headers: getHeader() }
    )
      .then((res) => {
        setSettlementReport && setSettlementReport(res.data ?? []);
      })
      .catch((err) => {
        console.error({ settleReportError: err });
        setSettlementReport && setSettlementReport([]);
        checkError(err, true, navigate);
      });
  };

  // * Get balance report for selected province
  const getBalanceReport = ({ ...params }: GetBalanceReportParams) => {
    params.setLoader(true);
    API.get(`${API_MOD.OPERATOR}/balance`, { headers: getHeader() })
      .then((res) => {
        const _data = (res.data as ReportBalanceDTO[]) ?? [];
        const _operators = _data.filter((x) => x.isDefault);
        const _agents = _data.filter((x) => !x.isDefault);
        params.setAllDashboardData(_data);
        params.setOperatorBalanceReport(_agents);
        params.setAggregatorBalanceReport(_operators);
        params.setAdminBalanceReport(_data);
        params.setLoader(false);
      })
      .catch((err) => {
        console.log({ err });
        params.setOperatorBalanceReport([]);
        params.setAggregatorBalanceReport([]);
        params.setAllDashboardData([]);
        params.setAdminBalanceReport([]);
        params.setLoader(false);

        checkError(err, true, params.navigate);
      });
  };

  return {
    getCurrencies,
    getSettlementReport,
    getBalanceReport,
  };
};
