import { memo, ReactNode } from "react";
import theme from "../styles/theme/themes.module.scss";
import layout from "../styles/theme/layout.module.scss";

//* Provider Prop Types
interface ThemeProviderProps {
  children: ReactNode;
}

//* Provider
const ThemeProvider = memo(({ children }: ThemeProviderProps) => {
  return (
    <div className={`${theme.blueWhite} ${layout.layout01}`}>
      <div className={layout.bodyWrapper}>
        <div className={layout.contentWrapper}>{children}</div>
      </div>
    </div>
  );
});

//* Display name for memoization.
ThemeProvider.displayName = "ThemeProvider";
export default ThemeProvider;
