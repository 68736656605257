import {
  DateTimeFormatter,
  LocalDate,
  LocalDateTime,
  ZoneId,
} from "@js-joda/core";
import { format } from "date-fns";
import { DATE_FORMAT, FORMAT } from "../constants/datetime.constant";

export const formatDate = (date: Date) => {
  const formattedDate = format(date, FORMAT.yyyyMMdd);
  return formattedDate;
};

export const formatDateString = (date: string | number, format?: string) => {
  if (!date || date === "-") return "-";
  const _formattedDate = LocalDate.parse(
    date?.toString(),
    DATE_FORMAT.yyyyMMdd
  ).format(DateTimeFormatter.ofPattern(format ?? FORMAT.ddMMyyyy_dash));
  return _formattedDate;
};

export const formatTimestamp = (timestamp: number, dateFormat?: string) => {
  if (!timestamp) return "";
  let t = new Date(timestamp);
  return format(t, dateFormat ?? "dd/MM/yyyy HH:mm:ss");
};

export const toLocalDateTime = (timestamp: string, dateFormat: string) => {};

export const timeStampToDateTime = (timestamp: string, _format?: string) => {
  try {
    if (!timestamp) return "";
    const _date = format(new Date(Number(timestamp)), FORMAT.yyyyMMddHHmmss);
    let parsedDate = LocalDateTime.parse(_date, DATE_FORMAT.yyyyMMddHHmmss);
    let formatted = parsedDate.format(
      DateTimeFormatter.ofPattern(_format ?? "dd-MM-yyyy HH:mm:ss")
    );
    return formatted;
  } catch (error) {
    console.log(error);
    return "";
  }
};

export const timestampToLocalDateTime = (
  timestamp: string,
  tz: string,
  _format: string = FORMAT.ddMMyyyyHHmmss_dash
) => {
  try {
    if (!timestamp || !tz) return "";
    const dateTime = format(new Date(Number(timestamp)), FORMAT.yyyyMMddHHmmss);
    let zoneId = ZoneId.of(tz);
    let pTime = LocalDateTime.parse(dateTime, DATE_FORMAT.yyyyMMddHHmmss)
      .atZone(zoneId)
      .withZoneSameInstant(ZoneId.systemDefault())
      .toLocalDateTime()
      .format(DateTimeFormatter.ofPattern(_format));
    console.log(pTime);
    return pTime;
  } catch (error) {
    console.log(error);
    return "";
  }
};
