import { memo, ReactNode, useEffect } from "react";
import styles from "../dialog/dialog.module.scss";

//* Dialog Prop Types
interface DialogProps {
  headerTemplate?: any;
  title?: string;
  children?: ReactNode;
  hideCloseBtn?: boolean;
  show?: boolean;
  preventBackdropClose?: boolean;
  closeOnEsc?: boolean;
  setShow?: (b: boolean) => void;
  glassEffect?: boolean;
  className?: string;
  onClose?: () => void;
}

//* Dialog
const Dialog = memo(({ setShow, show, closeOnEsc, ...props }: DialogProps) => {
  // * Effect to handle keyUp event listener.
  useEffect(() => {
    // * Handle Key Up Event change to hide dialog on event click
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setShow && setShow(false);
        props.onClose && props.onClose();
      }
    };

    if (closeOnEsc && show) window.addEventListener("keyup", handleKeyUp);
    else if (!show) window.removeEventListener("keyup", handleKeyUp);
    // * Destroy the listener. CallBack should be same to be destroyed
    return () => window.removeEventListener("keyup", handleKeyUp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeOnEsc, setShow, show]);

  /* handle Close Panel */

  const handleClose = () => {
    setShow && setShow(false);
  };

  if (show)
    return (
      <div className={styles.dialogWrapper}>
        <div
          className={`${styles.backdrop} ${
            props.glassEffect ? styles.blurBg : ""
          }`}
          onClick={() =>
            !props.preventBackdropClose && setShow && setShow(false)
          }
        ></div>
        <div className={styles.dialogCard}>
          <div className={styles.dialogHeader}>
            {props.headerTemplate ? props.headerTemplate() : props.title}
          </div>

          <div className={`${styles.dialogBody}`}>{props.children}</div>
          {!props.hideCloseBtn && (
            <button
              onClick={props.onClose ? props.onClose : handleClose}
              className={styles.closeBtn}
            >
              &times;
            </button>
          )}
        </div>
      </div>
    );
  return null;
});

//* Display name for memoization.
Dialog.displayName = "Dialog";
export default Dialog;
