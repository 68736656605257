import { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { COLSAggregatorBalanceReport } from "../../../../common/constants/columns.constant";
import { FORMAT } from "../../../../common/constants/datetime.constant";
import { STATUS } from "../../../../common/constants/general.constant";
import { formatDateString } from "../../../../common/helpers/date.helper";
import {
  NameIdTemplate,
  toLocaleNumber,
} from "../../../../common/helpers/general.helper";
import { ReportBalanceDTO } from "../../../../common/models/dashborad.model";
import FlatButton from "../../../../components/elements/buttons/flat.button";
import PrimeTable from "../../../../components/ui/table/primeTable.ui";
import {
  AtomAggregatorBalanceReport,
  AtomAggregatorsObj,
} from "../../../../store/aggregator.store";
import SettleDialog from "../settleDialog/settleDialog.component";
import styles from "./aggregatorTable.module.scss";

interface AgentTableProps {
  fetchBalanceReport: () => void;
  loader?:boolean;
}

const AggregatorTable = ({ fetchBalanceReport,loader }: AgentTableProps) => {
  const [aggregatorBalanceReport] = useRecoilState(AtomAggregatorBalanceReport);
  const [showSettle, setShowSettle] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const aggregators = useRecoilValue(AtomAggregatorsObj);
  const handleSettle = (rowData: any) => {
    setShowSettle(true);
    setSelectedRow(rowData);
  };

  const TableColumn = (
    rowData: ReportBalanceDTO | any,
    { rowIndex, field }: any
  ) => {
    switch (field) {
      case "Sr_no":
        return ++rowIndex;
      case "operatorDetail":
        const name =
          aggregators[rowData.aggregatorId] &&
          aggregators[rowData.aggregatorId].operatorDetail
            ? aggregators[rowData.aggregatorId].operatorDetail
            : rowData.operatorDetail;
        return NameIdTemplate(name, rowData.aggregatorId);

      case "rebateRate":
        return (
          <>
            <div className={styles.alignRight}>
              {rowData?.rebateRate
                ? `${rowData.rebateRate?.toFixed(2)} %`
                : "-"}
            </div>
          </>
        );

      case "closingBalance":
        return (
          <div className={styles.closingBalance}>
            {toLocaleNumber(rowData?.closingBalance)}
          </div>
        );
      case "environment":
        return rowData.environment === true ? STATUS.LIVE : STATUS.SIM;
      case "lastReportEndDate":
        return (
          <div className="text-center">
            {formatDateString(rowData[field], FORMAT.ddMMyyyy_slash)}
          </div>
        );
      case "lastReportStartDate":
        return (
          <div className="text-center">
            {formatDateString(rowData[field], FORMAT.ddMMyyyy_slash)}
          </div>
        );
      case "action":
        return (
          <div className={styles.actionButtons}>
            <FlatButton label="Report" onClick={() => handleSettle(rowData)} />
          </div>
        );
      default:
        return rowData[field] ?? "-";
    }
  };
  return (
    <>
      <h1 className="p1">Aggregators</h1>
      <SettleDialog
        show={showSettle}
        setShow={setShowSettle}
        selectedRow={selectedRow}
        fetchAgentBalanceReport={fetchBalanceReport}
      />

      <PrimeTable
        data={aggregatorBalanceReport}
        columns={COLSAggregatorBalanceReport}
        ColumnBody={TableColumn}
        isLoading={loader}
      />
    </>
  );
};

export default AggregatorTable;
