import styles from "./customMultiSelect.module.scss";

import { ElementChangeEvent } from "../../../common/types/ui.types";
import {
  ReactNode,
  useRef,
  useState,
  KeyboardEvent,
  ChangeEvent,
  useCallback,
  useEffect,
} from "react";
interface PzMultiSelectProps {
  onChange: (e: ElementChangeEvent) => void;
  options: any[];
  optionKey?: string;
  selectedOptions: any[];
  label?: string;
  optionObjKey?: string;
  name: string;
  optionTemplate?: (op: any) => ReactNode;
  className?: string;
  searchBy?: any;
  showTags?: boolean;
  placeholder?: string;
  onFilter?: (e: ElementChangeEvent) => void;
  isTemplate?: boolean;
}

const CustomMultiSelect = ({
  options,
  name,
  selectedOptions,
  ...props
}: PzMultiSelectProps) => {
  const [shown, setShown] = useState(false);
  const dropRef = useRef<HTMLDivElement>(null);
  const valueRef = useRef<HTMLDivElement>(null);
  const dropInputRef = useRef<HTMLInputElement>(null);
  const [searchStr, setSearchStr] = useState<string>("");
  const searchRef = useRef<HTMLInputElement>(null);
  // const [filteredOptions, setFilteredOptions] = useState<any[]>([]); //

  const handleOptionClick = (option: any) => {
    let _options = [...(selectedOptions ?? [])]; // previous selected options.
    // find and delete selected options or add option if not available.
    const i = _options.findIndex((x) =>
      typeof option === "object" && props.optionObjKey
        ? x[props.optionObjKey] === option[props.optionObjKey]
        : x === option
    );
    i === -1 ? _options.push(option) : _options.splice(i, 1);
    // On Change callback triggered in parent.
    props.onChange && props.onChange({ data: _options, name });
  };

  const handleFilter = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchStr(e.target.value);
    props.onFilter && props.onFilter({ data: e.target.value, name });
  };

  useEffect(() => {
    // Hide the target dropdown.
    const onOutsideClick = (e: any) => {
      if (dropRef.current && !dropRef.current.contains(e.target)) {
        setShown(false);
      }
    };

    window.addEventListener("click", onOutsideClick);
    return () => {
      window.removeEventListener("click", onOutsideClick);
    };
  }, []);
  // * Filter method triggers on option and search value change.
  // const filterOptions = useCallback(
  //   (objKey?: string) => {
  //     const _search = searchStr?.toLowerCase();
  //     let _filtered = [...options];
  //     if (_search) {
  //       _filtered = _filtered.filter((x) => {
  //         let _options = props.searchBy && props.searchBy[x]?.detail;
  //         let val: string = _options;
  //         if (objKey) return val?.toString().toLowerCase().startsWith(_search);
  //         else return val?.toString().toLowerCase().startsWith(_search);
  //       });
  //     }

  //     props.onFilter && props.onFilter(options);

  //     setFilteredOptions(_filtered);
  //   },
  //   []
  // );

  // * Initially filter and set options to be rendered.
  // useEffect(() => {
  //   filterOptions(props.optionObjKey);
  // }, [filterOptions, props.optionObjKey]);

  const TagsTemplate = selectedOptions.map((option, index) => {
    const label = props.optionObjKey
      ? option[props.optionObjKey]
      : typeof option === "string" || typeof option === "number"
      ? option
      : "";
    return (
      <span className={styles.tag} key={index}>
        {label}
        <button
          onClick={(e) => {
            e.stopPropagation();
            handleOptionClick(option);
          }}
          tabIndex={-1}
        >
          &times;
        </button>
      </span>
    );
  });

  const ValueTemplate = selectedOptions.map((option, index) => {
    let _options = props.searchBy && props.searchBy[option]?.detail;
    const label = props.optionObjKey
      ? option[props.optionObjKey]
      : typeof option === "string" || typeof option === "number"
      ? option
      : "";
    return (
      <span className={styles.value} key={index}>
        {/* &nbsp;{label} */}

        {props.isTemplate ? _options : label}
      </span>
    );
  });

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.code) {
      case "Enter":
        setShown(!shown);
        break;
      case "Space":
        setShown(!shown);
        break;
      case "Escape":
        setShown(false);
        break;
      case "NumpadEnter":
        setShown(!shown);
        break;
      default:
        break;
    }
  };

  const handleSearchKeyUp = (e: any) => {
    switch (e.code) {
      case "Escape":
        setShown(false);
        break;
    }
  };

  const OptionsTemplate = options.map((option, index) => {
    const label = props.optionObjKey
      ? option[props.optionObjKey]
      : typeof option === "string" || typeof option === "number"
      ? option
      : "-";

    let isSelected =
      typeof option !== "object" && selectedOptions.includes(option);
    isSelected =
      typeof option === "object"
        ? selectedOptions.some(
            (x) =>
              x[props.optionObjKey ?? ""] === option[props.optionObjKey ?? ""]
          )
        : selectedOptions.includes(option);

    return (
      <button
        className={`${isSelected && styles.activeOption}`}
        onClick={() => handleOptionClick(option)}
        key={index}
        onKeyUp={handleSearchKeyUp}
      >
        <span className={`${styles.checkbox} ${isSelected && styles.checked}`}>
          {isSelected && <>&#10003;</>}
        </span>

        <span className={styles.optionText}>
          {props.isTemplate ? (
            <>{props.optionTemplate && props.optionTemplate(option)}</>
          ) : (
            <>{label}</>
          )}
        </span>
      </button>
    );
  });
  const onSelectAll = () => {
    if (!props.onChange) return;
    if (selectedOptions.length === options.length)
      props.onChange({ data: [], name });
    else props.onChange({ data: options, name });
  };

  const handleClear = () => {
    setSearchStr("");
    props.onFilter && props.onFilter({ data: "", name });
  };

  const Search = (
    <div className={styles.searchWrapper}>
      <input
        onChange={handleFilter}
        value={searchStr}
        type="text"
        ref={searchRef}
        autoFocus
        onKeyUp={handleSearchKeyUp}
        className={styles.searchInput}
        placeholder="Search Options...  "
      />
      <button onClick={handleClear} tabIndex={-1} className={styles.clearBtn}>
        &times;
      </button>
    </div>
  );

  const NoOptions = (msg: string) => {
    return <div className={styles.noOptions}>{msg}</div>;
  };

  const toggleAndFocus = (value: boolean) => {
    if (!value) dropInputRef.current?.focus();
    setShown(value);
  };

  return (
    <div className={styles.body}>
      <div
        ref={dropRef}
        className={`${styles.selectWrapper} ${props.className ?? ""}`}
      >
        <div
          ref={valueRef}
          onClick={() => toggleAndFocus(!shown)}
          className={styles.valueWrapper}
        >
          <div className={styles.values}>
            {selectedOptions.length > 2
              ? `${selectedOptions.length} selected`
              : props.showTags
              ? TagsTemplate
              : ValueTemplate}
          </div>
        </div>
        <input
          onClick={() => toggleAndFocus(!shown)}
          type="text"
          className={`${styles.input} `}
          name={name}
          readOnly
          ref={dropInputRef}
          onKeyUp={handleKeyUp}
          placeholder={!selectedOptions.length ? props.placeholder : ""}
        />
        <label className={styles.floatLabel} htmlFor={name}>
          {props.label}
        </label>

        <div className={`${styles.optionWrapper} ${shown && styles.shown}`}>
          <div className={styles.optionsHeader}>
            <span
              className={`${styles.checkbox} ${
                selectedOptions.length === options.length && styles.checked
              }`}
              onClick={onSelectAll}
            >
              {selectedOptions.length === options.length &&
                options.length > 0 && <>&#10003;</>}
            </span>
            {Search}
          </div>

          <div className={styles.options}>
            {!options.length
              ? NoOptions("Options Not Available")
              : !options.length
              ? NoOptions("Options Not Available!")
              : OptionsTemplate}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomMultiSelect;
