import { OperatorDTO } from "../models/operator.model";
import { FDFilterDTO } from "../models/dashborad.model";
import { UserDTO } from "../models/user.model";
import { ROUTES } from "../routes/default.routes";
import { showToast } from "./toast.helper";

// returns error message if error available.
export const validateUser = (userDetails: UserDTO) => {
  if (!userDetails.fullName) {
    return "Full name Should Not Be Empty!!";
  }
  if (!userDetails?.username) {
    return "Environment Should Not Be Empty!!";
  }
  if (!userDetails.email) {
    return "Email Should Not Be Empty!!";
  }
  if (!userDetails?.password) {
    return "Password Should Not Be Empty!!";
  }
  if (!userDetails?.role) {
    return "User type Should Not Be Empty!!";
  }
  return null;
};

// * validation for agent entry
export const isValidAgent = (
  agentDetails: OperatorDTO,
  setErrorMessage: (err: string) => void
) => {
  if (!agentDetails.aggregatorId) {
    setErrorMessage("Aggregator Id should Not Be Empty!!");
    return false;
  }
  if (!agentDetails.operatorId) {
    setErrorMessage("Operator ID should Not Be Empty!!");
    return false;
  }
  if (!agentDetails?.status) {
    setErrorMessage("Status should be selected!!");
    return false;
  }
  if (!agentDetails?.detail) {
    setErrorMessage("Details should not be empty!!");
    return false;
  }

  if (!agentDetails?.rebateRate) {
    setErrorMessage("Rebate rate should not be empty");
    return false;
  }
  if (!agentDetails?.originUrls) {
    setErrorMessage("Origin URL should Not Be Empty!!");
    return false;
  }
  return true;
};

// On API error navigate to sign in page.
export const checkError = (
  error: any,
  _showToast?: boolean,
  navigate?: any
) => {
  const _err = error?.response?.data;
  const _sCode = _err?.statusCode;
  const _msg = _err?.message ?? "Server not responded! Please try later.";

  if (_showToast) showToast("error", _msg);

  if (_sCode === 401 && navigate) navigate(ROUTES.SIGN_IN);
};

// * Validate FD Filter
export const validateFDFilter = (filter: FDFilterDTO) => {
  if (!filter.startDate) return "Please select start date!";
  if (!filter.endDate) return "Please select end date!";
  return null;
};
