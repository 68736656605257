
import { FILTER } from "../../../common/constants/general.constant";

import React from "react";
// import { FILTER, RolesType } from "../../../common/constants/general.constant";

import { FilterDTO, __FilterDTO } from "../../../common/models/filter.modal";
import { ElementChangeEvent } from "../../../common/types/ui.types";
import PzMultiSelect from "../multiselect/multiselect.element";
import styles from "./filter.module.scss";
import { Checkbox, CheckboxChangeParams } from "primereact/checkbox";
import { AtomAggregatorsObj } from "../../../store/aggregator.store";
import { useRecoilValue } from "recoil";
import { AtomOperators } from "../../../store/operator.store";
import { NameIdTemplate } from "../../../common/helpers/general.helper";

import { OperatorDTO } from "../../../common/models/operator.model";
import CustomMultiSelect from "../customMultiselect/customMultiselect.element";

interface FilterProps {
  show: boolean;
  filterData: __FilterDTO;
  onFilterCHange: (e: ElementChangeEvent, optionkey?: string) => void;
  onFilterClick: () => void;
  onClearClick: () => void;
  default?: any;
  onFilter?: (e: ElementChangeEvent, type?: string) => void;
}
const Filter = ({ show, ...props }: FilterProps) => {
  const user = props.filterData[FILTER.user];
  const role = props.filterData[FILTER.role];
  const aggregator = props.filterData[FILTER.aggregator];
  const operator = props.filterData[FILTER.operator];
  const currency = props.filterData[FILTER.currency];
  const environment = props.filterData[FILTER.environment];
  const status = props.filterData[FILTER.status];
  const isDefault = props.filterData[FILTER.isDefault];
  const methodType = props.filterData[FILTER.methodType];
  const clientId = props.filterData[FILTER.clientId];
  const roundId = props.filterData[FILTER.roundId];
  const betStatus = props.filterData[FILTER.betStatus];
  const poolType = props.filterData[FILTER.poolType];
  const raceKey = props.filterData[FILTER.raceKey];
  const connectorId = props.filterData[FILTER.connectorId];
  const settlementType = props.filterData[FILTER.settlementType];
  const settlementBy = props.filterData[FILTER.settledBy];
  const username = props.filterData[FILTER.username];
  const wagerCurrency = props.filterData[FILTER.wagerCurrency];
  const aggregators = useRecoilValue(AtomAggregatorsObj);
  const operators = useRecoilValue(AtomOperators);

  const handleChange = (e: ElementChangeEvent, type: string) => {
    let _data = { ...props.filterData };
    let _d = { ..._data[type], selected: e.data };
    props.onFilterCHange({ data: _d, name: type });
  };

  const onCheckBoxChange = (e: CheckboxChangeParams, type: string) => {
    let _data = { ...props.filterData };
    let _d: FilterDTO = { ..._data[type], showDefault: e.checked };
    props.onFilterCHange({ data: _d, name: type });
  };

  const aggregatorsObjTemplate = (option: string) => {
    const name = aggregators[option]
      ? aggregators[option].detail
      : option ?? "";
    return NameIdTemplate(name, option);
  };

  const operatorObjTemplate = (option: any, optKey?: string) => {
    const name = operators[option] ? operators[option].detail : option;
    const _defaultFlag = operators[option]?.isDefault
      ? operators[option]?.isDefault
      : undefined;
    return NameIdTemplate(name, option, _defaultFlag);
  };

  const handleFilter = (e: ElementChangeEvent, type: string) => {
    props.onFilter && props.onFilter(e, type);
  };

  return (
    <div
      className={`${styles.filterWrapper} ${
        show ? styles.filterShow : styles.filterHide
      }`}
    >
      <div className={styles.filterFields}>
        {user && (
          <CustomMultiSelect
            onFilter={(e) => handleFilter(e, "user")}
            options={user?.options ?? []}
            selectedOptions={user?.selected ?? []}
            onChange={(e) => handleChange(e, FILTER.user)}
            name="username"
            label="Users"
          />
        )}
        {role && (
          <CustomMultiSelect
            onFilter={(e) => handleFilter(e, "role")}
            options={role?.options ?? []}
            selectedOptions={role?.selected ?? []}
            onChange={(e) => handleChange(e, FILTER.role)}
            name="role"
            label="User Type"
          />
        )}

        {aggregator && (
          <CustomMultiSelect
            onChange={(e) => handleChange(e, FILTER.aggregator)}
            options={aggregator?.options ?? []}
            selectedOptions={aggregator.selected ?? []}
            name="aggregatorId"
            label={"Aggregator"}
            onFilter={(e) => handleFilter(e, "aggregator")}
            optionTemplate={aggregatorsObjTemplate}
            searchBy={aggregators}
            isTemplate
          />
        )}
        {operator && (
          <CustomMultiSelect
            onFilter={(e) => handleFilter(e, "operator")}
            options={operator?.options ?? []}
            selectedOptions={operator?.selected ?? []}
            onChange={(e) => handleChange(e, FILTER.operator)}
            name={"operatorId"}
            label="Operators"
            optionTemplate={operatorObjTemplate}
            searchBy={operators}
            isTemplate
          />
        )}

        {currency && (
          <CustomMultiSelect
            onFilter={(e) => handleFilter(e, "currency")}
            options={currency?.options ?? []}
            selectedOptions={currency?.selected ?? []}
            onChange={(e) => handleChange(e, FILTER.currency)}
            name={"currency"}
            label="Currency"
          />
        )}
        {environment && (
          <CustomMultiSelect
            // onFilter={props.onFilter}
            options={environment?.options ?? []}
            selectedOptions={environment?.selected ?? []}
            onChange={(e) => handleChange(e, FILTER.environment)}
            name={"environment"}
            label="Environment"
          />
        )}
        {status && (
          <CustomMultiSelect
            onFilter={(e) => handleFilter(e, "status")}
            options={status?.options ?? []}
            selectedOptions={status?.selected ?? []}
            onChange={(e) => handleChange(e, FILTER.status)}
            name={"status"}
            label="Status"
          />
        )}
        {isDefault && (
          <CustomMultiSelect
            onFilter={(e) => handleFilter(e, "isDefault")}
            options={isDefault?.options ?? []}
            selectedOptions={isDefault?.selected ?? []}
            onChange={(e) => handleChange(e, FILTER.isDefault)}
            name={"isDefault"}
            label="Default"
          />
        )}
        {methodType && (
          <CustomMultiSelect
            onFilter={(e) => handleFilter(e, "methodType")}
            options={methodType?.options ?? []}
            selectedOptions={methodType?.selected ?? []}
            onChange={(e) => handleChange(e, FILTER.methodType)}
            name={"methodType"}
            label="Method Type"
          />
        )}
        {clientId && (
          <PzMultiSelect
            onFilter={(e) => handleFilter(e, "clientId")}
            options={clientId?.options ?? []}
            selected={clientId?.selected ?? []}
            onChange={(e) => handleChange(e, FILTER.clientId)}
            name={"clientId"}
            label="Client"
          />
        )}
        {roundId && (
          <PzMultiSelect
            onFilter={(e) => handleFilter(e, "roundId")}
            options={roundId?.options ?? []}
            selected={roundId?.selected ?? []}
            onChange={(e) => handleChange(e, FILTER.roundId)}
            name={"roundId"}
            label="Round Id"
          />
        )}
        {betStatus && (
          <CustomMultiSelect
            onFilter={(e) => handleFilter(e, "betStatus")}
            options={betStatus?.options ?? []}
            selectedOptions={betStatus?.selected ?? []}
            onChange={(e) => handleChange(e, FILTER.betStatus)}
            name={"betStatus"}
            label="Bet Status"
          />
        )}
        {poolType && (
          <CustomMultiSelect
            onFilter={(e) => handleFilter(e, "poolType")}
            options={poolType?.options ?? []}
            selectedOptions={poolType?.selected ?? []}
            onChange={(e) => handleChange(e, FILTER.poolType)}
            name={"poolType"}
            label="Pool Type"
          />
        )}
        {raceKey && (
          <CustomMultiSelect
            onFilter={(e) => handleFilter(e, "raceKey")}
            options={raceKey?.options ?? []}
            selectedOptions={raceKey?.selected ?? []}
            onChange={(e) => handleChange(e, FILTER.raceKey)}
            name={"raceKey"}
            label="Race Key"
          />
        )}
        {connectorId && (
          <CustomMultiSelect
            onFilter={(e) => handleFilter(e, "connectorId")}
            options={connectorId?.options ?? []}
            selectedOptions={connectorId?.selected ?? []}
            onChange={(e) => handleChange(e, FILTER.connectorId)}
            name={"connectorId"}
            label="Connector"
          />
        )}
        {settlementType && (
          <CustomMultiSelect
            onFilter={(e) => handleFilter(e, "settlementType")}
            options={settlementType?.options ?? []}
            selectedOptions={settlementType?.selected ?? []}
            onChange={(e) => handleChange(e, FILTER.settlementType)}
            name={"settlementType"}
            label="Settlement Type"
          />
        )}
        {settlementBy && (
          <CustomMultiSelect
            onFilter={(e) => handleFilter(e, "settlementBy")}
            options={settlementBy?.options ?? []}
            selectedOptions={settlementBy?.selected ?? []}
            onChange={(e) => handleChange(e, FILTER.settledBy)}
            name={"settledBy"}
            label="Settlement By"
          />
        )}
        {username && (
          <CustomMultiSelect
            onFilter={(e) => handleFilter(e, "username")}
            options={username?.options ?? []}
            selectedOptions={username?.selected ?? []}
            onChange={(e) => handleChange(e, FILTER.username)}
            name={"username"}
            label="User Name"
          />
        )}
        {wagerCurrency && (
          <CustomMultiSelect
            onFilter={(e) => handleFilter(e, "wagerCurrency")}
            options={wagerCurrency?.options ?? []}
            selectedOptions={wagerCurrency?.selected ?? []}
            onChange={(e) => handleChange(e, FILTER.wagerCurrency)}
            name={"wagerCurrency"}
            label="Currency"
          />
        )}
        {operator?.showDefault !== undefined && (
          <>
            <div className={styles.checkboxWrp}>
              <Checkbox
                onChange={(e) => onCheckBoxChange(e, FILTER.operator)}
                name="showDefault"
                checked={operator.showDefault}
                className={"pzCheck"}
              />
              <label htmlFor="cb1" className={styles.label}>
                Show default only
              </label>
            </div>
          </>
        )}
        <div className={styles.filterButtonsDiv}>
          <button
            className={`${styles.button} ${styles.filterButton}`}
            onClick={() => props.onFilterClick()}
          >
            Filter
          </button>
          <button
            className={`${styles.button} ${styles.clearButton}`}
            onClick={() => props.onClearClick()}
          >
            Clear
          </button>
        </div>
      </div>
    </div>
  );
};

export default Filter;
