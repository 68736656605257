import { ReactNode, useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { useRecoilState } from "recoil";
import { ROUTES } from "../common/routes/default.routes";
import { DashboardService } from "../common/services/dashboard.service";
import { AtomProvinces } from "../store/dashboard.store";

interface DashboardProps {
  children?: ReactNode;
}

const DashboardProvider = ({ children }: DashboardProps) => {
  const [currencies, setProvinces] = useRecoilState(AtomProvinces); // Storing all provinces from the APi.
  const { getCurrencies: getProvinces } = DashboardService(); // Service handles api calls and data manipulation
  const navigate = useNavigate();

  const path = useLocation().pathname;

  // * Fetching provinces data for the first time when dashboard initialized.
  const fetchProvinces = useCallback(() => {
    getProvinces({
      setCurrencies: setProvinces,
      setSelectedProvince: (p) => {
        if (path.startsWith(ROUTES.DASHBOARD))
          navigate(`${ROUTES.DASHBOARD}/${p.province}`);
      },
      navigate,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProvinces, navigate, setProvinces]);

  // const fetchAgentBalanceReport = useCallback(() => {
  //   getBalanceReport({
  //     province: "AU",
  //     setOperatorBalanceReport: setAgentBalanceReport,
  //     setAggregatorBalanceReport: setOperatorBalanceReport,
  //     setAdminBalanceReport,
  //     navigate,
  //     setAllDashboardData,
  //     setLoader: () => {},
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  // ! Do not DELETE

  // * Initially fetch data if data is undefined.
  useEffect(() => {
    if (currencies === undefined) fetchProvinces();
    // fetchAgentBalanceReport();
    // fetchAgentBalanceReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};

export default DashboardProvider;
