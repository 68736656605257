import { ReactNode, useEffect, useRef, useState } from "react";
import { ElementChangeEvent } from "../../../common/types/ui.types";
import { IoMdArrowDropdown } from "react-icons/io";
import styles from "../dropdown/dropdown.module.scss";

interface DropDownProps {
  options: any[];
  selectedValue: any;
  name: string;
  placeholder?: string;
  className?: string;
  label?: string;
  optionKey?: string;
  valueObjKey?: string;
  onChange?: (e: ElementChangeEvent, valueObjKey?: string) => void;
  disabled?: boolean;
  returnValue?: "string" | "object" | "number";
  icon?: any;
  optionTemplate?: (op: any, optKey?: string) => ReactNode | string;
  valueTemplate?: (val: any) => ReactNode | string;
}

const Dropdown = ({
  name,
  className,
  placeholder,
  label,
  options,
  selectedValue,
  ...props
}: DropDownProps) => {
  const dropRef = useRef<HTMLInputElement>(null);
  const [shown, setShown] = useState(false);
  //   const [filteredValue, setFilteredValue] = useState<any[]>([]);

  const handleOptionClick = (option: any) => {
    setShown(false);

    props.onChange && props.onChange({ data: option, name }, props.valueObjKey);
  };

  const OptionsTemplate = options.map((option, index) => {
    const label =
      typeof option === "object" && props.optionKey
        ? option[props.optionKey]
        : option;

    const returnVal =
      props.returnValue === "string" && props.optionKey
        ? option[props.optionKey]
        : option;

        
    return (
      <button
        key={index}
        className={styles.dropdownButton}
        onClick={() => handleOptionClick(returnVal)}
      >
        {props.optionTemplate ? props.optionTemplate(option) : label}
      </button>
    );
  });

  const labelValue =
    typeof selectedValue === "object" && props.valueObjKey
      ? selectedValue[props.valueObjKey]
      : selectedValue;

  // Callbacks
  useEffect(() => {
    // Hide the target dropdown.
    const onOutsideClick = (e: any) =>
      dropRef.current && !dropRef.current.contains(e.target) && setShown(false);

    window.addEventListener("click", onOutsideClick);
    return () => {
      window.removeEventListener("click", onOutsideClick);
    };
  }, []);

  return (
    <>
      <div
        className={`${styles.dropdownWrapper} ${
          props.disabled && styles.disabled
        }`}
        ref={dropRef}
      >
        <label htmlFor={name} className={styles.label}>
          {" "}
          {label}
        </label>
        <input
          readOnly
          onClick={() => props.disabled !== true && setShown(!shown)}
          type="text"
          className={styles.input}  
          placeholder={placeholder}
          name={name}
          value={
            props.valueTemplate
              ? props.valueTemplate(selectedValue)
              : labelValue
          }
          disabled={props.disabled && props.disabled}
        />

        <div className={styles.buttonIcon}>{<IoMdArrowDropdown />}</div>
        <div className={`${styles.optionWrapper} ${shown ? styles.shown : ""}`}>
          {options.length > 0 ? (
            <div className={styles.options}>{OptionsTemplate}</div>
          ) : (
            <h4 style={{ padding: "1.5rem" }}>No Options Available</h4>
          )}
        </div>
      </div>
    </>
  );
};

export default Dropdown;
