import { memo, ReactNode, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { AtomAggregatorsObj } from "../store/aggregator.store";
import { API, API_MOD } from "../common/config/api.config";
import { AtomAuthUser } from "../store/auth.store";
import { AggregatorDTO } from "../common/models/aggregator.model";
import { ROLES } from "../common/constants/general.constant";
import { checkError } from "../common/helpers/validation.helper";
import { useNavigate } from "react-router";
import { OperatorDTO } from "../common/models/operator.model";
import { __OperatorDTO, __AggregatorDTO } from "../common/types/dynamic.types";
import { AtomDefaultOperators, AtomOperators } from "../store/operator.store";
import { AggregatorService } from "../common/services/aggregator.service";

//* Provider Prop Types
interface OperatorProviderProps {
  children: ReactNode;
}

//* Provider

const DataProvider = memo(({ children }: OperatorProviderProps) => {
  const setAggregators = useSetRecoilState(AtomAggregatorsObj);
  const setOperators = useSetRecoilState(AtomOperators);
  const setDefaultOperators = useSetRecoilState(AtomDefaultOperators);
  const authUser = useRecoilValue(AtomAuthUser);
  const navigate = useNavigate();

  const getOperators = () => {
    if (!authUser) return;
    const { role, token } = authUser;

    if (role === ROLES.ADMIN)
      API.get(`${API_MOD.AGGREGATOR}/fetchAll`, {
        headers: {
          Authorization: `${token}`,
        },
      })
        .then((res) => {
          const _data: AggregatorDTO[] = res?.data ?? [];

          const _sorted = _data.sort((a, b) =>
            a.aggregatorId < b.aggregatorId
              ? -1
              : a.aggregatorId > b.aggregatorId
              ? 1
              : 0
          );
          let _op: __AggregatorDTO = {};
          for (const operator of _sorted) _op[operator.aggregatorId] = operator;
          setAggregators(_op);
        })
        .catch((err) => {
          console.log(err);
          checkError(err, true, navigate);
        });
  };

  const getAgents = () => {
    if (!authUser) return;
    const { role, token } = authUser;
    if (role === ROLES.OPERATOR) return;

    API.get(`${API_MOD.OPERATOR}/fetchAll`, {
      headers: {
        Authorization: `${token}`,
      },
    })
      .then((res) => {
        const _agents = (res?.data as OperatorDTO[]) ?? [];

        const _sorted = _agents.sort((a, b) =>
          a.operatorId < b.operatorId ? -1 : a.operatorId > b.operatorId ? 1 : 0
        );
        let _defaults = _agents
          .filter((x) => x.isDefault === true)
          .map((x) => x.operatorId);

        let _agentObj: __OperatorDTO = {};
        for (const agent of _sorted) _agentObj[agent.operatorId] = agent;

        setOperators(_agentObj);
        setDefaultOperators(_defaults);
      })
      .catch((err) => {
        checkError(err, true, navigate);
      });
  };

  // const getUsers = () => {
  //   if (!authUser) return;
  //   const { token, role } = authUser;
  //
  //   if (role === ROLES.ADMIN || role === ROLES.AGGREGATOR)
  //     API.get(`${API_MOD.USER}/fetchAll`, {
  //       headers: {
  //         Authorization: `${token}`,
  //       },
  //     })
  //       .then((res) => {
  //         const _data = (res?.data as UserDTO[]) ?? [];
  //         const _sorted = _data.sort((a, b) =>
  //           a.fullName < b.fullName ? -1 : a.fullName > b.fullName ? 1 : 0
  //         );
  //         setUsers(_sorted);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         checkError(err, true, navigate);
  //       });
  // };

  const getSingleAggregator = () => {
    const { getAggregator } = AggregatorService();
    if (authUser?.role !== ROLES.AGGREGATOR) return;
    getAggregator({
      authUser,
      setAggregators,
    });
  };

  useEffect(() => {
    if (authUser?.role) {
      getOperators();
      getAgents();
      // getUsers();
      getSingleAggregator();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser]);

  return <>{children}</>;
});

//* Display name for memoization.
DataProvider.displayName = "OperatorProvider";
export default DataProvider;
