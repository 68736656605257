import { useCallback, useEffect, useState } from "react";
import { FiMenu } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import { API, API_MOD } from "../../../common/config/api.config";
import { AuditColumns } from "../../../common/constants/columns.constant";
import {
  formatDate,
  formatDateString,
  formatTimestamp,
} from "../../../common/helpers/date.helper";
import { checkError } from "../../../common/helpers/validation.helper";
import { AuditDTO } from "../../../common/models/audit.model";
import { REPORT_TAB } from "../../../common/routes/default.routes";
import {
  ColumnBodyOptions,
  ElementChangeEvent,
} from "../../../common/types/ui.types";
import FlatButton from "../../../components/elements/buttons/flat.button";
import PrimeTable from "../../../components/ui/table/primeTable.ui";
import { AtomAuthUser, AtomShowSidebar } from "../../../store/auth.store";

import {
  AtomAuditReport,
  AtomAuditReportDate,
} from "../../../store/report.store";
import styles from "./_reports.module.scss";
import ReportHeader from "../../../components/shared/ReportHeader/reportHeader.element";
import Filter from "../../../components/elements/Filter/filter.element";
import { __FilterDTO } from "../../../common/models/filter.modal";
import { FILTER } from "../../../common/constants/general.constant";
import { objToStringList } from "../../../common/helpers/general.helper";
import { FilterHelper } from "../../../common/helpers/filter.helper";

const InitFilter = {
  [FILTER.methodType]: { options: [], selected: [] },
  [FILTER.username]: { options: [], selected: [] },
};

const AuditReport = () => {
  const path = useLocation().pathname;
  // State Variables
  const [isOpen, setIsOpen] = useRecoilState(AtomShowSidebar);
  const [selectedDate, setSelectedDate] = useRecoilState(AtomAuditReportDate);
  const [auditReport, setAuditReport] = useRecoilState(AtomAuditReport);
  const [filteredAudit, setFilteredAudit] = useState<AuditDTO[]>([]);
  const [search, setSearch] = useState("");

  const [, setLoader] = useState(false);
  const authUser = useRecoilValue(AtomAuthUser);
  const [filterData, setFilterData] = useState<__FilterDTO>(InitFilter);
  const [filterShow, setFilterShow] = useState(false);
  const navigate = useNavigate();
  const handleRoute = (route: string) => {
    navigate(route);
  };
  //   Functions
  const handleDateChange = (date: any, name: any) => {
    let _date = new Date(date);

    if (name === "startDate" && _date > selectedDate.endDate) {
      setSelectedDate({
        endDate: _date,
        startDate: _date,
      });
    } else {
      setSelectedDate({ ...selectedDate, [name]: _date });
    }
  };

  useEffect(() => {
    if (auditReport.length === 0) onSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = () => {
    initializeOptions(auditReport);
    setLoader(true);
    let selectStartDate = selectedDate.startDate;
    let selectEndDate = selectedDate.endDate;
    let timeDiff = Math.abs(
      selectStartDate.getTime() - selectEndDate.getTime()
    );
    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

    if (diffDays > 30) {
      toast.error("Start Date Should Not Be More Than 30 Days", {
        bodyClassName: "text",
        className: "text",
        position: "bottom-right",
      });
      return;
    }
    if (selectStartDate > selectEndDate) {
      toast.error("End date should not be greater than start date", {
        bodyClassName: "text",
        className: "text",
        position: "bottom-right",
      });
      return;
    }
    let startDate = formatDate(selectStartDate);
    let endDate = formatDate(selectEndDate);
    let auditPayload: any = {
      startDate: startDate.toString(),
      endDate: endDate.toString(),
    };
    API.post(`${API_MOD.REPORT}/auditReport`, auditPayload, {
      headers: {
        Authorization: `${authUser?.token}`,
      },
    })
      .then((res) => {
        let response = (res?.data as AuditDTO[]) ?? [];
        const _sorted = response.sort((a, b) =>
          a.timestamp < b.timestamp ? 1 : a.timestamp > b.timestamp ? -1 : 0
        );
        setAuditReport(_sorted);
        setFilteredAudit(_sorted);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        checkError(error, true, navigate);
      });
  };

  const ColumnBody = (
    rowData: AuditDTO | any,
    { field }: ColumnBodyOptions
  ) => {
    let _data = rowData[field] ?? "-";
    switch (field) {
      case "timestamp":
        return <>{formatTimestamp(Number(_data))}</>;
      case "localDate":
        return <>{formatDateString(Number(_data))}</>;
      case "message":
        return <>{_data ? _data : "-"}</>;
      case "impact":
        return <>{_data ? _data : "-"}</>;
      default:
        return <>{_data ?? "-"}</>;
    }
  };
  const handleMenuOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = (e: ElementChangeEvent) => {
    initializeOptions(auditReport);
    const val = e.data.toLowerCase();
    setSearch(e.data);
    const _filteredData = [...auditReport].filter((c) => {
      const isValid =
        c.username?.toLowerCase().startsWith(val) ||
        c.methodType?.toLowerCase().startsWith(val) ||
        formatTimestamp(c.timestamp).includes(val) ||
        formatTimestamp(c.timestamp).startsWith(val);
      return isValid;
    });
    setFilteredAudit(_filteredData);
  };
  const initializeOptions = useCallback(
    (_auditReport: AuditDTO[]) => {
      const _methodType = objToStringList(_auditReport, "methodType");
      const _username = objToStringList(_auditReport, "username");
      setFilterData({
        ...filterData,
        methodType: { ...filterData.methodType, options: _methodType },
        username: { ...filterData.username, options: _username },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    initializeOptions(auditReport);
    handleChange({ data: search, name: "" });
    setFilteredAudit(auditReport);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auditReport]);

  const handleFilter = () => {
    setFilterShow(!filterShow);
  };
  const handleFilterChange = (e: ElementChangeEvent) => {
    let _filter = { ...filterData };
    _filter = { ..._filter, [e.name]: e.data };
    setFilterData(_filter);
  };
  const onClearClick = () => {
    initializeOptions(auditReport);
    handleChange({ data: search, name: "" });
    setFilteredAudit(auditReport);
    setSearch("");
  };
  const onFilterClick = () => {
    setSearch("");
    const _methodTypeFilter = filterData[FILTER.methodType];
    const _usernameFilter = filterData[FILTER.username];
    let _filteredAudit: AuditDTO[] = [];

    for (const trans of auditReport) {
      if (
        _methodTypeFilter &&
        _methodTypeFilter?.selected?.length &&
        !_methodTypeFilter?.selected?.includes(trans.methodType)
      )
        continue;
      if (
        _usernameFilter &&
        _usernameFilter?.selected?.length &&
        !_usernameFilter?.selected.includes(trans.username)
      )
        continue;
      _filteredAudit.push(trans);
    }
    setFilteredAudit(_filteredAudit);
  };
  const filterSearch = (e: ElementChangeEvent, type?: string) => {
    switch (type) {
      case FILTER.methodType:
        const methodRes = FilterHelper.MethodType(e.data, auditReport);
        const _methodType = objToStringList(methodRes, "methodType");
        console.log("---_methodType", _methodType);

        setFilterData({
          ...filterData,
          methodType: { ...filterData.methodType, options: _methodType },
        });
        break;

      case FILTER.username:
        const filterResult = FilterHelper.MethodType(e.data, auditReport);
        const _filterResult = objToStringList(filterResult, "methodType");

        setFilterData({
          ...filterData,
          methodType: { ...filterData.methodType, options: _filterResult },
        });
        break;

      default:
        break;
    }
  };
  return (
    <div>
      <header className={styles.headerWrapper1}>
        <div className={styles.menuIcon}>
          <FiMenu
            onClick={handleMenuOpen}
            size={40}
            className="burgerMenuIcon"
          />
        </div>
        {REPORT_TAB.map((tab, key) => {
          const isActive = path.startsWith(tab.href);
          if (tab.roles?.includes(authUser?.role ?? ""))
            return (
              // <span
              //   key={key}
              //   className={`${styles.iconSet} ${isActive && styles.activeLink}`}
              // >
              <FlatButton
                key={key}
                label={tab.label}
                className={`${styles.iconSet} ${isActive && styles.activeLink}`}
                onClick={() => handleRoute(tab.href)}
              />
              // </span>
            );
          return null;
        })}
      </header>

      <ReportHeader
        selectedDate={selectedDate.startDate}
        onSubmit={onSubmit}
        onDateChange={(date) => handleDateChange(date, "startDate")}
        InputChange={handleChange}
        exportData={auditReport}
        onFilter={handleFilter}
        search={search}
        onEndDateChange={(date) => handleDateChange(date, "endDate")}
        selectedEndDate={selectedDate.endDate}
        exportFileName={`AuditReport_${formatDate(
          selectedDate.startDate
        )}_${formatDate(selectedDate.endDate)}`}
      />
      <Filter
        show={filterShow}
        filterData={filterData}
        onFilterCHange={handleFilterChange}
        onFilterClick={onFilterClick}
        onClearClick={onClearClick}
        onFilter={filterSearch}
      />
      <PrimeTable
        data={filteredAudit}
        columns={AuditColumns}
        ColumnBody={ColumnBody}
      />
    </div>
  );
};

export default AuditReport;
