import { useCallback, useEffect, useState } from "react";
import styles from "./_reports.module.scss";
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FlatButton from "../../../components/elements/buttons/flat.button";
import { TransactionLogColumns } from "../../../common/constants/columns.constant";
import { REPORT_TAB } from "../../../common/routes/default.routes";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  AtomTransactionDate,
  AtomTransaction,
} from "../../../store/report.store";

// import { CSVLink } from "react-csv";
import { API, API_MOD } from "../../../common/config/api.config";
import {
  formatDate,
  formatDateString,
  formatTimestamp,
} from "../../../common/helpers/date.helper";
import { AtomAuthUser, AtomShowSidebar } from "../../../store/auth.store";
import { format } from "date-fns";
import { FORMAT } from "../../../common/constants/datetime.constant";
import { toast } from "react-toastify";
import Dialog from "../../../components/ui/dialog/dialog.ui";
import { TransactionDTO } from "../../../common/models/report.model";
import { FiMenu } from "react-icons/fi";
import { checkError } from "../../../common/helpers/validation.helper";
import PrimeTable from "../../../components/ui/table/primeTable.ui";
import { showToast } from "../../../common/helpers/toast.helper";
import { JSONTree } from "react-json-tree";
import { AtomAggregatorsObj } from "../../../store/aggregator.store";
import {
  NameIdTemplate,
  objToStringList,
} from "../../../common/helpers/general.helper";
import { AtomOperators } from "../../../store/operator.store";
import { FaFlag } from "react-icons/fa";
import ReportHeader from "../../../components/shared/ReportHeader/reportHeader.element";
import { ElementChangeEvent } from "../../../common/types/ui.types";
import Filter from "../../../components/elements/Filter/filter.element";
import { __FilterDTO } from "../../../common/models/filter.modal";
import { FILTER, RolesType } from "../../../common/constants/general.constant";
import { FilterHelper } from "../../../common/helpers/filter.helper";
// import { parse } from "node:path/win32";

const theme = {
  scheme: "monokai",
  author: "wimer hazenberg (http://www.monokai.nl)",
  base00: "#272822",
  base01: "#383830",
  base02: "#49483e",
  base03: "#75715e",
  base04: "#a59f85",
  base05: "#f8f8f2",
  base06: "#f5f4f1",
  base07: "#f9f8f5",
  base08: "#f926ff",
  base09: "#fd971f",
  base0A: "#f4bf75",
  base0B: "#a6e22e",
  base0C: "#a1efe4",
  base0D: "#66d9ef",
  base0E: "#ae81ff",
  base0F: "#cc66ff",
};

const InitFilter = {
  [FILTER.methodType]: { options: [], selected: [] },
  [FILTER.aggregator]: { options: [], selected: [] },
  [FILTER.operator]: { options: [], selected: [] },
};

const TransactionLogPage = () => {
  const path = useLocation().pathname;
  const [transaction, setTransaction] = useRecoilState(AtomTransaction);
  const [filteredTransactions, setFilteredTransactions] = useState<
    TransactionDTO[]
  >([]);

  const [showReqResDialog, setShowReqResDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState({} as TransactionDTO | any);
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useRecoilState(AtomTransactionDate);
  const authUser = useRecoilValue(AtomAuthUser);
  const [isOpen, setIsOpen] = useRecoilState(AtomShowSidebar);
  const aggregators = useRecoilValue(AtomAggregatorsObj);
  const operators = useRecoilValue(AtomOperators);
  const [loader, setLoader] = useState(false);
  // let startDate = formatDate(selectedDate.startDate);
  // let endDate = formatDate(selectedDate.endDate);
  const [filterData, setFilterData] = useState<__FilterDTO>(InitFilter);
  const [filterShow, setFilterShow] = useState(false);
  const [search, setSearch] = useState("");

  const handleRoute = (route: string) => {
    navigate(route);
  };

  const onSubmit = () => {
    setLoader(true);
    let selectStartDate = selectedDate.startDate;
    let selectEndDate = selectedDate.endDate;
    let dateFirst = new Date(format(selectStartDate, FORMAT.mmddyyyy_slash));
    let dateSecond = new Date(format(selectEndDate, FORMAT.mmddyyyy_slash));
    let timeDiff = Math.abs(dateSecond.getTime() - dateFirst.getTime());
    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

    if (diffDays > 30) {
      toast.error("Start Date Should Not Be More Than 30 Days", {
        bodyClassName: "text",
        className: "text",
        position: "bottom-right",
      });
      return;
    }

    if (dateFirst > dateSecond) {
      toast.error("End date should not be greater than start date", {
        bodyClassName: "text",
        className: "text",
        position: "bottom-right",
      });
      return;
    }
    let startDate = formatDate(selectStartDate);
    let endDate = formatDate(selectEndDate);
    let transactionPayload: any = {
      startDate: startDate.toString(),
      endDate: endDate.toString(),
    };
    API.post(`${API_MOD.REPORT}/transactions`, transactionPayload, {
      headers: {
        Authorization: `${authUser?.token}`,
      },
    })
      .then((res) => {
        let response = (res?.data as TransactionDTO[]) ?? [];

        for (const transaction of response) {
          let _requestData = JSON.parse(transaction.request);
          let _poolKey = _requestData.poolKey;

          if (typeof _poolKey === "string") {
            let _A = _poolKey.split("~");
            transaction["poolType"] = _A[3];
          } else if (typeof _poolKey === "object") {
            transaction["poolType"] = _poolKey.poolType;
          }
        }

        const _sorted = response.sort((a, b) =>
          a.logTimestamp < b.logTimestamp
            ? 1
            : a.logTimestamp > b.logTimestamp
            ? -1
            : 0
        );

        setTransaction(_sorted);
        setLoader(false);
      })
      .catch((error) => {
        checkError(error, true, navigate);
        setLoader(false);
      });
  };

  const handleDateChange = (date: any, name: any) => {
    let _date = new Date(date);

    if (name === "startDate" && _date > selectedDate.endDate) {
      setSelectedDate({
        endDate: _date,
        startDate: _date,
      });
    } else {
      setSelectedDate({ ...selectedDate, [name]: _date });
    }
  };

  const onRowClick = (e: any) => {
    const _rowData = e.value;
    if (_rowData) {
      let _reqObj = { ..._rowData };
      const _req = _rowData?.request ?? "";
      const _res = _rowData?.response ?? "";

      try {
        let _reqData = JSON.parse(_req);
        _reqObj["_reqData"] = _reqData;
      } catch (error) {
        _reqObj["_reqData"] = _req;
      }

      try {
        _reqObj["_resdata"] = JSON.parse(_res);
      } catch (error) {
        _reqObj["_resdata"] = _res;
      }

      setSelectedRow(_reqObj);
    }
    if (_rowData?.request || _rowData?.response) {
      setShowReqResDialog(true);
    } else {
      showToast("error", "Data is not available for selected row..!");
    }
  };

  const reqResTemp = () => {
    return <h5 className={styles.dialogHeader}>Transaction Details</h5>;
  };

  // const handleMenuOpen = () => {};

  useEffect(() => {
    if (transaction.length === 0) onSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ColumnBody = (rowData: TransactionDTO | any, { field }: any) => {
    switch (field) {
      case "aggregatorId":
        const name = aggregators[rowData?.aggregatorId]
          ? aggregators[rowData?.aggregatorId].detail
          : rowData?.aggregatorId ?? "";

        return NameIdTemplate(name, rowData.aggregatorId);
      case "operatorId":
        let _data = rowData.isDefault === true ? aggregators : operators;

        const _name =
          _data[rowData.operatorId] && _data[rowData.operatorId].detail
            ? _data[rowData.operatorId].detail
            : rowData.operatorId;
        return (
          <>
            {NameIdTemplate(
              _name,
              rowData.operatorId,
              rowData?.operatorId === rowData.aggregatorId
            )}
          </>
        );

      case "localDate":
        return <>{formatDateString(rowData.localDate)}</>;
      case "logTimestamp":
        return <>{formatTimestamp(rowData.logTimestamp)}</>;
      default:
        return <>{rowData[field] ?? "-"}</>;
    }
  };

  // const poolKey = selectedRow?._reqData?.poolKey;
  // let _splitKey: string[] = [];
  // let _poolTrackInfo: string[] = [];
  // if (typeof poolKey === "string") {
  // _splitKey = poolKey?.split("~") ?? [];
  // _poolTrackInfo = _splitKey[1]?.split("-") ?? [];
  // }

  const handleChange = (e: ElementChangeEvent) => {
    const val = e.data.toLowerCase();
    setSearch(e.data);
    initializeOptions(transaction);
    const _filteredData = [...transaction].filter((c) => {
      let aggregator = aggregators[c.aggregatorId]?.detail;
      let operator = operators[c.operatorId]?.detail;
      let client = c.clientId;

      const isValid =
        c.aggregatorId?.toLowerCase().startsWith(val) ||
        c.operatorId?.toLowerCase().startsWith(val) ||
        aggregator?.toLowerCase().startsWith(val) ||
        operator?.toLowerCase().startsWith(val) ||
        client?.toLowerCase().startsWith(val) ||
        c.roundId?.toLowerCase().startsWith(val) ||
        c.methodType.toLowerCase().startsWith(val) ||
        c.aggregatorId?.toLowerCase().includes(val) ||
        c.operatorId?.toLowerCase().includes(val) ||
        aggregator?.toLowerCase().includes(val) ||
        operator?.toLowerCase().includes(val) ||
        client?.toLowerCase().includes(val) ||
        c.roundId?.toLowerCase().includes(val) ||
        c.methodType?.toLowerCase().includes(val) ||
        formatTimestamp(c.logTimestamp).includes(val) ||
        formatTimestamp(c.logTimestamp).startsWith(val);

      return isValid;
    });
    setFilteredTransactions(_filteredData);
  };

  const initializeOptions = useCallback((transaction: TransactionDTO[]) => {
    const _methodType = objToStringList(transaction, "methodType");
    const _aggregator = objToStringList(transaction, "aggregatorId");
    const _operator = objToStringList(transaction, "operatorId");
    setFilterData({
      ...filterData,
      methodType: { ...filterData.user, options: _methodType },
      aggregator: { ...filterData.user, options: _aggregator },
      operator: { ...filterData.user, options: _operator },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initializeOptions(transaction);
    handleChange({ data: search, name: "" });
    // setFilteredTransactions(transaction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction]);

  const handleFilter = () => {
    setFilterShow(!filterShow);
  };

  const handleFilterChange = (e: ElementChangeEvent) => {
    let _filter = { ...filterData };
    _filter = { ..._filter, [e.name]: e.data };
    setFilterData(_filter);
  };

  const onFilterClick = () => {
    setSearch("");
    // debugger;
    const _methodTypeFilter = filterData[FILTER.methodType];
    const _operatorFilter = filterData[FILTER.operator];
    const _aggregatorFilter = filterData[FILTER.aggregator];

    let _filteredTransactions: TransactionDTO[] = [];
    for (const trans of transaction) {
      if (
        _methodTypeFilter &&
        _methodTypeFilter?.selected?.length &&
        !_methodTypeFilter?.selected?.includes(trans.methodType)
      )
        continue;
      if (
        _operatorFilter &&
        _operatorFilter?.selected?.length &&
        !_operatorFilter?.selected?.includes(trans.operatorId)
      )
        continue;
      if (
        _aggregatorFilter &&
        _aggregatorFilter?.selected?.length &&
        !_aggregatorFilter?.selected?.includes(trans.aggregatorId)
      )
        continue;
      _filteredTransactions.push(trans);
    }

    setFilteredTransactions(_filteredTransactions);
  };

  const onClearClick = () => {
    setSearch("");
    initializeOptions(transaction);
    setFilteredTransactions(transaction);
  };

  const filterSearch = (e: ElementChangeEvent, type?: string) => {
    switch (type) {
      case FILTER.aggregator:
        const filterResult = FilterHelper.Aggregator(
          e.data,
          transaction,
          aggregators
        );
        const _aggregators = objToStringList(filterResult, "aggregatorId");

        setFilterData({
          ...filterData,
          aggregator: { ...filterData.aggregator, options: _aggregators },
        });
        break;
      case FILTER.operator:
        const _filterResult = FilterHelper.Operator(
          e.data,
          transaction,
          operators
        );
        const _operator = objToStringList(_filterResult, "operatorId");

        setFilterData({
          ...filterData,
          operator: { ...filterData.operator, options: _operator },
        });
        break;
      case FILTER.methodType:
        const methodRes = FilterHelper.MethodType(e.data, transaction);
        const _methodType = objToStringList(methodRes, "methodType");

        setFilterData({
          ...filterData,
          methodType: { ...filterData.methodType, options: _methodType },
        });
        break;

      default:
        break;
    }
  };

  return (
    <div>
      <header className={styles.headerWrapper1}>
        <div className={styles.menuIcon}>
          <FiMenu
            onClick={() => setIsOpen(!isOpen)}
            size={40}
            className="burgerMenuIcon"
          />
        </div>
        {REPORT_TAB.map((tab, key) => {
          const isActive = path.startsWith(tab.href);
          if (tab.roles?.includes(authUser?.role ?? ""))
            return (
              <FlatButton
                key={key}
                label={tab.label}
                className={`${styles.iconSet} ${isActive && styles.activeLink}`}
                onClick={() => handleRoute(tab.href)}
              />
            );
          return null;
        })}
      </header>

      <ReportHeader
        selectedDate={selectedDate.startDate}
        onSubmit={onSubmit}
        onDateChange={(date) => handleDateChange(date, "startDate")}
        InputChange={handleChange}
        onFilter={handleFilter}
        search={search}
        onEndDateChange={(date) => handleDateChange(date, "endDate")}
        selectedEndDate={selectedDate.endDate}
        exportData={filteredTransactions}
        exportFileName={`Transaction_${formatDate(
          selectedDate.startDate
        )}_${formatDate(selectedDate.endDate)}`}
      />
      <Filter
        show={filterShow}
        filterData={filterData}
        onFilterCHange={handleFilterChange}
        onFilterClick={onFilterClick}
        onClearClick={onClearClick}
        onFilter={filterSearch}
      />
      <PrimeTable
        data={filteredTransactions}
        columns={TransactionLogColumns}
        ColumnBody={ColumnBody}
        onRowSelect={onRowClick}
        isLoading={loader}
      />
      <Dialog
        closeOnEsc
        hideCloseBtn={false}
        show={showReqResDialog}
        setShow={setShowReqResDialog}
        headerTemplate={reqResTemp}
        glassEffect={true}
      >
        <div className="gridJSON">
          <div className="colGrid">
            <h3>Request</h3>
            <JSONTree
              labelRenderer={([key]) => <strong>{key}</strong>}
              valueRenderer={(raw) => <em className="val">{raw}</em>}
              data={selectedRow._reqData}
              theme={theme}
            />
          </div>
          <div className="colGrid">
            <h3>Response</h3>
            <JSONTree
              labelRenderer={([key]) => <strong>{key}</strong>}
              valueRenderer={(raw) => <em className="val">{raw}</em>}
              data={selectedRow._resdata}
              theme={theme}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default TransactionLogPage;
