import { atom } from "recoil";
import { FDRowDTO } from "../common/models/dashborad.model";

export const AtomAllFDData = atom<FDRowDTO[]>({
  key: "atomAllFDData",
  default: [],
});

export const AtomFilteredFDData = atom<FDRowDTO[]>({
  key: "atomFilteredFDData",
  default: [],
});

export const AtomFDOptions = atom<{
  aggregators: string[];
  operators: string[];
}>({
  key: "atomFDOptions",
  default: {
    aggregators: [],
    operators: [],
  },
});
