import { FaFlag } from "react-icons/fa";
import { ElementChangeEvent } from "../types/ui.types";

export const toLocaleNumber = (n: number) => {
  return n?.toLocaleString() ?? n;
};

export const NameIdTemplate = (
  name: string,
  id: string,

  isDefault?: boolean | undefined,
  isFixedEnable?: boolean | undefined,
  isToteEnable?: boolean | undefined
) => {
  return (
    <div className="nameTemplate">
      <div className="name">
        {name ?? "-"}{" "}
        {isDefault ? (
          <div>
            <FaFlag style={{ paddingLeft: "0.25rem" }} />
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="section1">
        <div className="id">{id}</div>

        <div className="badgeDiv">
          {isFixedEnable ? <div className="pool">FO</div> : ""}
          {isToteEnable ? <div className="pool">TOTE</div> : ""}
        </div>
      </div>

      {/* <div className="id">{id}</div> */}
    </div>
  );
};

export const isLessWidth = (num?: number) => {
  const _num = num ?? 480;
  return window.innerWidth <= _num;
};

export const generateUniqueId = (
  name: string,
  callBack?: (e: ElementChangeEvent) => void
) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let randomOperatorId = "";
  for (let i = 0; i < 15; i++) {
    randomOperatorId += characters.charAt(
      Math.floor(Math.random() * charactersLength)
    );
  }
  if (callBack) callBack({ data: randomOperatorId, name });
  return randomOperatorId;
};

export const objToStringList = (data: any[], key: string) => {
  let values: string[] = [];

  for (const d of data) {
    if (d[key]) values.push(d[key]);
  }

  return Array.from(new Set(values));
};
