import { useState, KeyboardEvent, useEffect, useCallback } from "react";
import { GoDotFill } from "react-icons/go";
import { ElementChangeEvent } from "../../common/types/ui.types";
import FlatButton from "../../components/elements/buttons/flat.button";
import { IoWalletOutline } from "react-icons/io5";
import SearchBar from "../../components/ui/searchBar/searchBar";
import {
  AggregatorColumns,
  PoolType,
} from "../../common/constants/columns.constant";
import Dialog from "../../components/ui/dialog/dialog.ui";
import InputText from "../../components/elements/input/inputText.element";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  AtomAggregatorEntries,
  AtomAggregatorsObj,
} from "../../store/aggregator.store";
import Dropdown from "../../components/elements/dropdown/dropdown.element";
import { FaFilter, FaGlobe, FaPercent } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import { AggregatorDTO } from "../../common/models/aggregator.model";
import { API, API_MOD } from "../../common/config/api.config";
import { AtomAuthUser, AtomShowSidebar } from "../../store/auth.store";
import { toast } from "react-toastify";
import { MdEdit, MdPersonAddAlt1 } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FILTER, ROLES, STATUS } from "../../common/constants/general.constant";

import ConfirmDialog from "../../components/ui/dialog/confirmDialog";
import { FiMenu } from "react-icons/fi";
// import { AtomProvinces } from "../../store/dashboard.store";
import { checkError } from "../../common/helpers/validation.helper";
import { useNavigate } from "react-router";
import PrimeTable from "../../components/ui/table/primeTable.ui";
import { showToast } from "../../common/helpers/toast.helper";
import {
  generateUniqueId,
  NameIdTemplate,
} from "../../common/helpers/general.helper";
import styles from "./aggregatorManagement.module.scss";
import Filter from "../../components/elements/Filter/filter.element";
import { __FilterDTO } from "../../common/models/filter.modal";
import { AtomOperators } from "../../store/operator.store";
// import CustomMultiSelect from "../../components/elements/customMultiselect/customMultiselect.element";
// import { IoIosRadioButtonOn } from "react-icons/io";
import PoolsDialog from "../../components/ui/dialog/poolsDialog";
import { ColumnsDTO } from "../../common/types/ui.types";
// import { callbackify } from "util";

const InitFilter = {
  // [FILTER.environment]: { options: [], selected: [] },
  [FILTER.status]: { options: [], selected: [] },
};
//* OperatorManagementPage
const AggregatorManagementPage = () => {
  // VARIABLES
  const [searchTerm, setSearchTerm] = useState("");
  const [filterAggregators, setFilterAggregators] = useState<any[]>([]);
  const [show, setShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [filterShow, setFilterShow] = useState(false);
  const [selectedRow, setSelectedRow] = useState<AggregatorDTO>(
    {} as AggregatorDTO
  );
  const [filterData, setFilterData] = useState<__FilterDTO>(InitFilter);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [isOpen, setIsOpen] = useRecoilState(AtomShowSidebar);
  // const [showEditCreditDialog, setShowEditCreditDialog] = useState(false);
  const [showEditRebateRateDialog, setShowEditRebateRateDialog] =
    useState(false);
  const [editValue, setEditValue] = useState(null);
  const [showStatusDialog, setShowStatusDialog] = useState(false);
  // const [editCreditValue, setEditCreditValue] = useState(null);
  const [editRebateRateValue, setEditRebateRateValue] = useState(null);
  const [loader, setLoader] = useState(false);

  // RECOIL VARIABLES
  const authToken = useRecoilValue<any>(AtomAuthUser);
  const [aggregators, setAggregators] = useRecoilState(AtomAggregatorsObj);
  const [operators, setOperators] = useRecoilState(AtomOperators);
  const [aggregatorEntries, setAggregatorEntries] = useRecoilState(
    AtomAggregatorEntries
  );
  const [tableColumns, settableColumns] = useState<ColumnsDTO[]>([]);
  // const currencies = useRecoilValue(AtomProvinces);
  const navigate = useNavigate();
  const [showPoolsDialog, setShowPoolsDialog] = useState(false);

  const [selectedAggregator, setSelectedAggregator] = useState<AggregatorDTO>();

  // const [selectedPool, setSelectedPOol] = useState({
  //   isFixedEnabled: false,
  //   isToteEnable: false,
  // });

  // const setOperators = useSetRecoilState(AtomOperators);

  // DROPDOWN OPTIONS
  // const currency = ["USD", "HKD", "AU"];

  // CALLBACKS

  useEffect(() => {
    setFilterAggregators(Object.values(aggregators));

    console.log(aggregators);
  }, [aggregators]);

  const initializeOptions = useCallback(() => {
    const _status = [STATUS.ACTIVE, STATUS.INACTIVE];
    // const _environment = [STATUS.LIVE, STATUS.SIM];
    setFilterData({
      ...filterData,
      [FILTER.status]: { ...filterData.status, options: _status },
      // [FILTER.environment]: {
      //   ...filterData.environment,
      //   options: _environment,
      // },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aggregators]);

  useEffect(() => {
    initializeOptions();
    setFilterAggregators(Object.values(aggregators));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilterChange = (e: ElementChangeEvent) => {
    let _filter = { ...filterData };
    _filter = { ..._filter, [e.name]: e.data };
    setFilterData(_filter);
  };

  const onFilterClick = () => {
    const _statusFilter = filterData[FILTER.status];
    // const _envFilter = filterData[FILTER.environment];
    let _filteredAggregator: AggregatorDTO[] = [];
    let _agg = Object.values(aggregators);
    for (const aggregator of _agg) {
      const env = aggregator.environment === true ? STATUS.LIVE : STATUS.SIM;
      if (
        _statusFilter &&
        _statusFilter?.selected?.length &&
        !_statusFilter?.selected?.includes(aggregator.status)
      )
        continue;
      // if (
      //   _envFilter &&
      //   _envFilter?.selected?.length &&
      //   !_envFilter?.selected?.includes(env)
      // )
      //   continue;
      _filteredAggregator.push(aggregator);
    }
    setFilterAggregators(_filteredAggregator);
    setSearchTerm("");
  };

  const onClearClick = () => {
    setSearchTerm("");
    initializeOptions();
    setFilterAggregators(Object.values(aggregators));
  };

  useEffect(() => {
    let _oddsAccessColumn = {
      field: "oddsAccess",
      header: "Odds Access",
      sortable: false,
    };

    let _coloumnIndex = AggregatorColumns?.findIndex((x) => {
      return x?.field === _oddsAccessColumn?.field;
    });

    console.log(_coloumnIndex);
    let _cols = [...AggregatorColumns];
    if (
      authToken?.role === ROLES.ADMIN &&
      _cols[_coloumnIndex]?.field !== _oddsAccessColumn?.field
    ) {
      _cols.splice(4, 0, _oddsAccessColumn);
    }
    settableColumns(_cols);
  }, []);

  // GENERAL FUNCTIONS
  const onAggregatorSave = () => {
    console.log(aggregatorEntries);
    setLoader(true);
    if (!handleValidation()) return;
    let _aggregatorsObj: any = { ...aggregatorEntries };
    _aggregatorsObj["environment"] =
      _aggregatorsObj["environment"] === STATUS.LIVE
        ? true
        : _aggregatorsObj["environment"] === STATUS.SIM
        ? false
        : "-";

    _aggregatorsObj["rebateRate"] = Number(aggregatorEntries.rebateRate);
    _aggregatorsObj["originUrls"] = [aggregatorEntries.originUrls.toString()];

    API.post(`${API_MOD.AGGREGATOR}/add`, _aggregatorsObj, {
      headers: {
        Authorization: `${authToken.token}`,
      },
    })
      .then((res) => {
        if (res) {
          // _aggregatorsObj["aggregatorId"] = res.data;
          setAggregators({
            ...aggregators,
            [_aggregatorsObj.aggregatorId]: _aggregatorsObj,
          });

          const _operators = { ...operators };

          _operators[_aggregatorsObj.aggregatorId] = {
            ..._aggregatorsObj,
            detail: _aggregatorsObj.detail,
            operatorId: _aggregatorsObj.aggregatorId,
            currency: _aggregatorsObj.currency,
            isDefault: true,
          };
          setOperators(_operators);
          setFilterAggregators([...filterAggregators, _aggregatorsObj]);
          setShow(false);
          setLoader(false);
          setAggregatorEntries({} as AggregatorDTO);
          // setAgents([OperatorObj as unknown as AgentDTO, ...agents]);
        }
        showToast("success", "Aggregator added successfully..!");
      })
      .catch((error) => {
        checkError(error, true, navigate);
      });
  };
  const onClose = () => {
    setShow(false);
    setAggregatorEntries({} as AggregatorDTO);
    setErrorMessage("");
    setShowEditDialog(false);
    // setShowEditCreditDialog(false);
    setShowEditRebateRateDialog(false);
    setShowDeleteConfirm(false);
    setShowStatusDialog(false);
  };

  const handleMenuOpen = () => {
    setIsOpen(!isOpen);
  };

  const updateAggregatorPool = (val: AggregatorDTO) => {
    let _aggregatorsObj: any = { ...val };

    setAggregators({
      ...aggregators,
      [_aggregatorsObj.aggregatorId]: _aggregatorsObj,
    });
    setFilterAggregators([...filterAggregators, _aggregatorsObj]);

    setShowPoolsDialog(false);
  };

  const handlePoolAccess = (rowData: AggregatorDTO) => {
    setShowPoolsDialog(true);
    setSelectedAggregator(rowData);
  };

  const ColumnBody = (
    rowData: AggregatorDTO | any,
    { field, rowIndex }: any
  ) => {
    switch (field) {
      case "Sr_no":
        return <>{++rowIndex}</>;
      case "detail":
        return NameIdTemplate(rowData.detail, rowData.aggregatorId);
      case "environment":
        return (
          <>
            {rowData?.environment === true
              ? STATUS.LIVE
              : rowData?.environment === false
              ? STATUS.SIM
              : "-"}
          </>
        );
      case "callBackUrl":
        return (
          <>
            <div className={styles.editBtnWpr}>
              {rowData?.callBackUrl}
              <button
                className={styles.editBtn}
                onClick={() => handleEditDialog(rowData)}
              >
                <MdEdit size={15} />
              </button>
            </div>
          </>
        );

      case "rebateRate":
        return (
          <>
            <div className={`${styles.editBtnWpr} ${styles.alignRight}`}>
              {rowData?.rebateRate
                ? `${rowData.rebateRate?.toFixed(2)} %`
                : "-"}
              <button
                className={styles.editBtn}
                onClick={() => handleRebateRateEditDialog(rowData)}
              >
                <MdEdit size={15} />{" "}
              </button>
            </div>
          </>
        );
      case "oddsAccess":
        return (
          <>
            <div className={styles.poolBadges}>
              <div
                className={styles.badge}
                onClick={() => handlePoolAccess(rowData)}
              >
                <GoDotFill
                  size={20}
                  color={rowData?.isFixedEnable ? "rgb(80, 216, 59)" : "grey"}
                />
                <span>FIXED</span>
              </div>
              <div
                className={styles.badge}
                onClick={() => handlePoolAccess(rowData)}
              >
                <GoDotFill
                  size={20}
                  color={rowData?.isToteEnable ? "rgb(80, 216, 59)" : "grey"}
                />
                <span>TOTE</span>
              </div>
            </div>
          </>
        );

      case "status":
        return (
          <>
            <div
              className={styles.statusWrapper}
              onClick={() => handleStatusChange(rowData)}
            >
              <GoDotFill size={20} className={rowData.status} />
              <FlatButton className={styles.statusBtn}>
                {rowData.status}
              </FlatButton>
            </div>
          </>
        );
      case "action":
        if (authToken?.aggregatorId === rowData.aggregatorId) return null;
        return (
          <>
            <FlatButton
              className={styles.actionBtn}
              onClick={() => handleConfirmDelete(rowData)}
              size="xs"
              severity="warning"
              isDisable={authToken?.aggregatorId === rowData.aggregatorId}
            >
              <RiDeleteBin6Line />
            </FlatButton>
          </>
        );
      default:
        return <>{rowData[field] ?? "-"}</>;
    }
  };

  const handleStatusChange = (rowData: AggregatorDTO) => {
    setShowStatusDialog(true);
    setSelectedRow(rowData);
  };

  const handleValidation = () => {
    // let expression =
    //   /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

    // var regex = new RegExp(expression);
    if (!aggregatorEntries.aggregatorId) {
      setErrorMessage("Aggregator Id should Not Be Empty!!");
      return false;
    }
    if (!aggregatorEntries.detail) {
      setErrorMessage("Aggregator details should Not Be Empty!!");
      return false;
    }
    if (!aggregatorEntries.callBackUrl) {
      setErrorMessage("Callback Url Not Be Empty!!");
      return false;
    }
    // if (!operatorEntries.callBackUrl.match(regex)) {
    //   setErrorMessage("Please enter correct URL");
    //   return false;
    // }
    // if (!operatorEntries?.creditLimit) {
    //   setErrorMessage("Credit Limit should Not Be Empty!!");
    //   return false;
    // }
    if (!aggregatorEntries?.environment) {
      setErrorMessage("Environment should be selected!!");
      return false;
    }
    if (!aggregatorEntries?.status) {
      setErrorMessage("Status should be selected!!");
      return false;
    }
    // if (!aggregatorEntries?.currency) {
    //   setErrorMessage("Currency should be selected!!");
    //   return false;
    // }
    if (!aggregatorEntries?.rebateRate) {
      setErrorMessage("Rebate rate should not be empty");
      return false;
    }
    if (!aggregatorEntries?.originUrls) {
      setErrorMessage("Origin URL should Not Be Empty!!");
      return false;
    }
    if (
      !aggregatorEntries?.isFixedEnable &&
      !aggregatorEntries.isToteEnable &&
      authToken.role === ROLES.ADMIN
    ) {
      setErrorMessage("Please Select Pool Type");
      return false;
    }
    // if (!operatorEntries.originUrls[0].match(regex)) {
    //   setErrorMessage("Please enter correct URL");
    //   return false;
    // }
    return true;
  };
  const dialogHeader = () => {
    return <h1>Add Aggregator</h1>;
  };

  // CHANGE FUNCTIONS
  const onChange = (e: ElementChangeEvent) => {
    initializeOptions();
    const value = e.data.toLowerCase();
    setSearchTerm(e.data);
    const _data = Object.values(aggregators);
    const filterData = _data.filter((aggregator) => {
      return (
        aggregator.detail?.toLowerCase().startsWith(value) ||
        aggregator.aggregatorId?.toLowerCase().startsWith(value) ||
        aggregator.callBackUrl?.toLowerCase().startsWith(value) ||
        aggregator.callBackUrl?.toLowerCase().includes(value) ||
        aggregator.callBackUrl?.toString().toLowerCase().startsWith(value) ||
        aggregator.callBackUrl?.toString().toLowerCase().includes(value) ||
        aggregator.aggregatorId?.toString().toLowerCase().includes(value) ||
        aggregator.detail?.toString().toLowerCase().includes(value)
      );
    });

    setFilterAggregators(filterData);
  };

  const onStatusChange = () => {
    let selectedOperator = { ...selectedRow };
    if (!selectedOperator) return;
    selectedOperator.status =
      selectedOperator.status === "ACTIVE" ? "INACTIVE" : "ACTIVE";
    // * update states

    API.post(
      `${API_MOD.AGGREGATOR}/status`,
      {
        aggregatorId: selectedOperator.aggregatorId,
        status: selectedOperator.status,
      },
      {
        headers: {
          Authorization: authToken?.token ?? "",
        },
      }
    )
      .then((response) => {
        let _filter = [...filterAggregators];
        const _i = _filter.findIndex(
          (x) => x.aggregatorId === selectedRow?.aggregatorId
        );
        _filter.splice(_i, 1, selectedOperator);
        let _operator = { ...aggregators };
        // let i = _operator.findIndex(
        //   (x) => x.aggregatorId === selectedRow?.aggregatorId
        // );
        // _operator.splice(i, 1, selectedOperator);
        _operator[selectedOperator.aggregatorId] = selectedOperator;
        setFilterAggregators(_filter);
        setAggregators(_operator);
        setShowStatusDialog(false);
        showToast("success", "Status changed successfully..!");
      })
      .catch((error) => {
        checkError(error, true, navigate);
      });
  };
  const handleChange = (val: ElementChangeEvent, otherData?: any) => {
    setErrorMessage("");
    const { data, name } = val;
    setAggregatorEntries({ ...aggregatorEntries, [name]: otherData ?? data });
  };

  const handleKeyChange = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === "Enter") {
      onAggregatorSave();
    }
  };

  const onDelete = (rowData: AggregatorDTO | null) => {
    if (!rowData) return;
    API.post(
      `${API_MOD.AGGREGATOR}/remove`,
      { aggregatorId: rowData?.aggregatorId },

      { headers: { Authorization: authToken?.token ?? "" } }
    )
      .then((response) => {
        if (response) {
          let _operatorList = { ...aggregators };
          // let _index = _operatorList.findIndex(
          //   (val) => val.operatorId === rowData?.operatorId
          // );
          // _operatorList.splice(_index, 1);
          delete _operatorList[rowData?.aggregatorId];
          setFilterAggregators(Object.values(_operatorList));
          setAggregators(_operatorList);
          showToast("success", "Aggregator Deleted successfully.");
        }
      })
      .catch((error) => {
        console.log(error, "error");
        checkError(error, true, navigate);
      });
    setShowDeleteConfirm(false);
  };

  const handleConfirmDelete = (rowData: AggregatorDTO) => {
    // setOperatorId(rowData.operatorId);
    setShowDeleteConfirm(true);
    setSelectedRow(rowData);
  };

  // EDIT FUNCTIONS
  const editCallbackURLHeader = () => {
    return <h1 className={styles.dialogHeader}>Edit Callback URL</h1>;
  };

  // const editCreditLimitHeader = () => {
  //   return <h1 className={styles.dialogHeader}>Edit Credit Limit</h1>;
  // };

  const onEditCallbackURL = (rowData: AggregatorDTO | null) => {
    API.post(
      `${API_MOD.AGGREGATOR}/update`,
      {
        callBackUrl: editValue ?? rowData?.callBackUrl,
        aggregatorId: rowData?.aggregatorId,
      },
      {
        headers: {
          Authorization: `${authToken.token}`,
        },
      }
    )
      .then((res) => {
        if (res) {
          let _res = res?.data;
          if (_res) {
            let _operators = { ...aggregators };
            let _operatorObj: AggregatorDTO | any = { ...rowData };
            _operatorObj["callBackUrl"] = _res.callBackUrl;
            // let _index = _operatorList.findIndex((val) => {
            //   return val.operatorId === _res.refId;
            // });
            // _operatorList.splice(_index, 1, _operatorObj);
            _operators[_operatorObj.aggregatorId] = _operatorObj;
            setFilterAggregators(Object.values(_operators));
            setAggregators(_operators);
            setShowEditDialog(false);
            toast.success(
              `Callback URL update successfully for ${selectedRow?.aggregatorId}..!`,
              {
                bodyClassName: "text",
                className: "text",
                position: "bottom-right",
              }
            );
          }
        }
      })
      .catch((error) => {
        console.log(error, "error");
        checkError(error, true, navigate);
      });
  };

  const handleEditDialog = (rowData: AggregatorDTO) => {
    setSelectedRow(rowData);
    setShowEditDialog(true);
  };

  const handleRebateRateEditDialog = (rowData: AggregatorDTO) => {
    setSelectedRow(rowData);
    setShowEditRebateRateDialog(true);
  };

  const onEditRebateRate = (rowData: AggregatorDTO | null) => {
    API.post(
      `${API_MOD.OPERATOR}/rebateRate`,
      {
        rebateRate: editRebateRateValue ?? rowData?.rebateRate,
        operatorId: rowData?.aggregatorId,
        aggregatorId: rowData?.aggregatorId,
      },
      {
        headers: {
          Authorization: `${authToken.token}`,
        },
      }
    )
      .then((res) => {
        if (res) {
          let _res = res?.data;
          if (_res) {
            let _operatorList = { ...aggregators };
            let _operatorObj: AggregatorDTO | any = { ...rowData };
            _operatorObj["rebateRate"] = _res.rebateRate;
            // let _index = _operatorList.findIndex((val) => {
            //   return val.operatorId === _res.agentId;
            // });
            // _operatorList.splice(_index, 1, _operatorObj);
            _operatorList[_operatorObj.aggregatorId] = _operatorObj;
            setFilterAggregators(Object.values(_operatorList));
            setAggregators(_operatorList);
            setShowEditRebateRateDialog(false);
            showToast(
              "success",
              `Rebate rate update successfully for ${selectedRow?.aggregatorId}..!`
            );
          }
        }
      })
      .catch((error) => {
        console.log(error, "error");
        checkError(error, true, navigate);
      });
  };

  const editRebateRateHeader = () => {
    return <h1 className={styles.dialogHeader}>Edit Rebate Rate</h1>;
  };

  return (
    <>
      <div>
        <header className={"headerWrapper"}>
          <span className={styles.iconNameWrp}>
            <FiMenu
              onClick={handleMenuOpen}
              size={40}
              className="burgerMenuIcon"
            />
            <span className={styles.nameWrp}>Aggregator Management</span>
          </span>
          <div className={styles.searchWrapper}>
            <FlatButton
              className={styles.filterIcon}
              onClick={() => setFilterShow(!filterShow)}
            >
              <FaFilter size={20} />
            </FlatButton>
            <SearchBar onChange={onChange} value={searchTerm} />
            <FlatButton
              icon={<MdPersonAddAlt1 size={20} />}
              label={window.innerWidth >= 480 ? "Add Aggregator" : ""}
              severity="light"
              className="addBtn"
              onClick={() => setShow(true)}
            ></FlatButton>
          </div>
        </header>
        <Filter
          show={filterShow}
          filterData={filterData}
          onFilterCHange={handleFilterChange}
          onFilterClick={onFilterClick}
          onClearClick={onClearClick}
        />
        <ConfirmDialog
          message={`Are you sure you want to delete this Aggregator ${
            selectedRow?.aggregatorId ?? ""
          } ?`}
          onConfirm={() => onDelete(selectedRow)}
          show={showDeleteConfirm}
          setShow={setShowDeleteConfirm}
          title="Delete Aggregator ?"
          confirmSeverity="danger"
        />

        {/* STATUS */}
        <div className={styles.actionWrapper}>
          <div className={styles.btnWrapper}>
            <ConfirmDialog
              message={`Are you sure you want to change the status of aggregator ${
                selectedRow?.aggregatorId ?? ""
              } ?`}
              title="Change Status"
              show={showStatusDialog}
              onConfirm={onStatusChange}
              setShow={setShowStatusDialog}
              onClose={onClose}
            />
          </div>
        </div>

        <div className={styles.actionWrapper}>
          <div className={styles.btnWrapper}>
            <Dialog
              closeOnEsc
              hideCloseBtn={false}
              show={showEditDialog}
              setShow={setShowEditDialog}
              headerTemplate={editCallbackURLHeader}
              glassEffect={true}
            >
              <InputText
                name="callBackUrl"
                className={styles.input}
                type="text"
                label="Callback URL"
                labelClass={styles.label}
                onChange={(e) => setEditValue(e.data)}
                placeholder="Enter URL Here"
                Icon={<FaGlobe size={20} />}
                defaultValue={selectedRow?.callBackUrl}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    onEditCallbackURL(selectedRow);
                  }
                }}
              />
              <div className={styles.buttonDiv}>
                <FlatButton
                  className={styles.dialogButton}
                  onClick={() => onEditCallbackURL(selectedRow)}
                  label="UPDATE"
                />
                <FlatButton
                  className={styles.dialogButton}
                  label="CANCEL"
                  onClick={onClose}
                />
              </div>
            </Dialog>
          </div>
        </div>

        <div className={styles.actionWrapper}>
          <div className={styles.btnWrapper}>
            <Dialog
              closeOnEsc
              hideCloseBtn={false}
              show={showEditRebateRateDialog}
              setShow={setShowEditRebateRateDialog}
              headerTemplate={editRebateRateHeader}
              glassEffect={true}
            >
              <InputText
                name="rebateRate"
                className={styles.input}
                type="number"
                label="Rebate Rate"
                labelClass={styles.label}
                onChange={(e) => setEditRebateRateValue(e.data)}
                placeholder="Enter Rebate Rate"
                Icon={<FaPercent size={20} />}
                defaultValue={selectedRow?.rebateRate}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    onEditRebateRate(selectedRow);
                  }
                }}
              />
              <div className={styles.buttonDiv}>
                <FlatButton
                  className={styles.dialogButton}
                  onClick={() => onEditRebateRate(selectedRow)}
                  label="UPDATE"
                />
                <FlatButton
                  className={styles.dialogButton}
                  label="CANCEL"
                  onClick={onClose}
                />
              </div>
            </Dialog>
          </div>
        </div>
        <Dialog
          closeOnEsc
          hideCloseBtn={false}
          show={show}
          setShow={setShow}
          headerTemplate={dialogHeader}
          glassEffect={true}
        >
          <div className={styles.dialogBody}>
            <div className={styles.GridWrapper}>
              <div className={styles.section1}>
                <div className={styles.generateIdAWrap}>
                  <InputText
                    name="aggregatorId"
                    className={styles.input}
                    type="text"
                    label="Aggregator ID"
                    labelClass={styles.label}
                    placeholder="Enter Aggregator ID"
                    onChange={handleChange}
                    value={aggregatorEntries.aggregatorId}
                    Icon={<CgProfile size={20} />}
                  />

                  <button
                    className={styles.generatebutton}
                    onClick={() =>
                      generateUniqueId("aggregatorId", handleChange)
                    }
                  >
                    {" "}
                    Generate
                  </button>
                </div>

                {/* <FlatButton onClick={generateId}/> */}

                <InputText
                  name="detail"
                  className={styles.input}
                  type="text"
                  label="Aggregator detail"
                  labelClass={styles.label}
                  placeholder="Enter Aggregator details"
                  onChange={handleChange}
                  Icon={<CgProfile size={20} />}
                />
                <InputText
                  name="callBackUrl"
                  className={styles.input}
                  type="text"
                  label="Callback URL"
                  labelClass={styles.label}
                  onChange={handleChange}
                  placeholder="Enter URL Here"
                  Icon={<FaGlobe size={20} />}
                />

                <Dropdown
                  options={[STATUS.SIM, STATUS.LIVE]}
                  selectedValue={aggregatorEntries?.environment}
                  name="environment"
                  label="Environment"
                  placeholder="Select Environment"
                  onChange={handleChange}
                />
                <Dropdown
                  options={[STATUS.ACTIVE, STATUS.INACTIVE]}
                  selectedValue={aggregatorEntries.status}
                  name="status"
                  label="Select Status"
                  placeholder="Select Status"
                  onChange={handleChange}
                />
                {/* <Dropdown
                  options={currencies ?? []}
                  selectedValue={aggregatorEntries.currency}
                  name="currency"
                  label="Select Currency"
                  placeholder="Select Currency"
                  onChange={(e) => handleChange(e, e.data.currency)}
                /> */}
                <InputText
                  name="rebateRate"
                  type="number"
                  label="Rebate Rate"
                  labelClass={styles.label}
                  onChange={handleChange}
                  placeholder="Enter in Percent"
                  Icon={<IoWalletOutline size={20} />}
                  onKeyUp={handleKeyChange}
                />
                <InputText
                  name="originUrls"
                  className={styles.input}
                  type="text"
                  label="Origin URL"
                  labelClass={styles.label}
                  onChange={handleChange}
                  placeholder="Enter URL Here"
                  Icon={<FaGlobe size={20} />}
                  onKeyUp={handleKeyChange}
                />
                <div className={styles.multiselectButtons}>
                  {authToken.role === ROLES.ADMIN
                    ? PoolType.map((pool, key) => {
                        return (
                          <button
                            className={`${styles.multiSelectButton} ${
                              aggregatorEntries[pool.id] ? styles.active : ""
                            }`}
                            onClick={(e) =>
                              handleChange({
                                data: !aggregatorEntries[pool.id],
                                name: pool.id,
                              })
                            }
                            key={key}
                          >
                            <div className={styles.buttonContent}>
                              <span>
                                <input
                                  type="checkbox"
                                  checked={aggregatorEntries[pool.id]}
                                />
                              </span>
                              <span>{pool.label}</span>
                            </div>
                          </button>
                        );
                      })
                    : ""}
                </div>
              </div>
              <div className="errorMsg">{errorMessage}</div>
              <div className={styles.buttonDiv}>
                <FlatButton
                  className={styles.dialogButton}
                  onClick={onAggregatorSave}
                  label="SAVE"
                />
                <FlatButton
                  className={styles.dialogButton}
                  label="CANCEL"
                  onClick={onClose}
                />
              </div>
            </div>
          </div>
        </Dialog>

        <PoolsDialog
          title="Odds Access"
          show={showPoolsDialog}
          glassEffect
          onClose={() => setShowPoolsDialog(false)}
          selectedRow={selectedAggregator}
          updatePool={updateAggregatorPool}
          closeOnEsc
        />

        <PrimeTable
          data={filterAggregators}
          columns={tableColumns}
          ColumnBody={ColumnBody}
          isLoading={loader}
        />
      </div>
    </>
  );
};

export default AggregatorManagementPage;
