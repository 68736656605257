import { DateTimeFormatter } from "@js-joda/core";

export const FORMAT = {
  yyyyMMdd: "yyyyMMdd",
  ddMMyyyy_slash: "dd/MM/yyyy",
  mmddyyyy_slash: "MM/dd/yyyy",
  ddMMyyyy_dash: "dd-MM-yyyy",
  ddMyyyy_space: "dd M, yyyy",
  yyyyMMddHHmmss: "yyyyMMddHHmmss",
  ddMMyyyyHHmmss_dash: "dd-MM-yyyy HH:mm:ss",
};

export const DATE_FORMAT = {
  yyyyMMdd: DateTimeFormatter.ofPattern(FORMAT.yyyyMMdd),
  yyyyMMddHHmmss: DateTimeFormatter.ofPattern(FORMAT.yyyyMMddHHmmss),
};
