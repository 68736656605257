import { AggregatorDTO } from "./../models/aggregator.model";
import { API, API_MOD } from "../config/api.config";
import { getHeader } from "../helpers/apis.helper";
import { __AggregatorDTO } from "../types/dynamic.types";

interface AggregatorParams {
  setAggregators: (obj: any) => void;
  authUser: any;
}
export const AggregatorService = () => {
  const getAggregator = ({ authUser, setAggregators }: AggregatorParams) => {
    API.get(`${API_MOD.AGGREGATOR}/fetch/${authUser?.aggregatorId}`, {
      headers: getHeader(),
    })
      .then((res) => {
        // console.log("res", res);
        let _data: AggregatorDTO = res?.data;
        let dataObj: __AggregatorDTO = {};
        dataObj[_data?.aggregatorId] = _data;
        setAggregators(dataObj);
      })
      .catch((err) => {
        console.log({ aggregatorErr: err });
      });
  };
  return { getAggregator };
};
