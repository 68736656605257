import { memo } from "react";
import { FaSearch } from "react-icons/fa";
import { ElementChangeEvent } from "../../../common/types/ui.types";
import styles from "./searchBar.module.scss";

export interface SearchBarProps {
  onChange: (e: ElementChangeEvent) => void;
  value: string | undefined;
  className?: string;
}

export const SearchBar = memo(({ ...props }: SearchBarProps) => {
  return (
    <div className={`${styles.searchBarWrp} ${props.className}`}>
      <div className={styles.inputWrp}>
        <FaSearch className={styles.searchIcon} />
        <input
          value={props?.value}
          type="text"
          placeholder="Search here..."
          onChange={(e) =>
            props.onChange && props.onChange({ data: e.target.value, name: "" })
          }
        />
      </div>
    </div>
  );
});
SearchBar.displayName = "SearchBar";
export default SearchBar;
