import { ReactNode, MouseEvent, CSSProperties } from "react";
import { FaSpinner } from "react-icons/fa";
import { Severity, Sizes } from "../../../common/types/ui.types";

// import Loader from "../loader/loader.element";
import styles from "../buttons/_buttons.module.scss";
interface FlatButtonProps {
  label?: string;
  children?: ReactNode;
  severity?: Severity;
  className?: string;
  style?: CSSProperties;
  isLoading?: boolean;
  isDisable?: boolean;
  size?: Sizes;
  icon?: any;
  iconPos?: "left" | "right";

  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
}
const FlatButton = ({ children, ...props }: FlatButtonProps) => {
  const LabelTemplate = () => {
    if (props.isLoading) return <FaSpinner className={styles.spin} />;
    return (
      <>
        {props.icon && <span className={styles.icon}>{props.icon}</span>}{" "}
        {children ?? props.label}
      </>
    );
  };

  return (
    <button
      style={props.style}
      className={`${props.className && props.className} ${styles.button} ${
        styles[props.size ?? "default"]
      } ${styles.flatBtn} ${styles[props.severity ?? "primary"]} ${
        props.isDisable && styles.disabled
      } ${props.isLoading && styles.loading}`}
      onClick={props.onClick && props.onClick}
      disabled={props.isDisable && props.isDisable}
    >
      {LabelTemplate()}
    </button>
  );
};

export default FlatButton;
