import { memo, MouseEvent } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  ColumnBodyOptions,
  ColumnsDTO,
  PrimeTableClickDTO,
} from "../../../common/types/ui.types";
import { NonResizableCols } from "../../../common/data/options.data";
// import Loader from "../../elements/loader/loader.element";
import styles from "./primetable.module.scss";
import Loader from "../../elements/loader/loader.element";

interface PrimeTableProps {
  data: any[];
  columns: ColumnsDTO[];
  rows?: number;
  ColumnBody?: (data: any, options: ColumnBodyOptions) => React.ReactNode;
  onRowClick?: (e: {
    originalEvent: MouseEvent<HTMLElement>;
    index: number;
  }) => void;
  onRowSelect?: (e: PrimeTableClickDTO) => void;
  selectionMode?: "single";
  isLoading?: boolean;
}

const PrimeTable = memo((props: PrimeTableProps) => {
  return (
    <div className={styles.tableDiv}>
      <Loader show={props.isLoading} className={styles.loader} />
      <DataTable
        value={props.data}
        resizableColumns
        columnResizeMode="fit"
        showGridlines
        // responsiveLayout="scroll"
        className={!props.onRowSelect ? `primeTable` : "primeTable rowHover"}
        paginator={props.data.length > 20}
        onRowClick={props.onRowClick}
        emptyMessage={
          props.data.length === 0 && !props.isLoading ? (
            <div className={styles.noData}>No Data Available.</div>
          ) : (
            <div className={styles.noData}></div>
          )
        }
        onSelectionChange={props.onRowSelect}
        rows={props.rows ?? 20}
        selectionMode={props.onRowSelect && "single"}
        removableSort
        responsiveLayout="scroll"
        // sortOrder={-1}
        // loading={props.isLoading}
      >
        {props.columns.map((col, key) => {
          const isResize = !NonResizableCols.includes(col.field);
          return (
            <Column
              key={key}
              body={props.ColumnBody}
              resizeable={isResize}
              field={col.field}
              header={col.header}
              style={col.style}
              sortable={col.sortable}
            />
          );
        })}
      </DataTable>
    </div>
  );
});

PrimeTable.displayName = "PrimeTable";
export default PrimeTable;
