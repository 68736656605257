import { memo, useEffect } from "react";
import { Severity } from "../../../common/types/ui.types";
import FlatButton from "../../elements/buttons/flat.button";
import styles from "./dialog.module.scss";

//* ConfirmDialog Prop Types
interface ConfirmDialogProps {
  headerTemplate?: any;
  title: string;
  message: string;
  hideCloseBtn?: boolean;
  show: boolean;
  preventBackdropClose?: boolean;
  preventEsc?: boolean;
  setShow: (b: boolean) => void;
  noGlassEffect?: boolean;
  className?: string;
  confirmLabel?: string;
  confirmSeverity?: Severity;
  cancelLabel?: string;
  onConfirm: () => void;
  onClose?: () => void;
}

//* ConfirmDialog
const ConfirmDialog = memo(
  ({ setShow, show, preventEsc, ...props }: ConfirmDialogProps) => {
    // * Effect to handle keyUp event listener.
    useEffect(() => {
      // * Handle Key Up Event change to hide ConfirmDialog on event click
      const handleKeyUp = (e: KeyboardEvent) =>
        e.key === "Escape" && setShow && setShow(false);

      if (!preventEsc && show) window.addEventListener("keyup", handleKeyUp);
      else if (!show) window.removeEventListener("keyup", handleKeyUp);
      // * Destroy the listener. CallBack should be same to be destroyed
      return () => window.removeEventListener("keyup", handleKeyUp);
    }, [preventEsc, setShow, show]);

    /* handle Close Panel */

    const handleClose = () => {
      setShow && setShow(false);
    };

    if (show)
      return (
        <div className={styles.confirmDialogWrapper}>
          <div
            className={`${styles.backdrop} ${
              props.noGlassEffect ? "" : styles.blurBg
            }`}
            onClick={() =>
              !props.preventBackdropClose && setShow && setShow(false)
            }
          ></div>
          <div className={styles.confirmDialogCard}>
            <div className={styles.confirmDialogHeader}>
              {props.headerTemplate ? props.headerTemplate() : props.title}
            </div>

            <div className={styles.confirmDialogBody}>{props.message}</div>

            <div className={styles.confirmDialogFooter}>
              <FlatButton
                label={props.confirmLabel ?? "Ok"}
                severity={props.confirmSeverity ?? "primary"}
                onClick={props.onConfirm}
              />
              <FlatButton
                label={props.cancelLabel ?? "Cancel"}
                severity={"warning"}
                onClick={() => {
                  if (props.onClose) props.onClose();
                  setShow(false);
                }}
              />
            </div>

            {!props.hideCloseBtn && (
              <button onClick={handleClose} className={styles.closeBtn}>
                &times;
              </button>
            )}
          </div>
        </div>
      );
    return null;
  }
);

//* Display name for memoization.
ConfirmDialog.displayName = "ConfirmDialog";
export default ConfirmDialog;
