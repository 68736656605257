import { useEffect } from "react";
import { useNavigate } from "react-router";
import { ROUTES } from "../../common/routes/default.routes";

//* FinancialDashboardPage
const ReportManagementPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(ROUTES.SUMMARY_LOG);
  });
};

export default ReportManagementPage;
