import { toast } from "react-toastify";
import { Severity } from "../types/ui.types";

// * Showing toast
export const showToast = (severity: Severity, message: string) => {
  const _toast: any = toast;
  _toast[severity](message, {
    bodyClassName: "text",
    className: "text",
    closeButton: true,
    hideProgressBar: true,
    pauseOnFocusLoss: false,
    pauseOnHover: true,
    position: "bottom-right",

  });
};
