import { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { UserDTO } from "../../../common/models/user.model";
import { ElementChangeEvent } from "../../../common/types/ui.types";
import FlatButton from "../../../components/elements/buttons/flat.button";
import InputText from "../../../components/elements/input/inputText.element";
import Dialog from "../../../components/ui/dialog/dialog.ui";
import styles from "../userManagement.module.scss";

interface EditUserDialogProps {
  show: boolean;
  setShow: (b: boolean) => void;
  onChange?: (user: UserDTO) => void;
  onSubmit: (user: UserDTO) => void;
  selectedUser: UserDTO | null;
}

const EditUserDialog = ({ show, setShow, ...props }: EditUserDialogProps) => {
  const [user, setUser] = useState<UserDTO>(
    props.selectedUser ?? ({} as UserDTO)
  );
 

  useEffect(() => {
    if (props.selectedUser) setUser(props.selectedUser);
  }, [props.selectedUser]);

  const onClose = () => {
    setShow(false);
    props.onChange && props.onChange({} as UserDTO);
  };

  const onChange = (e: ElementChangeEvent) => {
    setUser({ ...user, [e.name]: e.data });
  };

  return (
    <Dialog
      closeOnEsc
      hideCloseBtn={false}
      show={show}
      setShow={setShow}
      title="Edit User Details"
      glassEffect={true}
      className={styles.editDialog}
    >
      <InputText
        name="fullName"
        className={`${styles.input} ${styles.editDialogInput}`}
        type="text"
        label="Enter Name"
        labelClass={styles.label}
        onChange={onChange}
        placeholder="Enter Name"
        Icon={<FaUser size={20} />}
        defaultValue={props.selectedUser?.fullName}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            props.onSubmit(user);
          }
        }}
      />
      <InputText
        name="email"
        className={styles.input}
        type="text"
        label="Enter email"
        labelClass={styles.label}
        onChange={onChange}
        placeholder="Enter email"
        // eslint-disable-next-line react/jsx-no-undef
        Icon={<MdEmail size={20} />}
        defaultValue={props.selectedUser?.email}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            props.onSubmit(user);
          }
        }}
      />
      <div className={styles.buttonDiv}>
        <FlatButton
          className={styles.dialogButton}
          onClick={() => props.onSubmit(user)}
          label="UPDATE"
        />
        <FlatButton
          className={styles.dialogButton}
          label="CANCEL"
          onClick={onClose}
        />
      </div>
    </Dialog>
  );
};

export default EditUserDialog;
