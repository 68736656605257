import { useState } from "react";
import { FaFlag } from "react-icons/fa";
import { useRecoilState, useRecoilValue } from "recoil";
import { COLSOperatorBalanceReport } from "../../../../common/constants/columns.constant";
import { FORMAT } from "../../../../common/constants/datetime.constant";
import { ROLES, STATUS } from "../../../../common/constants/general.constant";
import { formatDateString } from "../../../../common/helpers/date.helper";
import {
  NameIdTemplate,
  toLocaleNumber,
} from "../../../../common/helpers/general.helper";
import { ReportBalanceDTO } from "../../../../common/models/dashborad.model";
import FlatButton from "../../../../components/elements/buttons/flat.button";
import PrimeTable from "../../../../components/ui/table/primeTable.ui";
import { AtomOperatorsBalanceReport } from "../../../../store/operator.store";
import { AtomAuthUser } from "../../../../store/auth.store";
import { AtomAggregatorsObj } from "../../../../store/aggregator.store";
import SettleDialog from "../settleDialog/settleDialog.component";
import styles from "./operatorTable.module.scss";

interface OperatorTableProps {
  fetchAgentBalanceReport: () => void;
  loader?: boolean;
}

const OperatorTable = ({
  fetchAgentBalanceReport,
  loader,
}: OperatorTableProps) => {
  const [operatorBalanceReport] = useRecoilState(AtomOperatorsBalanceReport);

  const [showSettle, setShowSettle] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const operators = useRecoilValue(AtomAggregatorsObj);
  const authUser = useRecoilValue(AtomAuthUser);
  const handleSettle = (rowData: any) => {
    setShowSettle(true);
    setSelectedRow(rowData);
   
  };

  const TableColumn = (
    rowData: ReportBalanceDTO | any,

    { rowIndex, field }: any
  ) => {
    switch (field) {
      case "Sr_no":
        return ++rowIndex;
      case "detail":
        return (
          <>
            {NameIdTemplate(rowData?.agentDetail, rowData.operatorId)}
            {rowData?.isDefault && (
              <FaFlag style={{ paddingLeft: "0.25rem" }} />
            )}
          </>
        );
      case "operatorDetail":
        const name =
          operators[rowData.operatorDetail] &&
          operators[rowData.operatorDetail].operatorDetail
            ? operators[rowData.operatorDetail].operatorDetail
            : rowData.operatorDetail;
        return NameIdTemplate(name, rowData.aggregatorId);

      case "rebateRate":
        return (
          <>
            <div className={styles.alignRight}>
              {rowData?.rebateRate
                ? `${rowData.rebateRate?.toFixed(2)} %`
                : "-"}
            </div>
          </>
        );

      case "closingBalance":
        return (
          <div className={styles.closingBalance}>
            {toLocaleNumber(rowData?.closingBalance)}
          </div>
        );
      case "environment":
        return rowData.environment === true ? STATUS.LIVE : STATUS.SIM;
      case "lastReportEndDate":
        return (
          <div className="text-center">
            {formatDateString(rowData[field], FORMAT.ddMMyyyy_slash)}
          </div>
        );
      case "lastReportStartDate":
        return (
          <div className="text-center">
            {formatDateString(rowData[field], FORMAT.ddMMyyyy_slash)}
          </div>
        );
      case "action":
        return (
          <div className={styles.actionButtons}>
            <FlatButton
              label={
                authUser?.role === ROLES.AGGREGATOR ? "Report/Settle" : "Report"
              }
              onClick={() => handleSettle(rowData)}
            />
          </div>
        );
      default:
        return rowData[field] ?? "-";
    }
  };
  return (
    <>
      <h1 className="p1">Operators</h1>
      <SettleDialog
        show={showSettle}
        setShow={setShowSettle}
        selectedRow={selectedRow}
        fetchAgentBalanceReport={fetchAgentBalanceReport}
        showCreditDebit={authUser?.role === ROLES.AGGREGATOR}
      />

      <PrimeTable
        data={operatorBalanceReport}
        columns={COLSOperatorBalanceReport}
        ColumnBody={TableColumn}
        isLoading={loader}
      />
    </>
  );
};

export default OperatorTable;
