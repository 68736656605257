import { ColumnsDTO } from "../types/ui.types";

export const COLSAdminBalanceReport: ColumnsDTO[] = [
  { field: "aggregatorId", header: "Aggregator", sortable: true },
  {
    field: "agentDetail",
    header: "Operator",
    sortable: true,
  },

  { field: "currency", header: "Currency", sortable: true },
  {
    field: "environment",
    header: "Environment",
    sortable: true,
  },
  { field: "closingBalance", header: "Closing Balance", sortable: true },
  { field: "rebateRate", header: "Rebate Rate", sortable: true },
  // {
  //   field: "lastReportStartDate",
  //   header: "Last Report Start Date",
  //   sortable: true,
  // },
  // {
  //   field: "lastReportEndDate",
  //   header: "Last Report End Date",
  //   sortable: true,
  // },
  { field: "action", header: "Action" },
];

export const COLSAgentBalanceReport: ColumnsDTO[] = [
  {
    field: "detail",
    header: "Operator Detail",
    sortable: true,
  },
  { field: "aggregatorId", header: "Aggregator", sortable: true },
  {
    field: "environment",
    header: "Environment",
    sortable: true,
  },
  { field: "closingBalance", header: "Closing Balance", sortable: true },
  { field: "rebateRate", header: "Rebate Rate", sortable: true },
  {
    field: "lastReportStartDate",
    header: "Last Report Start Date",
    sortable: true,
  },
  {
    field: "lastReportEndDate",
    header: "Last Report End Date",
    sortable: true,
  },
  { field: "action", header: "Action" },
];

export const COLSOperatorBalanceReport: ColumnsDTO[] = [
  { field: "aggregatorId", header: "Aggregator", sortable: true },
  {
    field: "environment",
    header: "Environment",
    sortable: true,
  },
  { field: "closingBalance", header: "Closing Balance", sortable: true },
  { field: "currency", header: "Currency", sortable: true },
  { field: "rebateRate", header: "Rebate Rate", sortable: true },
  {
    field: "lastReportStartDate",
    header: "Last Report Start Date",
    sortable: true,
  },
  {
    field: "lastReportEndDate",
    header: "Last Report End Date",
    sortable: true,
  },
  { field: "action", header: "Action" },
];
