import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { ROUTES } from "../common/routes/default.routes";
import { AtomAuthUser } from "../store/auth.store";

const IndexPage = () => {
  const user = useRecoilValue(AtomAuthUser);
  const navigate = useNavigate();
  useEffect(() => {
    if (!user?.token) {
      navigate(ROUTES.SIGN_IN);
    } else {
      navigate(ROUTES.AGGREGATOR_MANAGE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return <></>;
};

export default IndexPage;
