import { useRecoilValue } from "recoil";
import { COLSFinancialDashboard } from "../../../../../../common/constants/columns.constant";
import { formatDateString } from "../../../../../../common/helpers/date.helper";
import {
  NameIdTemplate,
  toLocaleNumber,
} from "../../../../../../common/helpers/general.helper";
import { FDRowDTO } from "../../../../../../common/models/dashborad.model";
import { ColumnBodyOptions } from "../../../../../../common/types/ui.types";
import PrimeTable from "../../../../../../components/ui/table/primeTable.ui";
import { AtomAggregatorsObj } from "../../../../../../store/aggregator.store";
import { AtomFilteredFDData } from "../../../../../../store/financialDashboard.store";
import { AtomOperators } from "../../../../../../store/operator.store";
interface FDTableProps {
  loader: boolean;
}

const FDTable = ({ loader }: FDTableProps) => {
  const fdFilteredData = useRecoilValue(AtomFilteredFDData);
  const operators = useRecoilValue(AtomOperators);
  const aggregators = useRecoilValue(AtomAggregatorsObj);
  const ColumnBody = (
    rowData: FDRowDTO | any,
    { field }: ColumnBodyOptions
  ) => {
    const _data = rowData[field] ?? "-";
    switch (field) {
      case "localDate":
        return <div className="text-left">{formatDateString(_data)}</div>;
      case "operatorId":
        const nameOperator = operators[rowData?.operatorId]
          ? operators[rowData?.operatorId]?.detail ?? ""
          : rowData?.operatorId ?? "";
        return (
          <>
            {NameIdTemplate(
              nameOperator,
              rowData.operatorId,
              rowData?.operatorId === rowData.aggregatorId
            )}
          </>
        );
      case "aggregatorId":
        const name = aggregators[rowData?.aggregatorId]
          ? aggregators[rowData?.aggregatorId].detail
          : rowData?.aggregatorId ?? "";

        return NameIdTemplate(name, rowData.aggregatorId);
      case "rebateRate":
        return <div className="text-right">{rowData[field]} %</div>;
      case "totalInvestment":
        return (
          <div className="text-right">
            {toLocaleNumber(rowData?.totalInvestment ?? 0)}
          </div>
        );
      case "totalRebates":
        return (
          <div className="text-right">
            {toLocaleNumber(rowData?.totalRebates ?? 0)}
          </div>
        );
      case "totalWinnings":
        return (
          <div className="text-right">
            {toLocaleNumber(rowData?.totalWinnings ?? 0)}
          </div>
        );
      case "totalRefund":
        return (
          <div className="text-right">
            {toLocaleNumber(rowData?.totalRefund ?? 0)}
          </div>
        );
      // case "localDate":
      //   return (
      //     <div className="text-right">
      //       {formatDateString(rowData?.localDate)}
      //     </div>
      //   );
      default:
        return rowData[field];
    }
  };

  return (
    <div>
      <PrimeTable
        data={fdFilteredData}
        columns={COLSFinancialDashboard}
        ColumnBody={ColumnBody}
        isLoading={loader}
      />
    </div>
  );
};

export default FDTable;
